import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from '../../styles/faq.module.css';
import axios from 'axios';
import configs from '../AccountSetting/url'


const S1 = () => {
  const navigate = useNavigate();
  const localhost = configs.apiUrl


    useEffect(() => {
        // setLoggedName(localStorage.getItem("firstname"));
        const fetchProtectedData = async () => {
          const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage
    
          try {
            const response = await axios.get(`${localhost}/protected`, {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the header
              },
            });
    
            if (response.data === true) {
              console.log("Access granted:", response.data); // The token was verified successfully
              // Proceed with your page logic here
            }
    
          } catch (error) {
            navigate('/client/login');
          }
        };
        fetchProtectedData();
      });



    // data/proactiveFAQ.js
const FAQ = [
    {
      question: "What is the Proactive section used for?",
      answer: "The Proactive section is used for managing proactive tasks and strategies. It helps you plan and execute actions to address potential issues before they arise."
    },
    {
      question: "How do I add a new proactive task?",
      answer: "Add new proactive tasks by clicking on 'Add Task' in the Proactive section and filling in the required details."
    },
    {
      question: "Can I track the progress of proactive tasks?",
      answer: "Yes, progress can be tracked in the Proactive section where you can view task statuses and updates."
    },
    {
      question: "How do I prioritize proactive tasks?",
      answer: "Prioritize tasks by setting their priority level when creating or editing a task in the Proactive section."
    }
  ];



    return (
        <div className={classes.priv}>
            <h1>Proactive FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
