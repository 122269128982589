import React, { useEffect } from 'react';
import classes from '../styles/privacy.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../aUrl'



const PrivacyTerms = () => {
  const navigate = useNavigate();
  const localhost = config.apiUrl


  useEffect(() => {
    // setLoggedName(localStorage.getItem("firstname"));
    const fetchProtectedData = async () => {
      const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage

      try {
        const response = await axios.get(`${localhost}/protected`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the header
          },
        });

        if (response.data === true) {
          console.log("Access granted:", response.data); // The token was verified successfully
          // Proceed with your page logic here
        }

      } catch (error) {
        navigate('/client/login');
      }
    };
    fetchProtectedData();
  });


  return (
    <div className={classes.priv}>
      <h1>Privacy Policy / Terms of Use</h1>
      <br></br>

      <div className={classes.privin}>
        <h2>Privacy Policy</h2>
        <br></br>
        <p>Welcome to the Omega Consulting Client Dashboard. This document outlines our Privacy Policy and Terms of Use. Please read these terms carefully before using the dashboard.</p><br></br>

        <p>At Omega Consulting, LLC ("Omega," "we," "our," "us"), we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Client Dashboard.</p><br></br>

        <p>We collect certain personal data such as your name, email address, and phone number, which you provide. Additionally, we gather usage data automatically, including your IP address, browser type, and usage patterns.</p><br></br>

        <p>We use the information collected to maintain and improve the dashboard, notify you of changes, and provide customer support. It also helps us analyze and enhance our services.</p><br></br>

        <p>Your data is securely stored on our servers with industry-standard security measures to prevent unauthorized access. We do not share your personal information with third parties except as necessary for providing services or as required by law.</p><br></br>

        <p>You have the right to access, update, or delete your personal information. To exercise these rights, please contact us at info@omegaconsulting.online. We also use cookies and tracking technologies to monitor activity on our dashboard. You can control cookies at the browser level.</p><br></br>

        <p>We may update our Privacy Policy periodically. Any changes will be posted on this page. For questions, contact us at info@omegaconsulting.online.</p><br></br>


<h2>Terms of Use</h2>
<br></br>
<p>By accessing and using the Omega Consulting Client Dashboard, you agree to comply with and be bound by these Terms of Use. If you do not agree to these terms, please do not use the dashboard.</p><br></br>

<p>You are granted a non-exclusive, non-transferable, revocable license to access and use the dashboard strictly in accordance with these terms. You agree not to use the dashboard for any unlawful purpose.</p><br></br>

<p>To access certain features of the dashboard, you may be required to create an account. You agree to provide accurate and complete information and to keep this information up to date. You are responsible for maintaining the confidentiality of your account credentials.</p><br></br>

<p>All content, trademarks, and data on this dashboard are the property of Omega Consulting or its licensors. You agree not to reproduce, distribute, or create derivative works from any content without our express written permission.</p><br></br>

<p>Omega Consulting is not liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the dashboard.</p><br></br>

<p>We may terminate or suspend your access to the dashboard immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms.</p><br></br>

<p>These terms shall be governed and construed in accordance with the laws of Massachusetts USA, without regard to its conflict of law provisions.</p><br></br>

<p>We reserve the right to modify or replace these Terms of Use at any time. We will notify you of any changes by posting the new terms on this page.</p><br></br>

<p>If you have any questions about these Terms of Use, please contact us:</p><br></br>
<p>Email: info@omegaconsulting.online</p><br></br>
<p>Phone: (617)203-2080</p><br></br>
<p>Address: One Marina Park Drive, Suite 1410, Boston, MA 02210</p><br></br>

<p>By using the Omega Consulting Client Dashboard, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy and Terms of Use.</p><br></br>

      </div>
    </div>
  );
};

export default PrivacyTerms;
