import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure the CSS for toast notifications is imported
import classes from "../styles/forgot.module.css";
import axios from "axios";
import config from '../aUrl.js'

const Forgot = () => {
  const navigate = useNavigate();
  const localhost = config.apiUrl

  const [data, setData] = useState({
    email: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Corrected the typo here

    try {
      const res = await axios.post(`${localhost}/otpverification`, {
        email: data.email
      });
      toast.success("OTP sent to your email");
      setTimeout(()=>{
        navigate('/client/verifyotp',{state :{email : data.email }});
      },2000)
    } catch (error) {
      toast.error("Email is invalid");
    }
  };

  return (
    <div className={classes.outercontainer}>
      <ToastContainer />

      <h1 className={classes.loginheader}>Reset Your Password</h1>

      <div className={classes.logincontainer}>
        <div className={classes.logincard}>
          {/* <p className={classes.otptext}>We'll send a password reset link to your email.</p> */}
          <br />

          {/* PASS RESET FORM  */}
          <form onSubmit={handleSubmit}>
            <label htmlFor="email" className={classes.labels}>
              Enter Your Registered Email
              <span className={classes.star}> *</span>
            </label>
            <br />

            {/* EMAIL INPUT  */}
            <input
              type="email"
              placeholder="Enter Email"
              className={classes.inputs}
              name="email" // Added name attribute
              value={data.email}
              onChange={handleChange}
              required
            />

            <br />

            {/* RESET PASS SUBMIT BUTTON  */}
            <div className={classes.loginbtnbox}>
              <button type="submit" className={classes.loginbutton}>
                Submit
              </button>
            </div>
          </form>

          <div className={classes.signupbox}>
            <p>Know your password?</p>

            <p>
              <Link to="/client/login" className={classes.signuplinklink}>
                Login Here
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
