import React, { useState, useEffect } from 'react';
import classes from '../../styles/projectScope/activities.module.css';
import DeleteIcon from '@mui/icons-material/Delete';


const Activities = () => {
  const [activities, setActivities] = useState([
    { id: 1, name: "Activity 1", description: "Hello there, I am Abhishek Bharti." },
    { id: 2, name: "Activity 2", description: "Urna donec cras molestie sit justo etiam." },
    { id: 3, name: "Activity 3", description: "Urna donec cras molestie sit justo etiam." },
    { id: 4, name: "Activity 4", description: "Urna donec cras molestie sit justo etiam." },
    { id: 5, name: "Activity 5", description: "Urna donec cras molestie sit justo etiam." },
    { id: 6, name: "Activity 6", description: "Urna donec cras molestie sit justo etiam." },
    { id: 7, name: "Activity 7", description: "Urna donec cras molestie sit justo etiam." },
    { id: 8, name: "Activity 8", description: "Urna donec cras molestie sit justo etiam." },
  ]);

  const [isEditMode, setIsEditMode] = useState(false);
  const [editedActivities, setEditedActivities] = useState([...activities]);

  // Toggles edit mode on/off
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    if (!isEditMode) {
      setEditedActivities([...activities]); // Reset to original values when exiting edit mode
    }
  };

  // Handle cancel button click
  const handleCancel = () => {
    setIsEditMode(false);
    setEditedActivities([...activities]); // Restore data from original activities
  };

  // Handle save button click
  const handleSave = () => {
    setIsEditMode(false);
    setActivities([...editedActivities]); // Save edited data
  };

  // Handle adding a new activity
  const handleAddActivity = () => {
    const newActivity = { id: Date.now(), name: "New Activity", description: "New description" };
    setEditedActivities([newActivity, ...editedActivities]); // Add new activity to the top
  };

  // Handle input changes during edit mode
  const handleInputChange = (id, field, value) => {
    setEditedActivities((prevActivities) =>
      prevActivities.map((activity) =>
        activity.id === id ? { ...activity, [field]: value } : activity
      )
    );
  };


  // Handle deleting an activity
  const handleDeleteActivity = (id) => {
    setEditedActivities((prevActivities) =>
      prevActivities.filter((activities) => activities.id !== id)
    );
  };





  return (
    <div className={classes.activitybox}>
      <div className={classes.otrhead}>
        <div className={classes.inotrhead}>
          <h2>Ongoing Activities</h2>
        </div>
        <div className={classes.inotrhead}>
          {/* Edit button toggles to cancel when in edit mode */}
          <button
            type="button"
            className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
            onClick={isEditMode ? handleCancel : toggleEditMode}
          >
            {isEditMode ? 'Cancel' : 'Edit'}
          </button>

          {/* Save and Add buttons appear only in edit mode */}
          {isEditMode && (
            <>
              <button
                onClick={handleSave}
                className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
              >
                Save
              </button>
              <button onClick={handleAddActivity} className={classes.saveimgbutton}>
                Add
              </button>
            </>
          )}
        </div>
      </div>

      <div className={classes.nmedsc}>
        <div className={classes.nme}>
          <p>Name</p>
        </div>
        <div className={classes.dsc}>
          <p>Description</p>
        </div>
      </div>

      {/* Activities Here */}
      <div className={classes.actimsgouterbox}>
        {editedActivities.map((activity) => (
          <div
            className={classes.actimessbox}
            key={activity.id}
            style={{ backgroundColor: activity.id % 2 === 0 ? "#f5f5f5" : "#ffffff" }}
          >
            <div className={classes.activnme}>
              {isEditMode ? (
                <input
                  type="text"
                  value={activity.name}
                  onChange={(e) => handleInputChange(activity.id, 'name', e.target.value)}
                />
              ) : (
                <p>{activity.name}</p>
              )}
            </div>
            <div className={classes.activdesc}>
              {isEditMode ? (
                <input
                  type="text"
                  value={activity.description}
                  onChange={(e) => handleInputChange(activity.id, 'description', e.target.value)}
                />
              ) : (
                <p>{activity.description}</p>
              )}
            </div>


            {/* Delete button in edit mode */}
            {isEditMode && (
                      <a
                        className={classes.deleteButtonlst}
                        onClick={() => handleDeleteActivity(activity.id)}
                      >
                        <DeleteIcon />
                      </a>
                    )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Activities;
