import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from '../../styles/faq.module.css';
import axios from 'axios';
import configs from '../AccountSetting/url';


const S1 = () => {

  const navigate = useNavigate();
  const localhost = configs.apiUrl


    useEffect(() => {
        // setLoggedName(localStorage.getItem("firstname"));
        const fetchProtectedData = async () => {
          const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage
    
          try {
            const response = await axios.get(`${localhost}/protected`, {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the header
              },
            });
    
            if (response.data === true) {
              console.log("Access granted:", response.data); // The token was verified successfully
              // Proceed with your page logic here
            }
    
          } catch (error) {
            navigate('/client/login');
          }
        };
        fetchProtectedData();
      });



    const FAQ = [
        {
          question: "Where can I manage my payment setup?",
          answer: "You can manage your payment setup in the 'Payment' section of the dashboard. This section allows you to add or update payment methods and review your payment history."
        },
        {
          question: "How do I add a new payment method?",
          answer: "In the 'Payment' section, click on 'Add Payment Method' and follow the prompts to enter your new payment information."
        },
        {
          question: "How can I view my payment history?",
          answer: "Payment history is available in the 'Payment' section, where you can review past transactions and download receipts."
        },
        {
          question: "Can I set up recurring payments?",
          answer: "Yes, you can set up recurring payments in the 'Payment' section by selecting the 'Set Up Recurring Payment' option."
        }
      ];



    return (
        <div className={classes.priv}>
            <h1>Payment FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
