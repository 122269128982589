import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BI from '../components/BillsInvoices'; // Import AccountSetting component

const Bills = () => {
  return (
    <div>
      <h1>Bills & Invoices</h1>
      <Routes>
        <Route path="/*" element={<BI />} /> {/* Render AccountSetting */}
      </Routes>
      
    </div>
  );
};

export default Bills;
