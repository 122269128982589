import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import classes from '../styles/searchbar.module.css';

// Debounce function to limit how often a function is called
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const SearchBar = ({ onQuestionClick }) => {
  const navigate = useNavigate();
  const searchBarRef = useRef(null); // Ref for the search bar container
  const inputRef = useRef(null); // Ref for the search input element
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);

  // Define individual navigation functions
  const S1 = () => navigate("/client/dashboard/profilefaq");
  const S2 = () => navigate("/client/dashboard/paymentfaq");
  const S3 = () => navigate("/client/dashboard/deliverablesfaq");
  const S4 = () => navigate("/client/dashboard/contractsfaq");
  const S5 = () => navigate("/client/dashboard/bills&invoicesfaq");
  const S6 = () => navigate("/client/dashboard/projectscopefaq");
  const S7 = () => navigate("/client/dashboard/objectivesfaq");
  const S8 = () => navigate("/client/dashboard/proactivefaq");
  const S9 = () => navigate("/client/dashboard/privacyfaq");
  const S10 = () => navigate("/client/dashboard/logoutfaq");
  

  const questionToFunctionMap = {

    "Can I update my contact information?": S1,
  "How can I set up my profile?": S1,
  "How do I change my profile picture?": S1,
  "How do I adjust my notification settings?": S1,
  
  "Where can I manage my payment setup?": S2,
  "How do I add a new payment method?": S2,
  "How can I view my payment history?": S2,
  "Can I set up recurring payments?": S2,
  
  "How do I check the status of my deliverables?": S3,
  "Can I view detailed reports for my deliverables?": S3,
  "How do I update the status of a deliverable?": S3,
  "How can I set deadlines for new deliverables?": S3,
  
  "How do I view my contracts?": S4,
  "Can I download contract documents?": S4,
  "How do I search for a specific contract?": S4,
  "How do I request changes to a contract?": S4,
  
  "Where can I view and manage my bills and invoices?": S5,
  "How do I download a bill or invoice?": S5,
  "Can I pay bills and invoices online?": S5,
  "How do I view the details of a bill or invoice?": S5,
  
  "What is included in the Project Scope section?": S6,
  "How can I view the objectives of the project?": S6,
  "Can I update the project scope?": S6,
  "How do I view the timeline of the project?": S6,
  
  "How do I set and track objectives?": S7,
  "Can I edit existing objectives?": S7,
  "How do I mark an objective as complete?": S7,
  "How can I set deadlines for objectives?": S7,
  
  "What is the Proactive section used for?": S8,
  "How do I add a new proactive task?": S8,
  "Can I track the progress of proactive tasks?": S8,
  "How do I prioritize proactive tasks?": S8,
  
  "How do I manage my privacy settings and view terms?": S9,
  "Can I change my privacy preferences?": S9,
  "How do I review the terms of service?": S9,
  "How do I update my privacy policy?": S9,
  
  "How do I log out of the dashboard?": S10

    
  };

  // Update the filtered questions based on the search term
  const filterQuestions = (term) => {
    if (term === '') {
      setFilteredQuestions(Object.keys(questionToFunctionMap)); // Show all questions if search term is empty
      return;
    }

    const lowercasedTerm = term.toLowerCase();
    const searchWords = lowercasedTerm.split(' ').filter(Boolean);

    const result = Object.keys(questionToFunctionMap).filter((question) => {
      const lowercasedQuestion = question.toLowerCase();
      return searchWords.every(word => lowercasedQuestion.includes(word));
    });

    setFilteredQuestions(result);
  };

  const debouncedFilter = useCallback(debounce(filterQuestions, 300), []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedFilter(value);
  };

  const handleFocus = () => {
    setIsSearchBarFocused(true);
    setFilteredQuestions(Object.keys(questionToFunctionMap)); // Show all questions when focused
  };

  const handleBlur = () => {
    // Delay hiding the list to allow the click to register
    setTimeout(() => {
      if (!isSearchBarFocused) {
        setFilteredQuestions([]);
      }
    }, 150);
  };

  const handleQuestionClick = (question) => {
    const navigationFunction = questionToFunctionMap[question];
    if (navigationFunction) {
      navigationFunction();
      setSearchTerm(''); // Reset search term after navigation
      setFilteredQuestions([]); // Clear the filtered questions list
      setIsSearchBarFocused(false); // Unfocus the search bar
      inputRef.current.blur(); // Blur the input element
    }
  };

  useEffect(() => {
    if (searchTerm === '' && !isSearchBarFocused) {
      setFilteredQuestions([]);
    } else if (searchTerm !== '' && isSearchBarFocused) {
      filterQuestions(searchTerm);
    }
  }, [searchTerm, isSearchBarFocused]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setIsSearchBarFocused(false);
        setFilteredQuestions([]);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener on component unmount
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div
      className={classes.container}
      ref={searchBarRef} // Set ref to the container
    >
      <input
        type="text"
        placeholder="Search FAQs..."
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`${classes.searchBar} ${isSearchBarFocused ? classes.searchBarExpanded : ''}`}
        ref={inputRef} // Set ref to the input element
      />
      {(isSearchBarFocused || filteredQuestions.length > 0) && (
        <ul className={classes.faqList}>
          {filteredQuestions.length ? (
            filteredQuestions.map((question, index) => (
              <li
                key={index}
                onClick={() => handleQuestionClick(question)}
                className={classes.faqItem}
              >
                <p>{question}</p>
              </li>
            ))
          ) : (
            <li className={classes.noResults}>No FAQs found.</li>
          )}
        </ul>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  onQuestionClick: PropTypes.func,
};

export default SearchBar;
