import React, { useState } from 'react';
import classes from '../../styles/projectScope/risks.module.css';
import DeleteIcon from '@mui/icons-material/Delete';

const Risks = () => {
    // State to toggle edit mode
    const [isEditMode, setIsEditMode] = useState(false);
    // State to manage the list of risks
    const [risks, setRisks] = useState([
        { id: 1, name: 'Risk 1', impact: 'High', probability: 'Medium' },
        { id: 2, name: 'Risk 2', impact: 'Medium', probability: 'High' },
        { id: 3, name: 'Risk 3', impact: 'High', probability: 'Low' },
        { id: 4, name: 'Risk 4', impact: 'Low', probability: 'Medium' },
        { id: 5, name: 'Risk 5', impact: 'Low', probability: 'High' }
    ]);
    // State to manage temporary edits
    const [tempRisks, setTempRisks] = useState([...risks]);

    // Function to toggle between edit and view modes
    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
        if (isEditMode) {
            // Restore previous data when exiting edit mode
            setTempRisks([...risks]);
        }
    };

    // Function to handle the saving of changes
    const handleSave = () => {
        setRisks([...tempRisks]);
        setIsEditMode(false);
    };

    // Function to handle the addition of a new risk
    const handleAddRisk = () => {
        const newRisk = { id: Date.now(), name: 'New Risk', impact: 'Low', probability: 'Low' };
        const updatedRisks = [newRisk, ...tempRisks].sort((a, b) => b.id - a.id); // Add new risk at the top and sort descendingly
        setTempRisks(updatedRisks);
    };

    // Function to handle the deletion of a risk
    const handleDeleteRisk = (id) => {
        const updatedRisks = tempRisks.filter(risk => risk.id !== id);
        setTempRisks(updatedRisks);
    };

    // Function to get the color based on impact
    const getImpactColor = (impact) => {
        switch (impact) {
            case 'Low':
                return '#6391F3'; // Light blue
            case 'Medium':
                return '#525CDD'; // Level 2 blue
            case 'High':
                return '#202AAB'; // Level 3 blue
            default:
                return '#f5f5f5'; // Default grey
        }
    };

    // Function to get the color based on probability
    const getProbabilityColor = (probability) => {
        switch (probability) {
            case 'Low':
                return '#6391F3'; // Light blue
            case 'Medium':
                return '#525CDD'; // Level 2 blue
            case 'High':
                return '#202AAB'; // Level 3 blue
            default:
                return '#f5f5f5'; // Default grey
        }
    };

    // Function to handle change in risk details
    const handleChange = (id, field, value) => {
        const updatedRisks = tempRisks.map(risk => 
            risk.id === id ? { ...risk, [field]: value } : risk
        );
        setTempRisks(updatedRisks);
    };

    return (
        <div className={classes.risksbox}>
            <div className={classes.otrhead}>
                <div className={classes.inotrhead}>
                    <h2>Risks</h2>
                </div>
                <div className={classes.inotrhead}>
                    {/* Toggle between edit and view modes */}
                    <button
                        type="button"
                        className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
                        onClick={toggleEditMode}
                    >
                        {isEditMode ? 'Cancel' : 'Edit'}
                    </button>

                    {/* Save and Add buttons appear only in edit mode */}
                    {isEditMode && (
                        <>
                            <button
                                onClick={handleSave}
                                className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
                            >
                                Save
                            </button>
                            <button onClick={handleAddRisk} className={classes.saveimgbutton}>
                                Add
                            </button>
                        </>
                    )}
                </div>
            </div>

            <div className={classes.rnmerimptrprob}>
                <div className={classes.rnme}><p2>Risk name</p2></div>
                <div className={classes.rimpt}><p2>Impact</p2></div>
                <div className={classes.rprob}><p2>Probability</p2></div>
            </div>

            {/* Risks Here */}
            <div className={classes.risksmsgouterbox}>
                {tempRisks.map((risk) => {
                    // Determine the background color for risksnme
                    const risksnmeBgColor = risk.id % 2 === 0 ? '#f5f5f5' : '#ffffff'; // Grey if id is even

                    // Get colors based on impact and probability
                    const risksimpctBgColor = getImpactColor(risk.impact);
                    const risksprobBgColor = getProbabilityColor(risk.probability);

                    return (
                        <div className={classes.risksmessbox} key={risk.id} style={{ backgroundColor: risksnmeBgColor }}>
                            <div className={classes.risksnme}>
                                {/* Editable field if in edit mode */}
                                {isEditMode ? (
                                    <input
                                        type="text"
                                        value={risk.name}
                                        onChange={(e) => handleChange(risk.id, 'name', e.target.value)}
                                    />
                                ) : (
                                    <p>{risk.name}</p>
                                )}
                            </div>
                            <div
                                className={classes.risksimpct}
                                style={{ backgroundColor: risksimpctBgColor }}
                            >
                                {isEditMode ? (
                                    <select
                                        value={risk.impact}
                                        onChange={(e) => handleChange(risk.id, 'impact', e.target.value)}
                                    >
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                    </select>
                                ) : (
                                    <p>{risk.impact}</p>
                                )}
                            </div>
                            <div
                                className={classes.risksprob}
                                style={{ backgroundColor: risksprobBgColor }}
                            >
                                {isEditMode ? (
                                    <select
                                        value={risk.probability}
                                        onChange={(e) => handleChange(risk.id, 'probability', e.target.value)}
                                    >
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                    </select>
                                ) : (
                                    <p>{risk.probability}</p>
                                )}
                            </div>
                            {/* Delete button in edit mode */}
                            {isEditMode && (
                                <a
                                    className={classes.deleteButtonlst}
                                    onClick={() => handleDeleteRisk(risk.id)}
                                >
                                    <DeleteIcon />
                                </a>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Risks;
