import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from '../../styles/faq.module.css';
import axios from 'axios';
import configs from '../AccountSetting/url'


const S1 = () => {

  const navigate = useNavigate();
  const localhost = configs.apiUrl


    useEffect(() => {
        // setLoggedName(localStorage.getItem("firstname"));
        const fetchProtectedData = async () => {
          const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage
    
          try {
            const response = await axios.get(`${localhost}/protected`, {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the header
              },
            });
    
            if (response.data === true) {
              console.log("Access granted:", response.data); // The token was verified successfully
              // Proceed with your page logic here
            }
    
          } catch (error) {
            navigate('/client/login');
          }
        };
        fetchProtectedData();
      });



    // data/projectScopeFAQ.js
const FAQ = [
    {
      question: "What is included in the Project Scope section?",
      answer: "The Project Scope section includes detailed information about the project’s objectives, timelines, and deliverables. It helps you understand the project’s boundaries and expectations."
    },
    {
      question: "How can I view the objectives of the project?",
      answer: "Objectives are listed in the Project Scope section, where you can view and track each objective’s progress."
    },
    {
      question: "Can I update the project scope?",
      answer: "Updates to the project scope can be made by selecting 'Edit Scope' in the Project Scope section."
    },
    {
      question: "How do I view the timeline of the project?",
      answer: "The project timeline is displayed in the Project Scope section, showing key milestones and deadlines."
    }
  ];



    return (
        <div className={classes.priv}>
            <h1>Project Scope FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
