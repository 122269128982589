import React from 'react';
import { Route, Routes, NavLink, Navigate } from 'react-router-dom';
import Pending from '../pages/BillsInvoices/Pending';
import History from '../pages/BillsInvoices/History';
import classes from '../styles/billsInvoices/billsInvoices.module.css';

const BillsInvoices = () => {
  return (
    <div className={classes['account-setting']}>
      <nav className={classes.tabs}>
        <NavLink
          to="pending"
          className={({ isActive }) => (isActive ? `${classes.active} ${classes.tab}` : classes.tab)}
        >
          Pending
        </NavLink>
        <NavLink
          to="history"
          className={({ isActive }) => (isActive ? `${classes.active} ${classes.tab}` : classes.tab)}
        >
          History
        </NavLink>
      </nav>


      <div className={classes.content}>
        <Routes>
          <Route path="pending" element={<Pending />} />
          <Route path="history" element={<History />} />

          <Route path="/" element={<Navigate to="pending" />} /> {/* Default route */}
        </Routes>
      </div>
    </div>
  );
};

export default BillsInvoices;
