import React, { useState, useEffect } from 'react';
import classes from '../../styles/projectScope/issues.module.css';
import DeleteIcon from '@mui/icons-material/Delete';

const Issues = () => {
  const initialIssues = [
    { rank: 5, issue: "Hello there, I am Abhishek Bharti." },
    { rank: 2, issue: "Urna donec cras molestie sit justo etiam." },
    { rank: 3, issue: "Urna donec cras molestie sit justo etiam." },
    { rank: 1, issue: "Urna donec cras molestie sit justo etiam." },
    { rank: 4, issue: "Urna donec cras molestie sit justo etiam." },
  ];

  const [issues, setIssues] = useState([...initialIssues]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedIssues, setEditedIssues] = useState([...initialIssues]);

  useEffect(() => {
    // Sort issues in ascending order
    const sortedIssues = [...issues].sort((a, b) => a.rank - b.rank);
    setEditedIssues(sortedIssues);
  }, [issues]);

  // Toggle edit mode
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  // Handle changes in issue description or rank
  const handleInputChange = (rank, field, value) => {
    setEditedIssues((prevIssues) =>
      prevIssues.map((issue) =>
        issue.rank === rank ? { ...issue, [field]: value } : issue
      )
    );
  };

  // Handle saving changes
  const handleSave = () => {
    // Sort issues by rank in ascending order
    const sortedIssues = [...editedIssues].sort((a, b) => a.rank - b.rank);
    setIssues(sortedIssues);
    setIsEditMode(false);
  };

  // Handle cancel - revert changes
  const handleCancel = () => {
    setEditedIssues([...issues]); // Revert to original issues
    setIsEditMode(false);
  };

  // Handle adding a new issue
  const handleAddIssue = () => {
    const availableRanks = [1, 2, 3, 4, 5].filter(
      (rank) => !editedIssues.some((issue) => issue.rank === rank)
    );
    if (availableRanks.length > 0) {
      const newIssue = { rank: availableRanks[0], issue: "New Issue" };
      setEditedIssues([newIssue, ...editedIssues]); // Add new issue at the top
    }
  };

  // Handle deleting an issue
  const handleDeleteIssue = (rank) => {
    setEditedIssues((prevIssues) =>
      prevIssues.filter((issue) => issue.rank !== rank)
    );
  };

  // Get available ranks for dropdown
  const getAvailableRanks = (currentRank) => {
    const usedRanks = editedIssues.map((issue) => issue.rank);
    return [1, 2, 3, 4, 5].filter((rank) => !usedRanks.includes(rank) || rank === currentRank);
  };











  return (
    <div className={classes.issuebox}>
      <div className={classes.otrhead}>
        <div className={classes.inotrhead}>
          <h2>Top 5 Issues</h2>
        </div>
        <div className={classes.inotrhead}>
          {/* Edit button toggles to cancel when in edit mode */}
          <button
            type="button"
            className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
            onClick={isEditMode ? handleCancel : toggleEditMode}
          >
            {isEditMode ? 'Cancel' : 'Edit'}
          </button>

          {/* Save and Add buttons appear only in edit mode */}
          {isEditMode && (
            <>
              <button
                onClick={handleSave}
                className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
              >
                Save
              </button>
              <button onClick={handleAddIssue} className={classes.saveimgbutton}>
                Add
              </button>
            </>
          )}
        </div>
      </div>

      <div className={classes.nmedsc}>
        <div className={classes.nme}><p>Rank</p></div>
        <div className={classes.dsc}><p>Issue</p></div>
      </div>

      {/* Issues List */}
      <div className={classes.issuemsgouterbox}>
        {editedIssues.map((issue, index) => (
          <div
            className={classes.issuemessbox}
            key={issue.rank}
            style={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "#f5f5f5" }} // Alternating colors
          >
            <div className={classes.issuenme}>
              {/* Rank Selector in edit mode */}
              {isEditMode ? (
                <select
                  value={issue.rank}
                  onChange={(e) => handleInputChange(issue.rank, "rank", parseInt(e.target.value))}
                >
                  {getAvailableRanks(issue.rank).map((rank) => (
                    <option key={rank} value={rank}>
                      {rank}
                    </option>
                  ))}
                </select>
              ) : (
                <p>{issue.rank}</p>
              )}
            </div>
            <div className={classes.issuedesc}>
              {/* Editable issue description */}
              {isEditMode ? (
                <input
                  type="text"
                  value={issue.issue}
                  onChange={(e) => handleInputChange(issue.rank, "issue", e.target.value)}
                />
              ) : (
                <p>{issue.issue}</p>
              )}
            </div>
            {/* Delete button in edit mode */}
            {isEditMode && (
                      <a
                        className={classes.deleteButtonlst}
                        onClick={() => handleDeleteIssue(issue.rank)}
                      >
                        <DeleteIcon />
                      </a>
                    )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Issues;
