import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Status from '../pages/Status';
import Contracts from '../components/Contracts';
import BillsInvoices from '../pages/Bills';
import ProjectScope from '../pages/ProjectScope';
import Objectives from '../pages/Objectives';
import Details from '../pages/Details';
// import Notifications from '../pages/Notifications';






import '../styles/index.css';
import classes from '../styles/dashboard.module.css';

const Dashboard = () => {
    return (
        <div className={classes.layout}>
            <div className={classes.sidebarcontainer}>
                <Sidebar />
            </div>
            <div className={classes.maincontent}>
                <Header />
                <div className={classes.content}>
                    <Routes>
                        <Route path="/" element={<Navigate to="details" />} /> {/* Include Settings with nested routes */}          {/* Redirect to Home */}
                        <Route path="details/*" element={<Details />} /> {/* Route for Home */}
                        <Route path="status" element={<Status />} />
                        <Route path="contracts/*" element={<Contracts />} />
                        <Route path="billsInvoices/*" element={<BillsInvoices />} />
                        <Route path="projectScope" element={<ProjectScope />} />
                        <Route path="objectives" element={<Objectives />} />
                        {/* <Route path="notifications" element={<Notifications />} /> */}





                    </Routes>
                </div>
            </div>



        </div>
    );
};

export default Dashboard;
