import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Home from '../pages/Home';
import Analytics from '../pages/Analytics';
import Messages from '../pages/Messages';
import Permissions from '../pages/Permissions';
import Settings from '../pages/Settings';
import Notifications from '../pages/Notifications';
import ClientList from '../pages/ClientList';
import EmployeeList from '../pages/EmployeeList';

// import Faq from '../pages/Faq';
import S1 from '../pages/Faq/S1';
import S2 from '../pages/Faq/S2';
import S3 from '../pages/Faq/S3';
import S4 from '../pages/Faq/S4';
import S5 from '../pages/Faq/S5';
import S6 from '../pages/Faq/S6';
import S7 from '../pages/Faq/S7';
import S8 from '../pages/Faq/S8';
import S9 from '../pages/Faq/S9';
import S10 from '../pages/Faq/S10';




import '../styles/index.css';
import classes from '../styles/dashboard.module.css';

const Dashboard = () => {
  return (
    <div className={classes.layout}>
      <div className={classes.sidebarcontainer}>
        <Sidebar />
      </div>
      <div className={classes.maincontent}>
        <Header />
        <div className={classes.content}>
          <Routes>
            <Route path="/" element={<Navigate to="home" />} /> {/* Redirect to Home */}
            <Route path="home" element={<Home />} /> {/* Route for Home */}
            <Route path="analytics" element={<Analytics />} />
            <Route path="messages" element={<Messages />} />
            <Route path="permissions" element={<Permissions />} />    
            <Route path="settings/*" element={<Settings />} /> {/* Include Settings with nested routes */}
            <Route path="notifications" element={<Notifications />} />
            <Route path="clientlist/*" element={<ClientList />} />
            <Route path="employeelist/*" element={<EmployeeList />} />

            

            {/* faq's  */}
            <Route path="profilefaq" element={<S1 />} />
            <Route path="paymentfaq" element={<S2 />} />
            <Route path="deliverablesfaq" element={<S3 />} />
            <Route path="contractsfaq" element={<S4 />} />
            <Route path="bills&invoicesfaq" element={<S5 />} />
            <Route path="projectscopefaq" element={<S6 />} />
            <Route path="objectivesfaq" element={<S7 />} />
            <Route path="proactivefaq" element={<S8 />} />
            <Route path="privacyfaq" element={<S9 />} />
            <Route path="logoutfaq" element={<S10 />} />

          </Routes>
        </div>
      </div>



    </div>
  );
};

export default Dashboard;
