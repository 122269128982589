import React, { useState } from 'react';
import classes from '../styles/objectives.module.css';
import SliderBudget from '../components/projectScope/objective_sliders/SliderBudget';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';









const Objectives = () => {


  const [objectives, setObjectives] = useState([
    { id: 1, title: 'Objective 1' },
    { id: 2, title: 'Objective 2' },
    { id: 3, title: 'Objective 3' },
    { id: 4, title: 'Objective 4' },
    { id: 5, title: 'Objective 5' },
    { id: 6, title: 'Objective 6' },
    { id: 7, title: 'Objective 7' },
    { id: 8, title: 'Objective 8' },
    { id: 9, title: 'Objective 9' },
    { id: 10, title: 'Objective 10' },
    { id: 11, title: 'Objective 11' },
    { id: 12, title: 'Objective 12' },
    { id: 13, title: 'Objective 13' },
  ]);
  const [isEditMode, setIsEditMode] = useState(false);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  const handleSave = () => {
    // Logic to save changes if needed
    setIsEditMode(false);
  };

  const handleAdd = () => {
    const newObj = {
      id: objectives.length + 1, // Incrementing ID
      title: '', // New empty objective
    };
    setObjectives([newObj, ...objectives]); // Add new objective to the top
  };

  const handleDelete = (id) => {
    setObjectives(objectives.filter((obj) => obj.id !== id));
  };

  const handleChange = (id, value) => {
    setObjectives(
      objectives.map((obj) =>
        obj.id === id ? { ...obj, title: value } : obj
      )
    );
  };














  // Breakdown 

  const [breakdowns, setBreakdowns] = useState([
    { id: 1, cost: "200", description: "Hello there, iuewfhkjwe eofhj oeihf oiohe" },
    { id: 2, cost: "200", description: "Urna donec cras molestie sit justo etiam." },
    { id: 3, cost: "300", description: "Urna donec cras molestie sit justo etiam." },
    { id: 4, cost: "500", description: "Urna donec cras molestie sit justo etiam." },
    { id: 5, cost: "700", description: "Urna donec cras molestie sit justo etiam." },
    { id: 6, cost: "200", description: "Urna donec cras molestie sit justo etiam." },
    { id: 7, cost: "100", description: "Urna donec cras molestie sit justo etiam." },
    { id: 8, cost: "200", description: "Urna donec cras molestie sit justo etiam." },
    { id: 9, cost: "300", description: "Urna donec cras molestie sit justo etiam." },
    { id: 10, cost: "500", description: "Urna donec cras molestie sit justo etiam." },
    { id: 11, cost: "700", description: "Urna donec cras molestie sit justo etiam." },
    { id: 12, cost: "200", description: "Urna donec cras molestie sit justo etiam." },
    { id: 13, cost: "100", description: "Urna donec cras molestie sit justo etiam." },
    { id: 14, cost: "200", description: "Urna donec cras molestie sit justo etiam." },
  ]);



  const sumCosts = () => {
    return breakdowns.reduce((acc, item) => acc + parseFloat(item.cost), 0);
  };

  const totalCost = sumCosts();


  const [isEditModeBreakdown, setIsEditModeBreakdown] = useState(false);

  // Toggles between view and edit modes for breakdowns
  const toggleEditModeBreakdown = () => {
    setIsEditModeBreakdown(!isEditModeBreakdown);
  };

  // Adds a new breakdown to the top of the list
  const handleAddBreakdown = () => {
    const newBreakdown = {
      id: breakdowns.length + 1,
      cost: "", // Start with an empty cost
      description: "", // Start with an empty description
    };
    setBreakdowns([newBreakdown, ...breakdowns]); // Add new breakdown at the top
  };

  // Removes a breakdown from the list by id
  const handleDeleteBreakdown = (id) => {
    setBreakdowns(breakdowns.filter((breakdown) => breakdown.id !== id));
  };

  // Handles input changes for cost or description
  const handleChangeBreakdown = (id, field, value) => {
    setBreakdowns(breakdowns.map((breakdown) =>
      breakdown.id === id ? { ...breakdown, [field]: value } : breakdown
    ));
  };

  // Saves the breakdown changes
  const handleSaveBreakdowns = () => {
    // Logic to save changes
    setIsEditModeBreakdown(false);
  };

  // tasks values temp 
  // Separate state for each day's tasks with example tasks
  const [mondayTasks, setMondayTasks] = useState([
    { id: 1, task: "Task" },
    { id: 2, task: "Task" },
  ]);
  const [tuesdayTasks, setTuesdayTasks] = useState([
    { id: 1, task: "Task" },
    { id: 2, task: "Task" },
  ]);
  const [wednesdayTasks, setWednesdayTasks] = useState([
    { id: 1, task: "Task" },
  ]);
  const [thursdayTasks, setThursdayTasks] = useState([
    { id: 1, task: "Task" },
    { id: 2, task: "Task" },
  ]);
  const [fridayTasks, setFridayTasks] = useState([
    { id: 1, task: "Task" },
  ]);














  // Don't touch below this line____________________________________________________________________________________ 
  const colors = ["#DBE7FE", "#ECDDFF", "#FCEBE4"]; //tasks colors 

  const [isEditingTask, setIsEditingTask] = useState(false);

  const toggleEditModeTask = () => {
    setIsEditingTask(!isEditingTask);
  };

  // task addition 
  const addTask = (day) => {
    const newTask = { id: Date.now() };
    switch (day) {
      case 'Monday':
        setMondayTasks((prev) => [newTask, ...prev]);
        break;
      case 'Tuesday':
        setTuesdayTasks((prev) => [newTask, ...prev]);
        break;
      case 'Wednesday':
        setWednesdayTasks((prev) => [newTask, ...prev]);
        break;
      case 'Thursday':
        setThursdayTasks((prev) => [newTask, ...prev]);
        break;
      case 'Friday':
        setFridayTasks((prev) => [newTask, ...prev]);
        break;
      default:
        break;
    }
  };



  // task deletion
  const deleteTask = (day, taskId) => {
    switch (day) {
      case 'Monday':
        setMondayTasks((prev) => prev.filter((task) => task.id !== taskId));
        break;
      case 'Tuesday':
        setTuesdayTasks((prev) => prev.filter((task) => task.id !== taskId));
        break;
      case 'Wednesday':
        setWednesdayTasks((prev) => prev.filter((task) => task.id !== taskId));
        break;
      case 'Thursday':
        setThursdayTasks((prev) => prev.filter((task) => task.id !== taskId));
        break;
      case 'Friday':
        setFridayTasks((prev) => prev.filter((task) => task.id !== taskId));
        break;
      default:
        break;
    }
  };



  const handleTaskChange = (day, taskId, newTask) => {
    switch (day) {
      case 'Monday':
        setMondayTasks((prev) =>
          prev.map((task) => (task.id === taskId ? { ...task, task: newTask } : task))
        );
        break;
      case 'Tuesday':
        setTuesdayTasks((prev) =>
          prev.map((task) => (task.id === taskId ? { ...task, task: newTask } : task))
        );
        break;
      case 'Wednesday':
        setWednesdayTasks((prev) =>
          prev.map((task) => (task.id === taskId ? { ...task, task: newTask } : task))
        );
        break;
      case 'Thursday':
        setThursdayTasks((prev) =>
          prev.map((task) => (task.id === taskId ? { ...task, task: newTask } : task))
        );
        break;
      case 'Friday':
        setFridayTasks((prev) =>
          prev.map((task) => (task.id === taskId ? { ...task, task: newTask } : task))
        );
        break;
      default:
        break;
    }
  };
  // Don't touch above this line____________________________________________________________________________________


  const saveChangesTask = () => {
    console.log("Changes saved");
    setIsEditingTask(false);
  };
































  return (
    <div>
      <h1>Objectives</h1>
      <br></br>

      <div className={classes.objout}>
        <div className={classes.obj1}>
          <div className={classes.inotrhead}>
            <button
              type="button"
              className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
              onClick={isEditMode ? handleCancel : toggleEditMode}
            >
              {isEditMode ? 'Cancel' : 'Edit'}
            </button>

            {isEditMode && (
              <>
                <button
                  onClick={handleSave}
                  className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
                >
                  Save
                </button>
                <button
                  onClick={handleAdd}
                  className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
                >
                  Add
                </button>
              </>
            )}
          </div>


          <div className={classes.obj11}>
            {objectives.map((objective) => (
              <div key={objective.id} className={classes.objs}>
                <div className={classes.bluedt}></div>
                {isEditMode ? (
                  <input
                    type="text"
                    value={objective.title}
                    onChange={(e) =>
                      handleChange(objective.id, e.target.value)
                    }
                    className={classes.editableInput}
                    placeholder="Enter objective title"
                  />
                ) : (
                  <p>&nbsp;{objective.title}</p>
                )}
                {isEditMode && (


                  <a
                    onClick={() => handleDelete(objective.id)}
                    className={classes.deleteButton}
                  >
                    < DeleteIcon />
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>






























        <div className={classes.obj2}>

          <div className={classes.csb}>
            <h2>Costs and Budget</h2>
            <br></br>
            <div className={classes.csbgrph}>
            <SliderBudget 
            value={totalCost}
         
            />

              <div className={classes.bdjslidelabek}><p>$0</p> <p>$100000</p></div>
            </div>
















            {/* BREAKDOWN   */}

            <div className={classes.breakdownHeader}>
              <h2>Financial Breakdown</h2>

              <div className={classes.breakdownHeader}>
                <button
                  type="button"
                  className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
                  onClick={isEditModeBreakdown ? () => setIsEditModeBreakdown(false) : toggleEditModeBreakdown}
                >
                  {isEditModeBreakdown ? 'Cancel' : 'Edit'}
                </button>

                {isEditModeBreakdown && (
                  <>
                    <button
                      onClick={handleSaveBreakdowns}
                      className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
                    >
                      Save
                    </button>
                    <button
                      onClick={handleAddBreakdown}
                      className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
                    >
                      Add
                    </button>
                  </>
                )}
              </div>
            </div>


            <div className={classes.actvty}>


              <div className={classes.nmedsc}>
                <div className={classes.nme}><p2>Cost</p2></div>
                <div className={classes.dsc}><p2>Description</p2></div>
              </div>



              {/* BREAKDOWNS Here */}
              <div className={classes.actimsgouterbox}>





                <div className={classes.actimsgouterbox}>
                  {breakdowns.map((breakdown) => (
                    <div
                      className={classes.actimessbox}
                      key={breakdown.id}
                      style={{ backgroundColor: breakdown.id % 2 === 0 ? "#f5f5f5" : "#ffffff" }}
                    >
                      {isEditModeBreakdown ? (
                        <>
                          <input
                            type="text"
                            value={breakdown.cost}
                            onChange={(e) => handleChangeBreakdown(breakdown.id, 'cost', e.target.value)}
                            placeholder="Cost"
                            className={classes.cost}
                          />
                          <input
                            type="text"
                            value={breakdown.description}
                            onChange={(e) => handleChangeBreakdown(breakdown.id, 'description', e.target.value)}
                            placeholder="Description"
                            className={classes.description}
                          />
                          <a
                            onClick={() => handleDeleteBreakdown(breakdown.id)}
                            className={classes.deleteButtonbrn}
                          >
                            < DeleteIcon />
                          </a>
                        </>
                      ) : (
                        <>
                          <div className={classes.activnme}><p>${breakdown.cost}</p></div>
                          <div className={classes.activdesc}><p>{breakdown.description}</p></div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>

            </div>


          </div>
        </div>
      </div>





















      {/* This Week At A Glance */}

      <div className={classes.objweek}>

        <div className={classes.breakdownHeader}>  
        <h2>This Week At A Glance</h2>


        <div className={classes.glancebtnhds} >
        <button onClick={toggleEditModeTask} className={classes.saveimgbutton} >
            {isEditingTask ? 'Cancel' : 'Edit'}
          </button>
          {isEditingTask && <button onClick={saveChangesTask} className={classes.saveimgbutton} >Save</button>}
          </div>
          </div>
          
        <br></br>

        <div className={classes.weekglance}>
          
          {/* Monday Tasks */}
          <div className={classes.monday}>
            
            <div className={classes.weeklabel}>
              <p>Monday</p>
              {isEditingTask && (
              <button onClick={() => addTask('Monday')} className={classes.editbtnhedtsks} ><AddIcon/></button>
            )}
              </div>
            {mondayTasks.map((task) => (
              <div key={task.id} className={classes.tasks} style={{
                backgroundColor: colors[task.id % colors.length],
              }}>
                {isEditingTask ? (
                  <input
                    type="text"
                    placeholder='Task'
                    value={task.task}
                    onChange={(e) => handleTaskChange('Monday', task.id, e.target.value)}
                  />
                ) : (
                  <p>{task.task}</p>
                )}
                {isEditingTask && (
                  <button 
                  onClick={() => deleteTask('Monday', task.id)}
                  className={classes.deleteButtonGlance}
                  >
                    <DeleteIcon/>

                  </button>
                )}
              </div>
            ))}
            
          </div>

          {/* Tuesday Tasks */}
          <div className={classes.tuesday}>
            <div className={classes.weeklabel}>
              <p>Tuesday</p>
              {isEditingTask && (
              <button onClick={() => addTask('Tuesday')} className={classes.editbtnhedtsks} ><AddIcon/></button>
            )}
              </div>
            {tuesdayTasks.map((task) => (
              <div key={task.id} className={classes.tasks} style={{
                backgroundColor: colors[task.id % colors.length],
              }}>
                {isEditingTask ? (
                  <input
                    type="text"
                    placeholder='Task'
                    value={task.task}
                    onChange={(e) => handleTaskChange('Tuesday', task.id, e.target.value)}
                  />
                ) : (
                  <p>{task.task}</p>
                )}
                {isEditingTask && (
                  <button onClick={() => deleteTask('Tuesday', task.id)}
                  className={classes.deleteButtonGlance}
                  >
                    <DeleteIcon/>
                  </button>
                )}
              </div>
            ))}
          </div>

          {/* Wednesday Tasks */}
          <div className={classes.wednesday}>
            <div className={classes.weeklabel}>
              <p>Wednesday</p>
              {isEditingTask && (
              <button onClick={() => addTask('Wednesday')} className={classes.editbtnhedtsks} ><AddIcon/></button>
            )}
              </div>
            {wednesdayTasks.map((task) => (
              <div key={task.id} className={classes.tasks} style={{
                backgroundColor: colors[task.id % colors.length],
              }}>
                {isEditingTask ? (
                  <input
                    type="text"
                    placeholder='Task'
                    value={task.task}
                    onChange={(e) => handleTaskChange('Wednesday', task.id, e.target.value)}
                  />
                ) : (
                  <p>{task.task}</p>
                )}
                {isEditingTask && (
                  <button onClick={() => deleteTask('Wednesday', task.id)}
                  className={classes.deleteButtonGlance}
                  >
                  <DeleteIcon/>  
                    
                    </button>
                )}
              </div>
            ))}
          </div>

          {/* Thursday Tasks */}
          <div className={classes.thursday}>
            <div className={classes.weeklabel}>
              <p>Thursday</p>
              {isEditingTask && (
              <button onClick={() => addTask('Thursday')} className={classes.editbtnhedtsks} ><AddIcon/></button>
            )}
              </div>
            {thursdayTasks.map((task) => (
              <div key={task.id} className={classes.tasks} style={{
                backgroundColor: colors[task.id % colors.length],
              }}>
                {isEditingTask ? (
                  <input
                    type="text"
                    placeholder='Task'
                    value={task.task}
                    onChange={(e) => handleTaskChange('Thursday', task.id, e.target.value)}
                  />
                ) : (
                  <p>{task.task}</p>
                )}
                {isEditingTask && (
                  <button onClick={() => deleteTask('Thursday', task.id)}
                  className={classes.deleteButtonGlance}
                  >
                    <DeleteIcon/>
                  </button>
                )}
              </div>
            ))}
          </div>

          {/* Friday Tasks */}
          <div className={classes.friday}>
            <div className={classes.weeklabel}>
              <p>Friday</p>
              {isEditingTask && (
              <button onClick={() => addTask('Friday')} className={classes.editbtnhedtsks} ><AddIcon/></button>
            )}
              </div>
            {fridayTasks.map((task) => (
              <div key={task.id} className={classes.tasks} style={{
                backgroundColor: colors[task.id % colors.length],
              }}>
                {isEditingTask ? (
                  <input
                    type="text"
                    placeholder='Task'
                    value={task.task}
                    onChange={(e) => handleTaskChange('Friday', task.id, e.target.value)}
                  />
                ) : (
                  <p>{task.task}</p>
                )}
                {isEditingTask && (
                  <button onClick={() => deleteTask('Friday', task.id)}
                   className={classes.deleteButtonGlance}
                  >
                  <DeleteIcon/>  
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  );
};

export default Objectives;
