import React from 'react';
import { Route, Routes, NavLink, Navigate } from 'react-router-dom';
import Business from '../pages/Contracts/BusinessContract';
import Personal from '../pages/Contracts/PersonalContract';





import classes from '../styles/billsInvoices/billsInvoices.module.css';

const Contracts = () => {
  return (
    <div className={classes['account-setting']}>
      <nav className={classes.tabs}>
        <NavLink
          to="business"
          className={({ isActive }) => (isActive ? `${classes.active} ${classes.tab}` : classes.tab)}
        >
          Business
        </NavLink>
        <NavLink
          to="personal"
          className={({ isActive }) => (isActive ? `${classes.active} ${classes.tab}` : classes.tab)}
        >
          Personal
        </NavLink>
      </nav>


      <div className={classes.content}>
        <Routes>
          <Route path="business" element={<Business />} />
          <Route path="personal" element={<Personal />} />

          <Route path="/" element={<Navigate to="business" />} /> {/* Default route */}
        </Routes>
      </div>
    </div>
  );
};

export default Contracts;
