import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AccountSetting from '../components/AccountSetting'; // Import AccountSetting component
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../aUrl'


const Settings = () => {
  const navigate = useNavigate()
  const localhost = config.apiUrl


  useEffect(() => {
    const fetchProtectedData = async () => {
      const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage

      try {
        const response = await axios.get(`${localhost}/protected`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the header
          },
        });

        if (response.data === true) {
          console.log("Access granted:", response.data); // The token was verified successfully
          // Proceed with your page logic here
        }

      } catch (error) {
        navigate('/client/login');
      }
    };
    fetchProtectedData();
  });

  return (
    <div>
      <h1>Settings</h1>
      <Routes>
        <Route path="/*" element={<AccountSetting />} /> {/* Render AccountSetting */}
      </Routes>
    </div>
  );
};

export default Settings;
