import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure the CSS for toast notifications is imported
import classes from "../styles/setpassword.module.css";
import axios from "axios";
import config from '../aUrl'


const Setpassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const localhost = config.apiUrl


  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [data, setData] = useState({
    password: "",
    Cpassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 

    if (data.password !== data.Cpassword) {
      toast.error("Passwords do not match!");
      return;
    }
     else if (data.email !== data.Cemail) {
      toast.error("Emails do not match!");
      return;
    }
    else if (!/[A-Z]/.test(data.password)) {
      // errorMessages.passwordError = "Password must contain at least one uppercase letter.";
      toast.error("your password must contain the Uppercase letter")
      return;
    }
    else if (!/[a-z]/.test(data.password)) {
      toast.error("your password must contain the lowercase letter")
      return;
    }
    else if (!/[0-9]/.test(data.password)) {
      toast.error("your password must contain the digit")
      return;
    }
    else if (!/[!@#$%^&*(),.?":{}|<>]/.test(data.password)) {
      toast.error("your password must contain the Special Character")
      return;
    }

    try {
      const res = await axios.post(`${localhost}/resetpassword`, {
        email: location.state.email,
        password: data.password,
        Cpassword: data.Cpassword
      });
      toast.success("Password updated successfully, login now");
      setTimeout(()=>{
        navigate('/client/login')
      },2000)
    } catch (error) {
      toast.error("Error");
    }
  };

  return (
    <div className={classes.outercontainer}>
      <ToastContainer />
      <h1 className={classes.loginheader}>Reset Your Password</h1>
      <div className={classes.logincontainer}>
        <div className={classes.logincard}>
          {/* <p className={classes.otptext}>We'll send a password reset link to your email.</p> */}
          <br />

          {/* PASS RESET FORM  */}
          <form onSubmit={handleSubmit}>
            <label htmlFor="email" className={classes.labels}>
              Enter New Password
              <span className={classes.star}> *</span>
            </label>
            <br />

            {/* PASSWORD INPUT  */}
            <input
              type="password"
              placeholder="Enter New Password"
              className={classes.inputs}
              name="password" // Added name attribute
              value={data.password}
              onChange={handleChange}
              required
            />

            <input
              type="password"
              placeholder="Confirm New Password"
              className={classes.inputs}
              name="Cpassword" // Added name attribute
              value={data.Cpassword}
              onChange={handleChange}
              required
            />

            <br />

            {/* RESET PASS SUBMIT BUTTON  */}
            <div className={classes.loginbtnbox}>
              <button type="submit" className={classes.loginbutton}>
                Submit
              </button>
            </div>
          </form>

          <div className={classes.signupbox}>
            <p>Know your password?</p>

            <p>
              <Link to="/client/login" className={classes.signuplinklink}>
                Login Here
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setpassword;