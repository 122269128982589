import React, { useState, useEffect } from 'react';
import classes from '../styles/header.module.css';
import SearchBar from './SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../aUrl'


const Header = () => {
  const localhost = config.apiUrl;

  const [user, setUser] = useState({});
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const username = localStorage.getItem("emails"); // Get userId from local storage
        const response = await axios.get(`${localhost}/userdata/${username}`);
        setUser(response.data); // Set the user data including the imageUrl
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();

  }, []);



const navigate = useNavigate();

  function profile(){
    navigate("/client/dashboard/settings/profile")
}
 

function proactive(){
  navigate("/client/dashboard/proactive")
}


function notifications(){
  navigate("/client/dashboard/notifications")
}
 

  return (
    <div className={classes.header}>
      <div className={classes.left}>
        <SearchBar />
      </div>



      <div className={classes.right}>




        <div className={classes.noti} onClick={notifications}>
          <FontAwesomeIcon icon={faBell} />
        </div>
        <div className={classes.noti} onClick={proactive}>
          <FontAwesomeIcon icon={faEnvelope} />
        </div>
        <div className={classes.noti} onClick={profile}>   
          {/* IN UR CODE U WROTE THE CLASS NAME "noti1" */}


            {/* IF IMAGE ISN'T THERE THEN LOGO WILL SHOW. I HAVE UPDATED IT.   */}

          {user.imageUrl ? (
            <img src={user.imageUrl} alt="Profile" />
          ) : (
            <FontAwesomeIcon icon={faUser} />
          )}

        </div>

      </div>


    </div>
  );
};

export default Header;
