import React, { useState, useEffect } from 'react';
import classes from '../../styles/billsInvoices/pending.module.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';









const Pending = () => {
    const [pendingData, setPendingData] = useState([
        {
            amount: "500",
            dueDate: "01/05/2024",
            invoiceDoc: null,
            link: "https://stripe.com/in"
        },
        {
            amount: "600",
            dueDate: "01/04/2024",
            invoiceDoc: null,
            link: "https://stripe.com/in"
        },
    ]);

    const [originalData, setOriginalData] = useState([]);  // Store original data before editing or directly call database api to get original data
    const [isEditing, setIsEditing] = useState(false);







    // don't touch this code below ____________________________________________________________________________________________
    // Helper functions to convert date formats
    const convertToDateInputFormat = (date) => {
        const [month, day, year] = date.split('/');
        return `${year}-${month}-${day}`;
    };

    const convertToDisplayFormat = (date) => {
        const [year, month, day] = date.split('-');
        return `${month}/${day}/${year}`;
    };

    // Sort data based on date (descending order)
    const sortDataByDate = (data) => {
        return [...data].sort((a, b) => new Date(convertToDateInputFormat(b.dueDate)) - new Date(convertToDateInputFormat(a.dueDate)));
    };

    // Initial sort and when data is changed
    useEffect(() => {
        if (!isEditing) {
            const sortedData = sortDataByDate(pendingData);
            setPendingData(sortedData);
        }
    }, [isEditing, pendingData]);

    // Toggle editing state
    const handleEditToggle = () => {
        if (!isEditing) {
            setOriginalData([...pendingData]);
        }
        setIsEditing(!isEditing);
    };

    
    // Delete item
    const handleDeleteClick = (index) => {
        const updatedData = pendingData.filter((_, i) => i !== index);
        setPendingData(updatedData);
    };

    // Handle input changes
    const handleInputChange = (index, field, value) => {
        const updatedData = pendingData.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
        );
        setPendingData(updatedData);
    };

    // Handle file upload (only PDFs under 2MB)
    const handleFileUpload = (index, fileInput) => {
        const file = fileInput.files[0]; // Access the uploaded file
        if (file) {
            if (file.type !== "application/pdf") {
                toast.error("Only PDF files are allowed!");
                fileInput.value = ""; // Clear the file input
                return;
            }

            if (file.size > 2 * 1024 * 1024) { // 2MB in bytes
                toast.error("File size must be less than 2MB!");
                fileInput.value = ""; // Clear the file input
                return;
            }

            // If the file is valid, update the data
            const updatedData = pendingData.map((item, i) =>
                i === index ? { ...item, invoiceDoc: file } : item
            );
            setPendingData(updatedData);
        }
    };


    // Add new entry at the top
    const handleAddClick = () => {
        const newEntry = {
            amount: "",
            dueDate: "",
            invoiceDoc: null,
            link: ""
        };
        setPendingData([newEntry, ...pendingData]); // Prepend new entry to the list
    };
// don't touch this code above ____________________________________________________________________________________________







// Save changes
const handleSave = () => {

    // Check if any field is empty
    const hasEmptyFields = pendingData.some(item =>
        !item.amount || !item.dueDate || !item.invoiceDoc || !item.link
    );
    
    // Show error message if any field is empty
    if (hasEmptyFields) {
        toast.error("All fields must be filled!");
        return;
    }

    // Sort data before saving (don't touch this)
    const sortedData = sortDataByDate(pendingData);
    setPendingData(sortedData);
    setIsEditing(false);
};



// Cancel editing and restore original data
const handleCancel = () => {
    setPendingData(originalData);   // Restore original data ( u can use this state or call database api to get original data)
    setIsEditing(false);
};




















    return (
        <div>
            {/* notification */}
            <ToastContainer />
            <h2>Pending Bills</h2>
            <br />

            <div className={classes.pendingbox}>
                <div className={classes.inotrhead}>
                    <button
                        className={classes.editbutton}
                        onClick={isEditing ? handleCancel : handleEditToggle}
                    >
                        {isEditing ? 'Cancel' : 'Edit'}
                    </button>
                    {isEditing && (
                        <button className={classes.savebutton} onClick={handleSave}>
                            Save
                        </button>
                    )}
                </div>

                {isEditing && (
                    <div className={classes.addbtnlst}>
                        <button
                            type='button'
                            className={classes.addlstbtn2}
                            onClick={handleAddClick}
                            disabled={!isEditing}
                        >
                            Add
                        </button>
                    </div>
                )}

                <div className={classes.table}>
                    <div className={classes.labeloflist}>
                        <div className={classes.lsth2}>Amount<br />[USD]</div>
                        <div className={classes.lsth3}>Due date<br />[MM/DD/YYYY]</div>
                        <div className={classes.lsth4}>Invoice</div>
                        <div className={classes.lsth5}>Payment Link</div>
                    </div>

                    <div className={classes.listsbox}>
                        {pendingData.map((item, index) => (
                            <div key={index} className={classes.singlelist}>
                                <div className={classes.itmh2}>
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            value={item.amount}
                                            onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
                                        />
                                    ) : (
                                        `$${item.amount} USD`
                                    )}
                                </div>

                                <div className={classes.itmh3}>
                                    {isEditing ? (
                                        <input
                                            type="date"
                                            value={convertToDateInputFormat(item.dueDate)}
                                            onChange={(e) => handleInputChange(index, 'dueDate', convertToDisplayFormat(e.target.value))}
                                        />
                                    ) : (
                                        item.dueDate
                                    )}
                                </div>

                                <div className={classes.itmh4}>
                                    {isEditing ? (
                                        <input
                                            type="file"
                                            accept=".pdf"
                                            onChange={(e) => handleFileUpload(index, e.target)}
                                        />
                                    ) : (
                                        <a href={item.invoiceDoc} download target="_blank" rel="noopener noreferrer" className={classes.downloadButton1}>
                                            <p>View/Download</p>
                                        </a>
                                    )}
                                </div>

                                <div className={classes.itmh51}>
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            value={item.link}
                                            onChange={(e) => handleInputChange(index, 'link', e.target.value)}
                                        />
                                    ) : (
                                        <a href={item.link} download target="_blank" rel="noopener noreferrer" className={classes.downloadButton}>
                                            <p>Pay Now</p>
                                        </a>
                                    )}
                                </div>

                                {isEditing && (
                                    <a
                                        className={classes.deleteButtonlst}
                                        onClick={() => handleDeleteClick(index)}
                                    >
                                        <DeleteIcon />
                                    </a>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pending;
