import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from '../../styles/faq.module.css';

const S1 = () => {



    const FAQ = [
        {
          question: "Where can I manage my payment setup?",
          answer: "You can manage your payment setup in the 'Payment' section of the dashboard. This section allows you to add or update payment methods and review your payment history."
        },
        {
          question: "How do I add a new payment method?",
          answer: "In the 'Payment' section, click on 'Add Payment Method' and follow the prompts to enter your new payment information."
        },
        {
          question: "How can I view my payment history?",
          answer: "Payment history is available in the 'Payment' section, where you can review past transactions and download receipts."
        },
        {
          question: "Can I set up recurring payments?",
          answer: "Yes, you can set up recurring payments in the 'Payment' section by selecting the 'Set Up Recurring Payment' option."
        }
      ];



    return (
        <div className={classes.priv}>
            <h1>Payment FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
