import React, { useState, useEffect } from "react";
import classes from "../styles/status.module.css";
import axios from 'axios';

const Status = () => {
  const [isStatus, SetIsStatus] = useState({});
  const [isDeliverable, SetIsDeliverable] = useState({});

  useEffect(() => {
    const fetchStatus = async () => {
      const userId = localStorage.getItem("username");
      try {
        const response = await axios.get(`http://localhost:5500/deliverable/Status/${userId}`);
        const result = response.data;
        SetIsStatus(result);
      } catch (error) {
        console.error("Error fetching status:", error);
        // Handle error message display on page
      }
    };

    const fetchDeliverable = async () => {
      const userId = localStorage.getItem("username");
      try {
        const response = await axios.get(`http://localhost:5500/deliverable/Array/${userId}`);
        const result = response.data;
        SetIsDeliverable(result);
      } catch (error) {
        console.error("Error fetching deliverables:", error);
        // Handle error message display on page
      }
    };

    fetchStatus();
    fetchDeliverable();
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case "Complete":
        return "#C0FFCA"; // Light green
      case "In Progress":
        return "#FFF6A8"; // Light yellow
      case "Not Started":
        return "#FFC7A8"; // Light red
      default:
        return "#FFFFFF"; // Default color
    }
  };

  return (
    <div>
      <h1>Deliverables Status</h1>
      <br />

      <div className={classes.outerbox}>
        <div className={classes.box1}>
          <div className={classes.inbox}>
            <div className={classes.txt}>
              <label>Project Name</label>
            </div>
            <div className={classes.txt2}>
              <p className={classes.p}>{isStatus?.name || "N/A"}</p>
            </div>
          </div>

          <div className={classes.inbox}>
            <div className={classes.txt}>
              <label>Project ID</label>
            </div>
            <div className={classes.txt2}>
              <p className={classes.p}>#{isStatus?.projectId || "N/A"}</p>
            </div>
          </div>

          <div className={classes.inbox}>
            <div className={classes.txt}>
              <label>Project Manager</label>
            </div>
            <div className={classes.txt2}>
              <p className={classes.p}>{isStatus?.projectManager || "N/A"}</p>
            </div>
          </div>

          <div className={classes.inbox}>
            <div className={classes.txt}>
              <label>Status</label>
            </div>
            <div className={classes.txt2}>
              <div className={classes.clrbox}>
                <p className={classes.p}>{isStatus?.status || "N/A"}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.box2}>
          <div className={classes.table}>
            <div className={classes.labeloflist}>
              <div className={classes.lsth1}>Sr. No.</div>
              <div className={classes.lsth2}>Deliverable Name</div>
              <div className={classes.lsth3}>Description</div>
              <div className={classes.lsth4}>Owner</div>
              <div className={classes.lsth5}>Status</div>
            </div>

            <div className={classes.listsbox}>
              {isDeliverable.DeliverableArray && isDeliverable.DeliverableArray.length > 0 ? (
                isDeliverable.DeliverableArray.map((item, index) => (
                  <div
                    key={index}
                    className={classes.singlelist}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                    }}
                  >
                    <div className={classes.itmh1}>{item.name || "n/a"}</div>
                    <div className={classes.itmh2}>{item.description || "n/a"}</div>
                    <div className={classes.itmh3}>{item.owner || "n/a"}</div>
                    <div className={classes.itmh4}>{item.status || "n/a"}</div>
                    <div className={classes.itmh51}>
                      <div
                        className={classes.itmh5}
                        style={{ backgroundColor: getStatusColor(item.status) }}
                      >
                        {item.status}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No deliverables available.</p>
              )}
            </div>
          </div>

          <div className={classes.legndoutr}>
            <div>
              <h2>LEGENDS</h2>
              <br />

              <div className={classes.lgndin}>
                <div className={classes.clrleg1}></div>
                &nbsp;<p>Complete</p>
              </div>

              <div className={classes.lgndin}>
                <div className={classes.clrleg2}></div>
                &nbsp;<p>In Progress</p>
              </div>

              <div className={classes.lgndin}>
                <div className={classes.clrleg3}></div>
                &nbsp;<p>Not Started</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Status;
