import React from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

// Custom tooltip to format the value
function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={`${value}%`}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

// Define the shadow style
const iOSBoxShadow = 'none';

// Styled Slider component with customized styles
const IOSSlider = styled(Slider)(({ theme }) => ({
  color: '#F1D575', // Slider color
  height: '100%', // Height of the slider
  padding: '0', // Padding around the slider
  
  '& .MuiSlider-thumb': {
    height: '100%', // Thumb height
    width: '10px', // Thumb width
    backgroundColor: '#836707', // Thumb background color
    border: 'none', // Thumb border
    boxShadow: iOSBoxShadow, // Thumb shadow
    borderRadius: '5px', // Thumb border radius
    cursor: 'default', // Default cursor to indicate non-interactivity
    '&.Mui-disabled': {
      backgroundColor: '#836707', // Keep thumb color when disabled
    },
  },

  '& .MuiSlider-valueLabel': {
    fontSize: 16, // Font size for the value label
    fontWeight: 'bold', // Font weight for the value label
    top: 0, // Position of the value label
    backgroundColor: 'transparent', // Background color of the value label
    color: theme.palette.text.primary, // Color of the value label
    '&::before': {
      display: 'none', // Hide before element
    },
    '& *': {
      background: 'transparent', // Background of value label content
      color: theme.palette.mode === 'dark' ? '#fff' : '#000', // Color based on theme
    },
  },
  
  '& .MuiSlider-track': {
    border: 'none', // Track border
    height: '90%',
    borderRadius: '5px 0px 0px 5px', // Track height
    '&.Mui-disabled': {
      backgroundColor: '#F1D575', // Keep track color when disabled
    },
  },
  
  '& .MuiSlider-rail': {
    opacity: 0.7, // Rail opacity
    backgroundColor: '#F5F5F5', // Rail background color
    height: '90%', // Rail height
    borderRadius: '5px 5px 5px 5px', // Rail border radius
    '&.Mui-disabled': {
      backgroundColor: '#F5F5F5', // Keep rail color when disabled
    },
  },

  // Apply styles for the entire slider in the disabled state
  '&.Mui-disabled': {
    color: '#F1D575', // Keep the slider color when disabled
    cursor: 'default', // Default cursor when disabled
  },
}));

// Slidercomplete component
const Slidercomplete = ({ value, onSliderChange, editable }) => {
  const handleChange = (event, newValue) => {
    onSliderChange(newValue); // Notify parent of the change
  };

  return (
    <IOSSlider
      aria-label="ios slider"
      min={0}
      max={100}
      value={value}          // Controlled value from parent
      step={1}
      valueLabelDisplay="on"
      ValueLabelComponent={ValueLabelComponent} // Use the custom tooltip
      onChange={editable ? handleChange : undefined} // Enable or disable slider
      disabled={!editable} // Set slider disabled based on edit mode
    />
  );
};

Slidercomplete.propTypes = {
  value: PropTypes.number.isRequired,
  onSliderChange: PropTypes.func.isRequired, // New prop to handle slider change
  editable: PropTypes.bool.isRequired, // New prop to control editability
};

export default Slidercomplete;
