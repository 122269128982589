import React from 'react';
import classes from '../../styles/billsInvoices/history.module.css';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Sample history data
const history = [
    {
        amount: "500",
        dueDate: "12/12/2021",
        paidOn: "12/12/2021",
        paidOnTime: "12:00 PM",
        invoiceDoc: "https://www.freshbooks.com/wp-content/uploads/2023/08/PDF-Invoice-Templates-US-Template-01.pdf",
        status: "Paid"
    },
    {
        amount: "500",
        dueDate: "11/12/2021",
        paidOn: "11/12/2021",
        paidOnTime: "12:00 PM",
        invoiceDoc: "https://www.freshbooks.com/wp-content/uploads/2023/08/PDF-Invoice-Templates-US-Template-01.pdf",
        status: "Paid"
    },
    {
        amount: "500",
        dueDate: "10/12/2021",
        paidOn: "10/12/2021",
        paidOnTime: "12:00 PM",
        invoiceDoc: "https://www.freshbooks.com/wp-content/uploads/2023/08/PDF-Invoice-Templates-US-Template-01.pdf",
        status: "Paid"
    },
    {
        amount: "500",
        dueDate: "12/12/2021",
        paidOn: "12/12/2021",
        paidOnTime: "12:00 PM",
        invoiceDoc: "https://www.freshbooks.com/wp-content/uploads/2023/08/PDF-Invoice-Templates-US-Template-01.pdf",
        status: "Paid"
    },
    {
        amount: "500",
        dueDate: "11/12/2021",
        paidOn: "11/12/2021",
        paidOnTime: "12:00 PM",
        invoiceDoc: "https://www.freshbooks.com/wp-content/uploads/2023/08/PDF-Invoice-Templates-US-Template-01.pdf",
        status: "Paid"
    },
    {
        amount: "500",
        dueDate: "10/12/2021",
        paidOn: "10/12/2021",
        paidOnTime: "12:00 PM",
        invoiceDoc: "https://www.freshbooks.com/wp-content/uploads/2023/08/PDF-Invoice-Templates-US-Template-01.pdf",
        status: "Paid"
    },
];

// Convert date format to YYYY-MM-DD for comparison
const parseDate = (dateString) => {
    const [month, day, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
};

const History = () => {
    // Sort history array by 'paidOn' date in descending order
    const sortedHistory = [...history].sort((a, b) => {
        return parseDate(b.paidOn) - parseDate(a.paidOn);
    });

    return (
        <div>
            <h2>Bills History</h2>
            <br></br>

            <div className={classes.pendingbox}>
                <div className={classes.table}>
                    <div className={classes.labeloflist}>
                        <div className={classes.lsth1}>Amount<br />[USD]</div>
                        <div className={classes.lsth2}>Due date<br />[MM/DD/YYYY]</div>
                        <div className={classes.lsth3}>Paid on<br />[MM/DD/YYYY]</div>
                        <div className={classes.lsth4}>Details</div>
                        <div className={classes.lsth5}>status</div>
                    </div>

                    <div className={classes.listsbox}>
                        {sortedHistory.map((item, index) => (
                            <div
                                key={index}
                                className={classes.singlelist}
                            >
                                <div className={classes.itmh1}>${item.amount} USD</div>
                                <div className={classes.itmh2}>{item.dueDate}</div>
                                <div className={classes.itmh3}>{item.paidOn}<div><AccessTimeIcon/>&nbsp;{item.paidOnTime}</div></div>

                                <div className={classes.itmh4}>
                                    <a href={item.invoiceDoc} download target="_blank" rel="noopener noreferrer" className={classes.downloadButton1} >
                                        <p>View/Download</p>
                                    </a>
                                </div>

                                <div className={classes.itmh51}>
                                    <div className={classes.downloadButtonstat} >
                                        <p>{item.status}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default History;
