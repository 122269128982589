import React, { useState } from 'react';
import classes from '../styles/projectScope/projectScope.module.css';
import OverallProgress from '../components/projectScope/OverallProgress';
import ProjectTimeline from '../components/projectScope/ProjectTimeline';
import BurnGraph from '../components/projectScope/BurnGraph';
import Activities from '../components/projectScope/Activities';
import Issues from '../components/projectScope/Issues';
import Risks from '../components/projectScope/Risks';
import Audit from '../components/projectScope/Audit';















const ProjectScope = () => {


  const statusColor = 'green'; // Change this to 'Green', 'yellow' or 'red' to change the color of the Overall Status

  const progressValue = 10; // For example, 75% progress



 










  






  return (
    <div>


      <div className={classes.headerbx}>

        <div className={classes.scopehead}>
          <h1>Project Scope</h1>
        </div>

        <div className={classes.headerbx2}>


          {/* Overall Status */}
          <div className={classes.overstat}>
            <p className={classes.headptxt}>Overall Status</p> <div className={classes.statclr} style={{ backgroundColor: statusColor }}></div>
          </div>

          {/* Overall Progress */}
          <div className={classes.overstat}>
            <p className={classes.headptxt}>Overall Progress</p>  <div className={classes.ovrprg}> <OverallProgress progress={progressValue} /> </div>
          </div>


        </div>
      </div>






      <br></br>


      {/* activity and timeline */}



      <div className={classes.actiline}>

      
        

        {/* ongoing Timeline  */}
        





          <ProjectTimeline />


        


      </div>











      {/* Issue Burndown risks */}



      <div className={classes.isuebrnrsk}>




<div className={classes.actvty} > 
<Activities/>
</div>




<div className={classes.actvty} > 
<Issues/>
</div>










        {/* Top 5 issues  */}
        




        

        


      </div>







<div className={classes.row3} >





  {/* Risks  */}

  <div className={classes.rsks}>
          <Risks />
        </div>







{/* Burndown Graph  */}

<div className={classes.brngraph}>

<div className={classes.onghed}><h2>Burndown Graph</h2></div>
<div className={classes.brngraphheight}>


  <BurnGraph />
</div>
</div>



</div>




















<div className={classes.audit} >

<Audit/>
</div>














    </div >
  );
};

export default ProjectScope;
