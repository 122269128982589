import React from "react";
import classes from "../../styles/projecttimeline.module.css";
import EastIcon from "@mui/icons-material/East";
import DoneIcon from "@mui/icons-material/Done";
import { useState, useEffect } from "react";
import axios from "axios";

const ProjectTimeline = () => {
  const sprints = [
    { number: "01", head: "Sprint 1", description: "kjfsejkfjkwe weihi ewh" },
    { number: "02", head: "Sprint 2", description: "kjfsejkfjkwe weihi ewh" },
    { number: "03", head: "Sprint 3", description: "kjfsejkfjkwe weihi ewh" },
    { number: "04", head: "Sprint 4", description: "kjfsejkfjkwe weihi ewh" },
    { number: "05", head: "Sprint 5", description: "kjfsejkfjkwe weihi ewh" },
  ]


    // Project timeline dates
    const projectTimelineDates = {
      startDate: '01/22/2023',
      endDate: '10/18/2023',
    };
  
  



  const [isStep, setIsStep] = useState([]);

  useEffect(()=>{
    const fetchTimeline = async () =>{
      const userId = localStorage.getItem("username")
      try {
        const response = await axios.get(`http://localhost:5500/project/Timeline/${userId}`);
      const result = response.data;
      if(response.status === 200 && result)
      {
        setIsStep(result);
      }
      } catch (error) {
        alert(error);
      }
      
    }
    fetchTimeline();
  },[])

  return (
    <div className={classes.timelineContainer}>
      <div className={classes.timelineItem}>
        <div className={classes.lineinmid}></div>

        <div className={classes.strt}>
        <p>Release&nbsp;Start<br /> <span className={classes.sprntdts} >{projectTimelineDates.startDate}</span></p>



          <div className={classes.start}>
            {" "}
            <EastIcon />{" "}
          </div>
        </div>

        {isStep.map((sprint, index) => (
          <div
            key={index}
            className={`${index % 2 === 0 ? classes.odd : classes.even}`}
          >
            {index % 2 !== 0 && (
              <div className={classes.descriptionodd}>
                <p2>{sprint.head}</p2>
                <p>{sprint.description}</p>
              </div>
            )}

            <div className={classes.circle}>
              <p>0{index+1}</p>
            </div>

            {index % 2 === 0 && (
              <div className={classes.descriptioneven}>
                <p2>{sprint.head}</p2>
                <p>{sprint.description}</p>
              </div>
            )}
          </div>
        ))}

        <div className={classes.strtop}>
        <p>Release&nbsp;End<br /> <span className={classes.sprntdts} >{projectTimelineDates.endDate}</span></p>

          <div className={classes.stop}>
            <DoneIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectTimeline;
