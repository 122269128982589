import React from "react";
// import { useState } from "react";
// import { useNavigate, Link } from "react-router-dom";
// import { toast, ToastContainer } from "react-toastify"; // Assuming you are using react-toastify for notifications
import classes from "../styles/reset.module.css";

const Reset = () => {
  return (
    <div className={`${classes.outercontainer}`}>
      <h1 className={`${classes.loginheader}`}>Set a New Password</h1>

      <div className={`${classes.logincontainer}`}>
        <div className={`${classes.logincard}`}>
          <p className={`${classes.otptext}`}>
            Please set a new password to secure your account.
          </p>
          <br></br>

          {/* PASS RESET FORM  */}

          <form onSubmit="">
            <label htmlFor="password" className={`${classes.labels}`}>
              Enter Password<span className={`${classes.star}`}> *</span>
            </label>
            <br></br>

            {/* PASSWORD INPUT  */}

            <input
              type="password"
              placeholder="Enter Password"
              className={`${classes.inputs}`}
              required
            />

            <label htmlFor="cpassword" className={`${classes.labels}`}>
              Confirm Password<span className={`${classes.star}`}> *</span>
            </label>
            <br></br>

            {/* CONFIRM PASS INPUT  */}

            <input
              type="password"
              placeholder="Confirm Password"
              className={`${classes.inputs}`}
              required
            />

            <br></br>

            {/* RESET PASS SUBMIT BUTTON  */}
            <div className={`${classes.loginbtnbox}`}>
              <button type="submit" className={`${classes.loginbutton}`}>
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Reset;