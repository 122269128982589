import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from '../../styles/faq.module.css';

const S1 = () => {



    const FAQ = [
        {
          question: "How do I log out of the dashboard?",
          answer: "To log out of the dashboard, click on the 'Logout' button usually found in the top-right corner of the interface. This will securely log you out of your account."
        }
      ];



    return (
        <div className={classes.priv}>
            <h1>Logout FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
