import React from 'react';
import classes from '../styles/header.module.css';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faEnvelope, faUser} from '@fortawesome/free-solid-svg-icons';

const Header = () => {

  const navigate = useNavigate();

   function notifications(){
       navigate("/admin/dashboard/notifications")
   }

   
   function messages(){
    navigate("/admin/dashboard/messages")
}


   function profile(){
    navigate("/admin/dashboard/settings/profile")
}

   


  return (
    <div className={classes.header}>
      <div className={classes.left}>
      <SearchBar/>
      </div>



      <div className={classes.right}>




        <div className={classes.noti} onClick={notifications} >
        <FontAwesomeIcon icon={faBell} />
        </div>
        <div className={classes.noti} onClick={messages}>
        <FontAwesomeIcon icon={faEnvelope} />
        </div>
        <div className={classes.noti} onClick={profile}>
        <FontAwesomeIcon icon={faUser} />
        </div>




      </div>


    </div>
  );
};

export default Header;
