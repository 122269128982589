import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import classes from '../../styles/client/clientlist.module.css';
import EditIcon from '@mui/icons-material/Edit';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { Link } from 'react-router-dom';
import config from '../../EditClient/aUrl';

function ActiveClient() {
    const navigate = useNavigate();
    const localhost = config.apiUrl;

    function editview() {
        navigate("/admin/dashboard/clientlist/edit");
    }

    const [searchTerm, setSearchTerm] = useState('');
    const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);
    const [user, setUser] = useState([]);

    const deactivate = async (id) => {
        try {
            const response = await fetch(`${localhost}/deactivateUser/${id}`, {
                method: "PUT"
            })
            if (response.ok) {
                alert("user Deactivated Successfully")
            }
            window.location.reload();
        } catch (error) {
            console.log("Error")
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${localhost}/allUserData`);
                const data = response.data;
                console.log(data);
                setUser(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);


    const filteredItems = user.filter((item) => {
        const lowerSearchTerm = searchTerm.toLowerCase();
        const matchName = item.name?.toLowerCase().includes(lowerSearchTerm);
        const matchBusinessName = item.businessData?.[0]?.bussinessName?.toLowerCase().includes(lowerSearchTerm);
        const matchBusinessEmail = item.businessData?.[0]?.bussinessEmail?.toLowerCase().includes(lowerSearchTerm);
        const matchBusinessPhone = item.businessData?.[0]?.bussinessPhone?.toLowerCase().includes(lowerSearchTerm);

        const matchPartner = item.partner?.some((partner) =>
            partner.partnerName?.toLowerCase().includes(lowerSearchTerm)
        );
        return matchName || matchBusinessName || matchBusinessEmail || matchBusinessPhone || matchPartner;
    });

    return (
        <div>
            <h1>Active Client List</h1>
            <br />

            <div className={classes.client}>
                <div className={classes.srch}>
                    {/* Search Input */}
                    <input
                        type="text"
                        placeholder="Search by Name, Partner, or Business Details"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onFocus={() => setIsSearchBarFocused(true)}
                        onBlur={() => setIsSearchBarFocused(false)}
                        className={`${classes.searchBar} ${isSearchBarFocused ? classes.searchBarExpanded : ''}`}
                    />
                </div>

                <div className={classes.pendingbox}>
                    <div className={classes.table}>
                        <div className={classes.labeloflist}>
                            <div className={classes.lsth1}>Sr. no.</div>
                            <div className={classes.lsth2}>Owner</div>
                            <div className={classes.lsth3}>Partners</div>
                            <div className={classes.lsth4}>Business Details</div>
                            <div className={classes.lsth5}>Actions</div>
                        </div>

                        <div className={classes.listsbox}>
                            {filteredItems.length > 0 ? (
                                filteredItems.map((item, index) => (
                                    <div key={index} className={classes.singlelist}>
                                        <div className={classes.itmh1}>#{index + 1}</div>

                                        <div className={classes.itmh2}>
                                            <p>{item.name || 'n/a'}</p>
                                        </div>

                                        <div className={classes.itmh3}>
                                            {item.partner && item.partner.length > 0 ? (
                                                item.partner.map((partner, partnerIndex) => (
                                                    <p key={partnerIndex}>
                                                        {partner.partnerName || 'n/a'}
                                                    </p>
                                                ))
                                            ) : (
                                                <p>n/a</p>
                                            )}
                                        </div>

                                        <div className={classes.itmh4}>
                                            {item.businessData && item.businessData.length > 0 ? (
                                                item.businessData.map((business, businessIndex) => (
                                                    <div key={businessIndex}>
                                                        <p>Company:&nbsp;<span>{business.bussinessName || 'n/a'}</span></p>
                                                        <p>Email:&nbsp;<span>{business.bussinessEmail || 'n/a'}</span></p>
                                                        <p>Tel:&nbsp;<span>{business.bussinessPhone || 'n/a'}</span></p>
                                                    </div>
                                                ))
                                            ) : (
                                                <div>
                                                    <p>Company:&nbsp;<span>n/a</span></p>
                                                    <p>Email:&nbsp;<span>n/a</span></p>
                                                    <p>Tel:&nbsp;<span>n/a</span></p>
                                                </div>
                                            )}
                                        </div>

                                        <div className={classes.itmh51}>

                                            <a href={`/admin/dashboard/clientlist/${item._id}/edit/`} className={`${classes.downloadButton} ${classes.editmargindwn}`} target="_blank" rel="noopener noreferrer">
                                                <p><span><EditIcon /></span>View/Edit</p>
                                            </a>


                                            <a onClick={() => deactivate(item._id)} className={`${classes.downloadButton} ${classes.deactivebtn}`}>
                                                <p><span><NoAccountsIcon /></span>Deactivate</p>
                                            </a>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className={classes.singlelistno}>
                                    <p>No accounts found</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ActiveClient;
