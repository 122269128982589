import React, { useState, useEffect } from 'react';
import classes from '../../styles/contracts.module.css';
import preview from '../../assets/pdf/1.jpeg';
import pdfExample from '../../assets/pdf/pdfExample.pdf';
import FileUpload from '../../components/FileUpload';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import config from '../../../../client/aUrl';
import axios from 'axios';

const BusinessContract = () => {
  const params = useParams();
  const localhost = config.apiUrl;

  useEffect(()=>{
    const fetchPdfData = async () => {
      try {
    toast.info("Currently Working on it")
        const response = await axios.get(`${localhost}/Bgetpdfs/${params.id}`);
        setPdfData(response.data.pdfs); // Adjust based on the actual response structure
      } catch (error) {
        console.error('Error fetching PDF data:', error);
      }
    };
    fetchPdfData();
  },[]);
  // Example data for PDFs
  const initialPdfData = [
    { image: preview, text: 'MOU', file: pdfExample },
    { image: preview, text: 'NDA', file: pdfExample },
    { image: preview, text: 'BUSINESS', file: pdfExample },
    { image: preview, text: 'PAYMENT', file: pdfExample },
    { image: preview, text: 'NEW PROJECT', file: pdfExample },
    { image: preview, text: 'MOU', file: pdfExample },
  ];

  // State for original data and search query
  const [originalPdfData, setOriginalPdfData] = useState(initialPdfData);
  const [pdfData, setPdfData] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Filter PDFs based on search query
  const filteredPdfData = pdfData.filter(pdf =>
    pdf.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleEditToggle = () => {
    if (!isEditing) {
      // Save a deep copy of the current state before editing
      setOriginalPdfData(JSON.parse(JSON.stringify(pdfData)));
    }
    setIsEditing(!isEditing);
  };

  const handleCancel = () => {
    // Restore the original state including names
    setPdfData(JSON.parse(JSON.stringify(originalPdfData)));
    setIsEditing(false); // Exit editing mode
  };

  const handleDownload = (file, e) => {
    if (isEditing) {
      e.stopPropagation(); // Prevent download in edit mode
      return;
    }
    const link = document.createElement('a');
    link.href = file;
    link.download = file.split('/').pop();
    link.click();
  };

  const handleNameChange = (index, newName) => {
    const updatedPdfData = [...pdfData];
    updatedPdfData[index].text = newName;
    setPdfData(updatedPdfData);
  };

  const handleDelete = (index) => {
    const updatedPdfData = pdfData.filter((_, i) => i !== index);
    setPdfData(updatedPdfData);
  };

  const handleSave = () => {
    // Check if any document name is empty
    const hasEmptyName = pdfData.some(pdf => pdf.text.trim() === '');
    if (hasEmptyName) {
      toast.error("Document names cannot be empty.");
      return;
    }
    // Save logic here (e.g., save to a server or local storage)
    toast.info("Currently Working on it")
    setIsEditing(false);
  };



  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);
  const handleFocus = () => {
    setIsSearchBarFocused(true);
  };




  return (
    <div>
      <div className={classes.otrhead}>
        <div>
          <h2>Business Contracts</h2>
        </div>
        <div className={classes.inotrhead}>
          <button
            className={classes.editbutton}
            onClick={isEditing ? handleCancel : handleEditToggle}
          >
            {isEditing ? 'Cancel' : 'Edit'}
          </button>
          {isEditing && (
            <button className={classes.savebutton} onClick={handleSave}>
              Save
            </button>
          )}
        </div>
      </div>

      {/* Search Input */}
      <div className={classes.searchContainer}>
        <input
          type="text"
          placeholder="Search Business Contracts..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={handleFocus}
          onBlur={() => setIsSearchBarFocused(false)}
          className={`${classes.searchBar} ${isSearchBarFocused ? classes.searchBarExpanded : ''}`}
        />

      </div>

      <div className={classes.pdfboxes}>
        {filteredPdfData.map((pdf, index) => (
          <div
            className={classes.pdfs}
            key={index}
            style={{ cursor: isEditing ? 'default' : 'pointer' }} // Change cursor based on editing mode
          >
            <div
              className={classes.photopdf}
              onClick={(e) => handleDownload(pdf.file, e)}
              style={{ pointerEvents: isEditing ? 'none' : 'auto' }} // Disable click if editing
            >
              <img src={pdf.imageUrl} alt={`Contract ${pdf.text}`} />
            </div>
            <div className={classes.txtout}>
              {isEditing ? (
                <input
                  type="text"
                  value={pdf.text}
                  onChange={(e) => handleNameChange(index, e.target.value)}
                  className={classes.editInput}
                />
              ) : (
                <p className={classes.pdftxt}>{pdf.text}</p>
              )}
              {isEditing && (
                <button
                  className={classes.deletebutton}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the download
                    handleDelete(index);
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        ))}

        {!isEditing && (
          <div className={classes.pdfsup}>
            <FileUpload />
          </div>
        )}
      </div>

      {/* ToastContainer for notifications */}
      <ToastContainer />
    </div>
  );
};

export default BusinessContract;
