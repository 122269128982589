import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/index.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
 // Import the CSS file for styling
import classes from '../styles/sidebar.module.css'; // Import the CSS file for styling
import logo from '../assets/omegalogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faGear, faFileContract, faFileInvoiceDollar, faLightbulb, faBullseye, faShieldAlt, faCog, faSignOutAlt, faMessage, faPersonRifle, faPerson, faUser } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
  
// navigation 
const navigate = useNavigate();

  const params = useParams();

  function adminhome(){
    navigate("/admin/dashboard/")
  }

  const navItems = [
    { to: `/admin/dashboard/clientlist/${params.id}/edit/details`, label: "Company Profile", icon: faUser },
    { to: `/admin/dashboard/clientlist/${params.id}/edit/status`, label: "Deliverables Status", icon: faGear },
    { to: `/admin/dashboard/clientlist/${params.id}/edit/contracts`, label: "Contracts", icon: faFileContract },
    { to: `/admin/dashboard/clientlist/${params.id}/edit/billsInvoices`, label: "Bills & Invoices", icon: faFileInvoiceDollar },
    { to: `/admin/dashboard/clientlist/${params.id}/edit/ProjectScope`, label: "Project Scope", icon: faLightbulb },
    { to: `/admin/dashboard/clientlist/${params.id}/edit/objectives`, label: "Objectives", icon: faBullseye },
  ];

  return (
    <aside className={classes.sidebar}>
      <div className={classes.logo}>
        <img src={logo} alt="Company Logo" />
      </div>
      <ul>
        {navItems.map(item => (
          <NavLink
          key={item.to}
          to={item.to}
          className={({ isActive }) => isActive ? classes.active : ''}
        >
          {({ isActive }) => (
            <li className={isActive ? classes['active-link'] : ''}>
              <div className={`${classes.bluemark} ${isActive ? classes.active : ''}`}></div>
              <div className={`${classes['link-content']} ${isActive ? classes.active : ''}`}>
                <span><FontAwesomeIcon icon={item.icon} />&nbsp;</span>{item.label}
              </div>
            </li>
          )}
        </NavLink>
        ))}
      </ul>
      <div className={classes.logbox}>
        <button onClick={adminhome} ><FontAwesomeIcon icon={faSignOutAlt} /> Admin Home</button>
      </div>
    </aside>
  );
};

export default Sidebar;
