import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "../../styles/accountSetting/business.module.css"; // Assuming this file exists
import { useNavigate } from "react-router-dom";
import config from "./url";

const Business = () => {
  const navigate = useNavigate();
  const localhost = config.apiUrl;
  
  const [formData, setFormData] = useState({
    businessType: "",
    specificCategory: "",
    businessName: "",
    businessAddress: "",
    businessCity: "",
    businessState: "",
    businessZip: "",
    businessPhone: "",
    businessFax: "",
    businessEmail: "",
    contactPersonPosition: "",
    preferredMethod: "",
    federalId: "",
    years: "",
    employees: "",
    annualRevenue: "",
  });


  // for business
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isLength, setIsLength] = useState(false);
  // for partner
  const [submitted, setSubmitted] = useState(false);
  const [submitted1, setSubmitted1] = useState(false);
  const [submitted2, setSubmitted2] = useState(false);
  const [submitted3, setSubmitted3] = useState(false);
  const [submitted4, setSubmitted4] = useState(false);

  const [partnerss, setPartnerss] = useState([
    {
      partnerName: "",
      partnerTitle: "",
      partnerAddress: "",
      partnerCity: "",
      partnerState: "",
      partnerZip: "",
      partnerPhone: "",
      partnerFax: "",
      partnerEmail: "",
      partnerCountry: "",
    },
  ]);

  const [partnerss1, setPartnerss1] = useState([
    {
      partnerName: "",
      partnerTitle: "",
      partnerAddress: "",
      partnerCity: "",
      partnerState: "",
      partnerZip: "",
      partnerPhone: "",
      partnerFax: "",
      partnerEmail: "",
      partnerCountry: "",
    },
  ]);

  const [partnerss2, setPartnerss2] = useState([
    {
      partnerName: "",
      partnerTitle: "",
      partnerAddress: "",
      partnerCity: "",
      partnerState: "",
      partnerZip: "",
      partnerPhone: "",
      partnerFax: "",
      partnerEmail: "",
      partnerCountry: "",
    },
  ]);

  const [partnerss3, setPartnerss3] = useState([
    {
      partnerName: "",
      partnerTitle: "",
      partnerAddress: "",
      partnerCity: "",
      partnerState: "",
      partnerZip: "",
      partnerPhone: "",
      partnerFax: "",
      partnerEmail: "",
      partnerCountry: "",
    },
  ]);

  const [partnerss4, setPartnerss4] = useState([
    {
      partnerName: "",
      partnerTitle: "",
      partnerAddress: "",
      partnerCity: "",
      partnerState: "",
      partnerZip: "",
      partnerPhone: "",
      partnerFax: "",
      partnerEmail: "",
      partnerCountry: "",
    },
  ]);

  useEffect(() => {
    const fetchBusinessData = async () => {
      const userId = localStorage.getItem("emails");

      const response = await fetch(
        `${localhost}/getBusinessInformation/${userId}`
      );
      const result = await response.json();

      if (response.ok && result.BusinessData) {
        setFormData(result.BusinessData);
        setIsSubmitted(true);
      } else {
        // toast.error("Failed to get business data")
        console.log("");
      }
    };

    const fetchProtectedData = async () => {
      const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage

      try {
        const response = await axios.get(`${localhost}/protected`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the header
          },
        });

        if (response.data === true) {
          console.log("Access granted:", response.data); // The token was verified successfully
          // Proceed with your page logic here
        }
      } catch (error) {
        navigate("/client/login");
      }
    };
    const fetchPartnerData = async () => {
      const userId = localStorage.getItem("emails");
      const response = await fetch(
        `${localhost}/getPartnerInformation/${userId}`
      );
      const result = await response.json();
      if (result.Length < 1 || result.Length === undefined) {
        setIsLength(true);
      }

      if (response.ok && result.Partner) {
        setPartnerss(result.Partner);
        setSubmitted(true);
      }

      if (response.ok && result.Partner1) {
        setPartnerss1(result.Partner1);
        setSubmitted1(true);
      }

      if (response.ok && result.Partner2) {
        setPartnerss2(result.Partner2);
        setSubmitted2(true);
      }

      if (response.ok && result.Partner3) {
        setPartnerss3(result.Partner3);
        setSubmitted3(true);
      }

      if (response.ok && result.Partner4) {
        setPartnerss4(result.Partner4);
        setSubmitted4(true);
      }
    };
    fetchProtectedData();
    fetchPartnerData();
    fetchBusinessData();
  }, []);

  const handleBussinessSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const BussinessData = {
      userId: localStorage.getItem("emails"),
      businessType: formData.get("businessType"),
      specificCategory: formData.get("specificCategory"),
      businessName: formData.get("businessName"),
      businessAddress: formData.get("businessAddress"),
      businessCity: formData.get("businessCity"),
      businessState: formData.get("businessState"),
      businessZip: formData.get("businessZip"),
      businessPhone: formData.get("businessPhone"),
      businessFax: formData.get("businessFax"),
      businessEmail: formData.get("businessEmail"),
      years: formData.get("years"),
      contactPersonPosition: formData.get("contactPersonPosition"),
      preferredMethod: formData.get("preferredMethod"),
      federalId: formData.get("federalId"),
      employees: formData.get("employees"),
      annualRevenue: formData.get("annualRevenue"),
    };

    const response = await fetch(`${localhost}/bussinessInformation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(BussinessData),
    });

    if (response.ok) {
      toast.success("Information Stored Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toast.error("Fill the form correctly");
    }
  }

  // Initial state with one empty partner object
  const [partners, setPartners] = useState([
    {
      partnerName: "",
      partnerTitle: "",
      partnerCity: "",
      partnerState: "",
      partnerCountry: "",
      partnerPhone: "",
      partnerFax: "",
      partnerEmail: "",
      partnerAddress: "",
      partnerZip: "",
    },
  ]);

  // Handle input change for dynamic form fields
  const handleInputChange = (index, field, value) => {
    const updatedPartners = [...partners];
    updatedPartners[index][field] = value;
    setPartners(updatedPartners);
  };

  // Add a new partner form
  const handleAddPartner = () => {
    if (partners.length < 5) {
      setPartners([
        ...partners,
        {
          partnerName: "",
          partnerTitle: "",
          partnerCity: "",
          partnerState: "",
          partnerCountry: "",
          partnerPhone: "",
          partnerFax: "",
          partnerEmail: "",
          partnerAddress: "",
          partnerZip: "",
        },
      ]);
    } else {
      toast.error("You can only add up to 5 partners.");
    }
  };

  // Remove a partner form
  const handleRemovePartner = (index) => {
    const updatedPartners = partners.filter((_, i) => i !== index);
    setPartners(updatedPartners);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const partnerData = {
      userId: localStorage.getItem("emails"), // Assuming user email is stored in localStorage
      partners, // Send the partners array
    };

    try {
      const response = await axios.post(
        `${localhost}/savePartner`,
        partnerData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Partner Information Stored Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      toast.error("Fill the form correctly");
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className={`${classes.profilesettingscontainer}`}>
        <div className={`${classes.personalsection}`}>
          <h2>Business Information</h2>

          <form onSubmit={handleBussinessSubmit}>
            {/* USER DETAILS */}
            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                {/* Business Type  */}
                <label htmlFor="business-type" className={`${classes.labels}`}>
                  Business Type
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.bussinessType}</p>
                  ) : (
                    <select
                      name="businessType"
                      className={`${classes.inputs}`}
                      value={formData.businessType}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          businessType: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Business Type</option>
                      <option value="retail">Retail</option>
                      <option value="service">Service</option>
                      <option value="manufacturing">Manufacturing</option>
                      <option value="wholesale">Wholesale</option>
                    </select>
                  )}
                </div>
              </div>
              <div className={`${classes.formitem}`}>
                {/* Specific Category */}
                <label htmlFor="last-name" className={`${classes.labels}`}>
                  Specific Category
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.specificCategory}</p>
                  ) : (
                    <select
                      name="specificCategory"
                      className={`${classes.inputs}`}
                      value={formData.specificCategory}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          specificCategory: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Specific Category</option>
                      <option value="electronics">Electronics</option>
                      <option value="clothing">Clothing</option>
                      <option value="furniture">Furniture</option>
                      <option value="food">Food</option>
                    </select>
                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                {/* Business Name  */}
                <label htmlFor="business-name" className={`${classes.labels}`}>
                  Business Name
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.bussinessName}</p>
                  ) : (
                    <input
                      name="businessName"
                      type="text"
                      placeholder="Business Name"
                      className={`${classes.inputs}`}
                      value={formData.businessName}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          businessName: e.target.value,
                        })
                      }
                      required
                    />
                  )}
                </div>
              </div>

              <div className={`${classes.formitem}`}>
                {/* Business Address  */}
                <label
                  htmlFor="business-address"
                  className={`${classes.labels}`}
                >
                  Business Address
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.bussinessAddress}</p>
                  ) : (
                    <input
                      type="text"
                      name="businessAddress"
                      placeholder="Business Address"
                      value={formData.businessAddress}
                      className={`${classes.inputs}`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          businessAddress: e.target.value,
                        })
                      }
                      required
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                {/* City  */}
                <label htmlFor="city" className={`${classes.labels}`}>
                  City
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.city}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="City"
                      name="businessCity"
                      value={formData.businessCity}
                      className={`${classes.inputs}`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          businessCity: e.target.value,
                        })
                      }
                      required
                    />
                  )}
                </div>
              </div>

              <div className={`${classes.formitem}`}>
                {/* STATE  */}
                <label htmlFor="state" className={`${classes.labels}`}>
                  State
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.state}</p>
                  ) : (
                    <input
                      type="text"
                      name="businessState"
                      placeholder="State"
                      value={formData.businessState}
                      className={`${classes.inputs}`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          businessState: e.target.value,
                        })
                      }
                      required
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                {/* ZIP  */}
                <label htmlFor="zip" className={`${classes.labels}`}>
                  Zip
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.zip}</p>
                  ) : (
                    <input
                      type="numbers"
                      placeholder="Zip"
                      name="businessZip"
                      value={formData.businessZip}
                      className={`${classes.inputs}`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          businessZip: e.target.value,
                        })
                      }
                      required
                    />
                  )}
                </div>
              </div>

              <div className={`${classes.formitem}`}>
                {/* Contact Person, Position */}
                <label
                  htmlFor="contact-person-position"
                  className={`${classes.labels}`}
                >
                  Contact Person, Position
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.contractPersonAndPosition}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="Contact Person, Position"
                      name="contactPersonPosition"
                      value={formData.contactPersonPosition}
                      className={`${classes.inputs}`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          contactPersonPosition: e.target.value,
                        })
                      }
                      required
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                {/* BUSINESS PHONE  */}
                <label htmlFor="business-phone" className={`${classes.labels}`}>
                  Business Phone
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.bussinessPhone}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="Business Phone"
                      name="businessPhone"
                      value={formData.businessPhone}
                      className={`${classes.inputs}`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          businessPhone: e.target.value,
                        })
                      }
                      required
                    />
                  )}
                </div>
              </div>

              <div className={`${classes.formitem}`}>
                {/* BUSINESS FAX  */}
                <label htmlFor="business-fax" className={`${classes.labels}`}>
                  Business Fax
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.bussinessFax}</p>
                  ) : (
                    <input
                      type="numbers"
                      placeholder="Business Fax"
                      name="businessFax"
                      value={formData.businessFax}
                      className={`${classes.inputs}`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          businessFax: e.target.value,
                        })
                      }
                      required
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                {/* BUSINESS EMAIL  */}
                <label htmlFor="business-email" className={`${classes.labels}`}>
                  Business E-mail
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.bussinessEmail}</p>
                  ) : (
                    <input
                      type="email"
                      placeholder="Business E-mail"
                      name="businessEmail"
                      value={formData.businessEmail}
                      className={`${classes.inputs}`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          businessEmail: e.target.value,
                        })
                      }
                      required
                    />
                  )}
                </div>
              </div>

              <div className={`${classes.formitem}`}>
                {/* PREFFERED METHOD  */}
                <label
                  htmlFor="preferred-method"
                  className={`${classes.labels}`}
                >
                  Preferred Method of Contact
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.modeOfContact}</p>
                  ) : (
                    <select
                      name="preferredMethod"
                      className={`${classes.inputs}`}
                      value={formData.preferredMethod}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          preferredMethod: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="" null>
                        Preferred method
                      </option>
                      <option value="Email">Email</option>
                      <option value="Call">Call</option>
                    </select>
                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                {/* FEDERAL Id#  */}
                <label htmlFor="federal-id" className={`${classes.labels}`}>
                  Federal Id#
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.federalId}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="Federal Id"
                      name="federalId"
                      value={formData.federalId}
                      className={`${classes.inputs}`}
                      onChange={(e) =>
                        setFormData({ ...formData, federalId: e.target.value })
                      }
                      required
                    />
                  )}
                </div>
              </div>

              <div className={`${classes.formitem}`}>
                {/* YEARS IN BUSINESS  */}
                <label htmlFor="years-business" className={`${classes.labels}`}>
                  # of years in business
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.years}</p>
                  ) : (
                    <input
                      type="numbers"
                      placeholder="Years in Business"
                      name="years"
                      value={formData.years}
                      className={`${classes.inputs}`}
                      onChange={(e) =>
                        setFormData({ ...formData, years: e.target.value })
                      }
                      required
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                {/* NUMBER OF EMPLOYEES  */}
                <label className={`${classes.labels}`}>
                  Number of Employees
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.employee}</p>
                  ) : (
                    <select
                      name="employees"
                      value={formData.employees}
                      className={`${classes.inputs}`}
                      onChange={(e) =>
                        setFormData({ ...formData, employees: e.target.value })
                      }
                      required
                    >
                      <option value="">Number of Employees</option>
                      <option value="1-10">1-10</option>
                      <option value="11-50">11-50</option>
                      <option value="51-200">51-200</option>
                      <option value="201-500">201-500</option>
                      <option value="501-1000">501-1000</option>
                      <option value="1001+">1001+</option>
                    </select>
                  )}
                </div>
              </div>

              <div className={`${classes.formitem}`}>
                {/* ANNUAL REVENUE  */}
                <label className={`${classes.labels}`}>Annual Revenue</label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted ? (
                    <p>{formData.annualRevenue}</p>
                  ) : (
                    <select
                      name="annualRevenue"
                      value={formData.annualRevenue}
                      className={`${classes.inputs}`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          annualRevenue: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Annual Revenue</option>
                      <option value="0-100k">0-100k</option>
                      <option value="100k-500k">100k-500k</option>
                      <option value="500k-1M">500k-1M</option>
                      <option value="1M-5M">1M-5M</option>
                      <option value="5M-10M">5M-10M</option>
                      <option value="10M+">10M+</option>
                    </select>
                  )}
                </div>
              </div>
            </div>

            {/* SAVE BUSINESS BUTTON */}

            {!isSubmitted && (
              <button type="submit" className={`${classes.savepassbutton}`}>
                Save
              </button>
            )}
          </form>
        </div>
      </div>

      {/* Partner Information */}
      {submitted ? (
        <div className={classes.profilesettingscontainer}>
          <div className={classes.personalsection}>
            <h2>Partner 1</h2>

            <form>
              {partners.map((partner, index) => (
                <div key={index}>
                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="name">Name</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss.partnerName}</p>
                      </div>
                    </div>
                    {/* Other fields */}
                    <div className={classes.formitem}>
                      <label htmlFor="title">Title</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss.partnerTitle}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="city">City</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss.partnerCity}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="state">State</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss.partnerState}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="country">Country</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss.partnerCountry}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="phone">Phone</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss.partnerPhone}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="fax">Fax</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss.partnerFax}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="email">Email</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss.partnerEmail}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="address">Address</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss.partnerAddress}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="zipCode">Zip Code</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss.partnerZip}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </form>
          </div>
        </div>
      ) : (
        <p></p>
      )}

      {submitted1 ? (
        <div className={classes.profilesettingscontainer}>
          <div className={classes.personalsection}>
            <h2>Partner 2</h2>

            <form>
              {partners.map((partner, index) => (
                <div key={index}>
                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="name">Name</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss1.partnerName}</p>
                      </div>
                    </div>
                    {/* Other fields */}
                    <div className={classes.formitem}>
                      <label htmlFor="title">Title</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss1.partnerTitle}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="city">City</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss1.partnerCity}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="state">State</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss1.partnerState}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="country">Country</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss1.partnerCountry}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="phone">Phone</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss1.partnerPhone}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="fax">Fax</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss1.partnerFax}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="email">Email</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss1.partnerEmail}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="address">Address</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss1.partnerAddress}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="zipCode">Zip Code</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss1.partnerZip}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </form>
          </div>
        </div>
      ) : (
        <p></p>
      )}

      {submitted2 ? (
        <div className={classes.profilesettingscontainer}>
          <div className={classes.personalsection}>
            <h2>Partner 3</h2>

            <form>
              {partners.map((partner, index) => (
                <div key={index}>
                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="name">Name</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss2.partnerName}</p>
                      </div>
                    </div>
                    {/* Other fields */}
                    <div className={classes.formitem}>
                      <label htmlFor="title">Title</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss2.partnerTitle}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="city">City</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss2.partnerCity}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="state">State</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss2.partnerState}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="country">Country</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss2.partnerCountry}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="phone">Phone</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss2.partnerPhone}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="fax">Fax</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss2.partnerFax}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="email">Email</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss2.partnerEmail}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="address">Address</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss2.partnerAddress}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="zipCode">Zip Code</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss2.partnerZip}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </form>
          </div>
        </div>
      ) : (
        <p></p>
      )}

      {submitted3 ? (
        <div className={classes.profilesettingscontainer}>
          <div className={classes.personalsection}>
            <h2>Partner 4</h2>

            <form>
              {partners.map((partner, index) => (
                <div key={index}>
                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="name">Name</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss3.partnerName}</p>
                      </div>
                    </div>
                    {/* Other fields */}
                    <div className={classes.formitem}>
                      <label htmlFor="title">Title</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss3.partnerTitle}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="city">City</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss3.partnerCity}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="state">State</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss3.partnerState}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="country">Country</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss3.partnerCountry}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="phone">Phone</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss3.partnerPhone}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="fax">Fax</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss3.partnerFax}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="email">Email</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss3.partnerEmail}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="address">Address</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss3.partnerAddress}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="zipCode">Zip Code</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss3.partnerZip}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </form>
          </div>
        </div>
      ) : (
        <p></p>
      )}

      {submitted4 ? (
        <div className={classes.profilesettingscontainer}>
          <div className={classes.personalsection}>
            <h2>Partner 5</h2>
            <form>
              {partners.map((partner, index) => (
                <div key={index}>
                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="name">Name</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss4.partnerName}</p>
                      </div>
                    </div>
                    {/* Other fields */}
                    <div className={classes.formitem}>
                      <label htmlFor="title">Title</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss4.partnerTitle}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="city">City</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss4.partnerCity}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="state">State</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss4.partnerState}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="country">Country</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss4.partnerCountry}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="phone">Phone</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss4.partnerPhone}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="fax">Fax</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss4.partnerFax}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="email">Email</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss4.partnerEmail}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label htmlFor="address">Address</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss4.partnerAddress}</p>
                      </div>
                    </div>
                    <div className={classes.formitem}>
                      <label htmlFor="zipCode">Zip Code</label>
                      <div className={classes.inputwrapper}>
                        <p>{partnerss4.partnerZip}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </form>
          </div>
        </div>
      ) : (
        <p></p>
      )}

      {isLength ? (
        <div className={classes.profilesettingscontainer}>
          <div className={classes.personalsection}>
            <h2>Names of Owners, Partners, or Proprietors</h2>
            <p>(Save up to 5 Owners, Partners, or Proprietors Information)</p>
            <form onSubmit={handleSubmit}>
              {partners.map((partner, index) => (
                <div className={classes.maingroup} key={index}>
                  <h3>Partner {index + 1}</h3>
                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label>Name</label>
                      <input
                        type="text"
                        placeholder="Name"
                        required
                        value={partner.partnerName}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "partnerName",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className={classes.formitem}>
                      <label>Title</label>
                      <input
                        type="text"
                        placeholder="Title"
                        required
                        value={partner.partnerTitle}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "partnerTitle",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label>City</label>
                      <input
                        type="text"
                        placeholder="city"
                        required
                        value={partner.partnerCity}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "partnerCity",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className={classes.formitem}>
                      <label>State</label>
                      <input
                        type="text"
                        placeholder="State"
                        required
                        value={partner.partnerState}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "partnerState",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label>Country</label>
                      <input
                        type="text"
                        placeholder="Country"
                        required
                        value={partner.partnerCountry}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "partnerCountry",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className={classes.formitem}>
                      <label>Phone</label>
                      <input
                        type="text"
                        placeholder="Phone"
                        required
                        value={partner.partnerPhone}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "partnerPhone",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label>Fax</label>
                      <input
                        type="text"
                        placeholder="Fax"
                        required
                        value={partner.partnerFax}
                        onChange={(e) =>
                          handleInputChange(index, "partnerFax", e.target.value)
                        }
                      />
                    </div>
                    <div className={classes.formitem}>
                      <label>Email</label>
                      <input
                        type="email"
                        placeholder="email"
                        required
                        value={partner.partnerEmail}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "partnerEmail",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                      <label>Address</label>
                      <input
                        type="text"
                        placeholder="Name"
                        required
                        value={partner.partnerAddress}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "partnerAddress",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className={classes.formitem}>
                      <label>Zip</label>
                      <input
                        type="text"
                        placeholder="zip"
                        required
                        value={partner.partnerZip}
                        onChange={(e) =>
                          handleInputChange(index, "partnerZip", e.target.value)
                        }
                      />
                    </div>
                  </div>

                  {/* Other fields: City, State, Country, Phone, etc. */}
                  {/* ... Similar for other fields */}

                  {partners.length > 1 && (
                    <button
                      type="button"
                      onClick={() => handleRemovePartner(index)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              <div className={classes.mainform}>
                {partners.length < 5 && (
                  <button type="button" onClick={handleAddPartner}>
                    Add Partner
                  </button>
                )}
                <button type="submit">Save</button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default Business;
