import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classes from '../../styles/accountSetting/business.module.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../aUrl';




const Business = () => {
  const localhost = config.apiUrl;
  const params = useParams();
  const [bussinessData, setBussinessData] = useState([])
  const [partnerData, setPartnerData] = useState([]);

  const FetchBussinessData = async () => {
    try {
      const response = await axios.get(`${localhost}/getBusinessInformationForAdmin/${params.id}`);
      const result = response.data;
      setBussinessData(result);
      setBusinessDetails(result);
    } catch (err) {

    }

  }

  

  


  // example value for partners
  const initialPartners = [
    {
      name: 'Jane Doe',
      title: 'Partner',
      city: 'Partner City',
      state: 'Partner State',
      country: 'Partner Country',
      phone: '987-654-3210',
      fax: '987-654-3211',
      email: 'jane.doe@example.com',
      address: '456 Partner St.',
      zipCode: '54321',
    },
    {
      name: 'Mike Smith',
      title: 'Co-Partner',
      city: 'Co-Partner City',
      state: 'Co-Partner State',
      country: 'Co-Partner Country',
      phone: '654-321-0987',
      fax: '654-321-0988',
      email: 'mike.smith@example.com',
      address: '789 Co-Partner Ave.',
      zipCode: '67890',
    },
  ];

  const [partners, setPartners] = useState(initialPartners);

  const FetchPartnerData = async () => {
    try {
      const response = await axios.get(`${localhost}/admin/getPartnerData/${params.id}`);
      const result = response.data;
      setPartnerData(result);
      setPartners(result);

    } catch (error) {

    }
  }


// don't touch below this line _________________________________________________________________ 
  // State for business info edit mode and submitted status
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(true);


  // state for partners info edit mode and submitted status
  const [isEditMode2, setIsEditMode2] = useState(false);
  const [isSubmitted2, setIsSubmitted2] = useState(true);


  // const [businessDetails, setBusinessDetails] = useState(initialBusinessDetails);


  const handleInputChange = (field, value) => {
    setBusinessDetails({
      ...businessDetails,
      [field]: value,
    });
  };


  const handlePartnerInputChange = (index, field, value) => {
    const updatedPartners = [...partners];
    updatedPartners[index][field] = value;
    setPartners(updatedPartners);
  };



  const handleAddPartner = () => {
    if (partners.length < 5) {
      setPartners([
        ...partners,
        {
          partnerName: '',
          partnerTitle: '',
          partnerCity: '',
          partnerState: '',
          partnerCountry: '',
          partnerPhone: '',
          partnerFax: '',
          partnerEmail: '',
          partnerAddress: '',
          partnerZip: '',
        },
      ]);
    }
  };


  const handleRemovePartner = (index) => {
    if (partners.length > 1) {
      const updatedPartners = [...partners];
      updatedPartners.splice(index, 1);
      setPartners(updatedPartners);
    }
  };
// don't touch above this line _________________________________________________________________ 

  // edit mode toggle function business info
  const toggleEditMode = () => {
    if (isEditMode) {
      // call the database to get the initial value of the business details (CANCEL IS CLICKED)
      FetchBussinessData();
    }
    setIsEditMode(!isEditMode);
  };

  // edit mode toggle function  partners
  const toggleEditMode2 = () => {
    if (isEditMode2) {
      // call the database to get the initial value of the partners details (CANCEL IS CLICKED)
      FetchPartnerData();
    }
    setIsEditMode2(!isEditMode2);
  };

  const [businessDetails, setBusinessDetails] = useState({
    bussinessType: '',
    specificCategory: '',
    bussinessName: '',
    bussinessAddress: '',
    city: '',
    state: '',
    zip: '',
    contractPersonAndPosition: '',
    bussinessPhone: '',
    bussinessFax: '',
    bussinessEmail: '',
    modeOfContact: '',
    federalId: '',
    years: '',
    employee: '',
    annualRevenue: '',
  });  

  const handleSubmit = async (event) => {
    event.preventDefault();

    // SAVE LOGIC GOES HERE 

    const businessData = {
      bussinessType: businessDetails.bussinessType,
      specificCategory: businessDetails.specificCategory,
      bussinessName: businessDetails.bussinessName,
      bussinessAddress: businessDetails.bussinessAddress,
      city: businessDetails.city,
      state: businessDetails.state,
      zip: businessDetails.zip,
      contractPersonAndPosition: businessDetails.contractPersonAndPosition,
      bussinessPhone: businessDetails.bussinessPhone,
      bussinessFax: businessDetails.bussinessFax,
      bussinessEmail: businessDetails.bussinessEmail,
      modeOfContact: businessDetails.modeOfContact,
      federalId: businessDetails.federalId,
      years: businessDetails.years,
      employee: businessDetails.employee,
      annualRevenue: businessDetails.annualRevenue
    }

    if (!businessDetails.bussinessType) {
      toast.error("Please Select the Business Type");
      return;
    } else if (!businessDetails.specificCategory) {
      toast.error("Please enter the Specific Category");
      return;
    } else if (!businessDetails.bussinessName) {
      toast.error("Please enter the Business Name");
      return;
    } else if (!businessDetails.bussinessAddress) {
      toast.error("Please enter the Business Address");
      return;
    } else if (!businessDetails.city) {
      toast.error("Please enter the City");
      return;
    } else if (!businessDetails.state) {
      toast.error("Please enter the State");
      return;
    } else if (!businessDetails.zip) {
      toast.error("Please enter the ZIP code");
      return;
    } else if (!businessDetails.contractPersonAndPosition) {
      toast.error("Please enter the Contact Person and Position");
      return;
    } else if (!businessDetails.bussinessPhone) {
      toast.error("Please enter the Business Phone");
      return;
    } else if (!businessDetails.bussinessEmail) {
      toast.error("Please enter the Business Email");
      return;
    } else if (!businessDetails.modeOfContact) {
      toast.error("Please select the Mode of Contact");
      return;
    } else if (!businessDetails.federalId) {
      toast.error("Please enter the Federal ID");
      return;
    } else if (!businessDetails.years) {
      toast.error("Please enter the number of Years in Business");
      return;
    } else if (!businessDetails.employee) {
      toast.error("Please enter the number of Employees");
      return;
    } else if (!businessDetails.annualRevenue) {
      toast.error("Please enter the Annual Revenue");
      return;
    }
    
    

    try {
      const response = await fetch(`${localhost}/admin/save/businessInformation/${params.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(businessData),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        // window.location.reload();
        FetchBussinessData()
        toast.success("Data Stored Successfully")
      } else {
        console.error('Error submitting business details');
      }
    } catch (error) {
      console.error('Request failed:', error);
    }
    setIsSubmitted(true);
    setIsEditMode(false);
  };

  useEffect(()=> {
    FetchBussinessData();
    FetchPartnerData();
  },[]);

  // submit func for partners
  const handleSubmit2 = async (event) => {
    event.preventDefault();

    const partnerData = {
      // Assuming user email is stored in localStorage
      partners, // Send the partners array
    };

    try {
      const response = await axios.post(
        `${localhost}/admin/savePartner/${params.id}`,
        partnerData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Partner Information Stored Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      toast.error("Fill the form correctly");
    }

    setIsSubmitted2(true);
    setIsEditMode2(false);
  }

  return (
    <div>
      {/* ToastContainer for notifications */}
      <ToastContainer />
      <div className={`${classes.profilesettingscontainer}`}>
        <div className={`${classes.personalsection}`}>
          <div className={classes.otrhead}>
            <div className={classes.inotrhead}>
              <h2>Business Information</h2>
            </div>
            <div className={classes.inotrhead}>

              {/* save and edit button */}
              <button
                type='button'
                className={`${classes.saveimgbutton} ${classes.saveimgbuttonedt}`}
                onClick={toggleEditMode}
              >
                {isEditMode ? 'Cancel' : 'Edit'}
              </button>


              {isEditMode && (
                <button
                  type='submit'
                  className={`${classes.saveimgbutton}`}
                  onClick={handleSubmit}
                >
                  Save
                </button>
              )}
            </div>
          </div>

          <form>
            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                <label htmlFor="business-type" className={`${classes.labels}`}>
                  Business Type
                </label>
                <div className={`${classes.inputwrapper}`}>

                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.bussinessType || 'N/A'}</p>
                  ) : (
                    <select
                      name="business-type"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.bussinessType}
                      onChange={(e) => handleInputChange('bussinessType', e.target.value)}
                      disabled={!isEditMode}
                    >
                      <option value="">Business Type</option>
                      <option value="retail">Retail</option>
                      <option value="service">Service</option>
                      <option value="manufacturing">Manufacturing</option>
                      <option value="wholesale">Wholesale</option>
                    </select>
                  )}

                </div>
              </div>
              <div className={`${classes.formitem}`}>
                <label htmlFor="specific-category" className={`${classes.labels}`}>
                  Specific Category
                </label>
                <div className={`${classes.inputwrapper}`}>

                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.specificCategory || 'N/A'}</p>
                  ) : (
                    <select
                      name="specific-category"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.specificCategory}
                      onChange={(e) => handleInputChange('specificCategory', e.target.value)}
                      disabled={!isEditMode}
                    >
                      <option value="">Specific Category</option>
                      <option value="electronics">Electronics</option>
                      <option value="clothing">Clothing</option>
                      <option value="furniture">Furniture</option>
                      <option value="food">Food</option>
                    </select>

                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                <label htmlFor="business-name" className={`${classes.labels}`}>
                  Business Name
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.bussinessName || 'N/A'}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="Business Name"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.bussinessName}
                      onChange={(e) => handleInputChange('bussinessName', e.target.value)}
                      disabled={!isEditMode}
                    />
                  )}
                </div>
              </div>
              <div className={`${classes.formitem}`}>
                <label htmlFor="business-address" className={`${classes.labels}`}>
                  Business Address
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.bussinessAddress || 'N/A'}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="Business Address"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.bussinessAddress}
                      onChange={(e) => handleInputChange('bussinessAddress', e.target.value)}
                      disabled={!isEditMode}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                <label htmlFor="city" className={`${classes.labels}`}>
                  City
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.city || 'N/A'}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="City"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.city}
                      onChange={(e) => handleInputChange('city', e.target.value)}
                      disabled={!isEditMode}
                    />
                  )}
                </div>
              </div>
              <div className={`${classes.formitem}`}>
                <label htmlFor="state" className={`${classes.labels}`}>
                  State
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.state || 'N/A'}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="State"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.state}
                      onChange={(e) => handleInputChange('state', e.target.value)}
                      disabled={!isEditMode}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                <label htmlFor="zip" className={`${classes.labels}`}>
                  Zip
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.zip || 'N/A'}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="Zip"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.zip}
                      onChange={(e) => handleInputChange('zip', e.target.value)}
                      disabled={!isEditMode}
                    />
                  )}
                </div>
              </div>
              <div className={`${classes.formitem}`}>
                <label htmlFor="contact-person" className={`${classes.labels}`}>
                  Contact Person, Position
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.contractPersonAndPosition || 'N/A'}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="Contact Person, Position"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.contractPersonAndPosition}
                      onChange={(e) => handleInputChange('contractPersonAndPosition', e.target.value)}
                      disabled={!isEditMode}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                <label htmlFor="business-phone" className={`${classes.labels}`}>
                  Business Phone
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.bussinessPhone || 'N/A'}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="Business Phone"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.bussinessPhone}
                      onChange={(e) => handleInputChange('bussinessPhone', e.target.value)}
                      disabled={!isEditMode}
                    />
                  )}
                </div>
              </div>
              <div className={`${classes.formitem}`}>
                <label htmlFor="business-fax" className={`${classes.labels}`}>
                  Business Fax
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.bussinessFax || 'N/A'}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="Business Fax"
                      className={`${classes.inputs}`}
                      value={businessDetails.bussinessFax}
                      onChange={(e) => handleInputChange('bussinessFax', e.target.value)}
                      disabled={!isEditMode}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                <label htmlFor="business-email" className={`${classes.labels}`}>
                  Business Email
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.bussinessEmail || 'N/A'}</p>
                  ) : (
                    <input
                      type="email"
                      placeholder="Business Email"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.bussinessEmail}
                      onChange={(e) => handleInputChange('bussinessEmail', e.target.value)}
                      disabled={!isEditMode}
                    />
                  )}
                </div>
              </div>
              <div className={`${classes.formitem}`}>
                <label htmlFor="preferred-method" className={`${classes.labels}`}>
                  Preferred Method of Contact
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.modeOfContact || 'N/A'}</p>
                  ) : (
                    <select
                      name="preferred-method"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.modeOfContact}
                      onChange={(e) => handleInputChange('modeOfContact', e.target.value)}
                      disabled={!isEditMode}
                    >
                      <option value="">Preferred Method</option>
                      <option value="Email">Email</option>
                      <option value="Phone">Phone</option>
                    </select>
                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                <label htmlFor="federal-id" className={`${classes.labels}`}>
                  Federal ID
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.federalId || 'N/A'}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="Federal ID"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.federalId}
                      onChange={(e) => handleInputChange('federalId', e.target.value)}
                      disabled={!isEditMode}
                    />
                  )}
                </div>
              </div>
              <div className={`${classes.formitem}`}>
                <label htmlFor="years-in-business" className={`${classes.labels}`}>
                  Years in Business
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.years || 'N/A'}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="Years in Business"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.years}
                      onChange={(e) => handleInputChange('years', e.target.value)}
                      disabled={!isEditMode}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={`${classes.formgroup}`}>
              <div className={`${classes.formitem}`}>
                <label htmlFor="number-of-employees" className={`${classes.labels}`}>
                  Number of Employees
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.employee || 'N/A'}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="Number of Employees"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.employee}
                      onChange={(e) => handleInputChange('employee', e.target.value)}
                      disabled={!isEditMode}
                    />
                  )}
                </div>
              </div>
              <div className={`${classes.formitem}`}>
                <label htmlFor="annual-revenue" className={`${classes.labels}`}>
                  Annual Revenue
                </label>
                <div className={`${classes.inputwrapper}`}>
                  {isSubmitted && !isEditMode ? (
                    <p>{bussinessData.annualRevenue || 'N/A'}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="Annual Revenue"
                      className={`${classes.inputs}`}
                      required
                      value={businessDetails.annualRevenue}
                      onChange={(e) => handleInputChange('annualRevenue', e.target.value)}
                      disabled={!isEditMode}
                    />
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>


      <div className={classes.profilesettingscontainer}>
        <div className={classes.personalsection}>
          <div className={classes.otrhead}>
            <div className={classes.inotrhead}>
              <h2>Names of Owners, Partners, or Proprietors</h2>
            </div>
            <div className={classes.inotrhead}>


              {/* save and edit button */}
              <button
                type='button'
                className={`${classes.saveimgbutton} ${classes.saveimgbuttonedt}`}
                onClick={toggleEditMode2}
              >
                {isEditMode2 ? 'Cancel' : 'Edit'}
              </button>


              {isEditMode2 && (
                <button
                  type='submit'
                  className={`${classes.saveimgbutton}`}
                  onClick={handleSubmit2}
                >
                  Save
                </button>
              )}
            </div>
          </div>

          <form onSubmit={handleSubmit2}>
            {partners.map((partner, index) => (
              <div key={index} className={classes.partnerForm}   >
                <h2>Partner {index + 1}</h2>
                <div className={`${classes.formgroup}`}>
                  <div className={`${classes.formitem}`}>
                    <label htmlFor={`name-${index}`} className={`${classes.labels}`}>
                      Name
                    </label>
                    <div className={`${classes.inputwrapper}`}>
                      {isSubmitted2 && !isEditMode2 ? (
                        <p>{partner.partnerName}</p>
                      ) : (
                        <input
                          type="text"
                          placeholder="Name"
                          className={`${classes.inputs}`}
                          value={partner.partnerName}
                          onChange={(e) => handlePartnerInputChange(index, 'partnerName', e.target.value)}
                          disabled={!isEditMode2}
                          required
                        />
                      )}
                    </div>
                  </div>
                  <div className={`${classes.formitem}`}>
                    <label htmlFor={`title-${index}`} className={`${classes.labels}`}>
                      Title
                    </label>
                    <div className={`${classes.inputwrapper}`}>
                      {isSubmitted2 && !isEditMode2 ? (
                        <p>{partner.partnerTitle}</p>
                      ) : (
                        <input
                          type="text"
                          placeholder="Title"
                          className={`${classes.inputs}`}
                          value={partner.partnerTitle}
                          onChange={(e) => handlePartnerInputChange(index, 'partnerTitle', e.target.value)}
                          disabled={!isEditMode2}
                          required
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className={`${classes.formgroup}`}>
                  <div className={`${classes.formitem}`}>
                    <label htmlFor={`city-${index}`} className={`${classes.labels}`}>
                      City
                    </label>
                    <div className={`${classes.inputwrapper}`}>
                      {isSubmitted2 && !isEditMode2 ? (
                        <p>{partner.partnerCity}</p>
                      ) : (
                        <input
                          type="text"
                          placeholder="City"
                          className={`${classes.inputs}`}
                          value={partner.partnerCity}
                          onChange={(e) => handlePartnerInputChange(index, 'partnerCity', e.target.value)}
                          disabled={!isEditMode2}
                          required
                        />
                      )}
                    </div>
                  </div>
                  <div className={`${classes.formitem}`}>
                    <label htmlFor={`state-${index}`} className={`${classes.labels}`}>
                      State
                    </label>
                    <div className={`${classes.inputwrapper}`}>
                      {isSubmitted && !isEditMode2 ? (
                        <p>{partner.partnerState}</p>
                      ) : (
                        <input
                          type="text"
                          placeholder="State"
                          className={`${classes.inputs}`}
                          value={partner.partnerState}
                          onChange={(e) => handlePartnerInputChange(index, 'partnerState', e.target.value)}
                          disabled={!isEditMode2}
                          required
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className={`${classes.formgroup}`}>
                  <div className={`${classes.formitem}`}>
                    <label htmlFor={`country-${index}`} className={`${classes.labels}`}>
                      Country
                    </label>
                    <div className={`${classes.inputwrapper}`}>
                      {isSubmitted2 && !isEditMode2 ? (
                        <p>{partner.partnerCountry}</p>
                      ) : (
                        <input
                          type="text"
                          placeholder="Country"
                          className={`${classes.inputs}`}
                          value={partner.partnerCountry}
                          onChange={(e) => handlePartnerInputChange(index, 'partnerCountry', e.target.value)}
                          disabled={!isEditMode2}
                          required
                        />
                      )}
                    </div>
                  </div>
                  <div className={`${classes.formitem}`}>
                    <label htmlFor={`phone-${index}`} className={`${classes.labels}`}>
                      Phone
                    </label>
                    <div className={`${classes.inputwrapper}`}>
                      {isSubmitted2 && !isEditMode2 ? (
                        <p>{partner.partnerPhone}</p>
                      ) : (
                        <input
                          type="text"
                          placeholder="Phone"
                          className={`${classes.inputs}`}
                          value={partner.partnerPhone}
                          onChange={(e) => handlePartnerInputChange(index, 'partnerPhone', e.target.value)}
                          disabled={!isEditMode2}
                          required
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className={`${classes.formgroup}`}>
                  <div className={`${classes.formitem}`}>
                    <label htmlFor={`fax-${index}`} className={`${classes.labels}`}>
                      Fax
                    </label>
                    <div className={`${classes.inputwrapper}`}>
                      {isSubmitted2 && !isEditMode2 ? (
                        <p>{partner.partnerFax}</p>
                      ) : (
                        <input
                          type="text"
                          placeholder="Fax"
                          className={`${classes.inputs}`}
                          value={partner.partnerFax}
                          onChange={(e) => handlePartnerInputChange(index, 'partnerFax', e.target.value)}
                          disabled={!isEditMode2}
                        />
                      )}
                    </div>
                  </div>
                  <div className={`${classes.formitem}`}>
                    <label htmlFor={`email-${index}`} className={`${classes.labels}`}>
                      Email
                    </label>
                    <div className={`${classes.inputwrapper}`}>
                      {isSubmitted2 && !isEditMode2 ? (
                        <p>{partner.partnerEmail}</p>
                      ) : (
                        <input
                          type="email"
                          placeholder="Email"
                          className={`${classes.inputs}`}
                          value={partner.partnerEmail}
                          onChange={(e) => handlePartnerInputChange(index, 'partnerEmail', e.target.value)}
                          disabled={!isEditMode2}
                          required
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className={`${classes.formgroup}`}>
                  <div className={`${classes.formitem}`}>
                    <label htmlFor={`address-${index}`} className={`${classes.labels}`}>
                      Address
                    </label>
                    <div className={`${classes.inputwrapper}`}>
                      {isSubmitted2 && !isEditMode2 ? (
                        <p>{partner.partnerAddress}</p>
                      ) : (
                        <input
                          type="text"
                          placeholder="Address"
                          className={`${classes.inputs}`}
                          value={partner.partnerAddress}
                          onChange={(e) => handlePartnerInputChange(index, 'partnerAddress', e.target.value)}
                          disabled={!isEditMode2}
                          required
                        />
                      )}
                    </div>
                  </div>


                  <div className={`${classes.formitem}`}>
                    <label htmlFor={`email-${index}`} className={`${classes.labels}`}>
                      Zip
                    </label>
                    <div className={`${classes.inputwrapper}`}>
                      {isSubmitted2 && !isEditMode2 ? (
                        <p>{partner.partnerZip}</p>
                      ) : (
                        <input
                          type="number"
                          placeholder="Zip"
                          className={`${classes.inputs}`}
                          value={partner.partnerZip}
                          onChange={(e) => handlePartnerInputChange(index, 'partnerZip', e.target.value)}
                          disabled={!isEditMode2}
                          required
                        />
                      )}
                    </div>
                  </div>
                </div>

                {index > 0 && isEditMode2 && (
                  <button
                    type="button"
                    className={classes.saveimgbutton}
                    onClick={() => handleRemovePartner(index)}
                    disabled={!isEditMode2}
                  >
                    Remove Partner
                  </button>
                )}
              </div>
            ))}


            {isEditMode2 && (

              <button
                type="button"
                className={classes.saveimgbutton}
                onClick={handleAddPartner}
                disabled={!isEditMode2}
              >
                Add Partner
              </button>

            )}

          </form>
        </div>
      </div>

    </div>
  );
};

export default Business;



































