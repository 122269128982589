import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import classes from "../styles/login.module.css";
import config from '../aUrl'
import axios from "axios";
// import bgimage from "";


function Login() {
    const [loginInfo, setLoginInfo] = useState({
        email: '',
        password: ''
    });
    const navigate = useNavigate();
    const localhost = config.apiUrl;

    useEffect(() => {

        const fetchProtectedData = async () => {
            const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage

            try {
                const response = await axios.get(`${localhost}/protected`, {
                    headers: {
                        Authorization: `Bearer ${token}`, // Include the token in the header
                    },
                });

                if (response.data === true) {
                    console.log("Access granted:", response.data); // The token was verified successfully
                    navigate('/client/dashboard');
                }

            } catch (error) {
                // navigate('/client/login');
            }
        };
        fetchProtectedData();
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginInfo(prevState => ({ ...prevState, [name]: value }));
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        const { email, password } = loginInfo;

        if (!email || !password) {
            toast.error("Email and password are required");
            return;
        }

        try {
            const url = `${localhost}/login`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginInfo)
            });


            const result = await response.json();
            const { success, message, jwtToken, names, emails, username, imageUrl } = result;

            if (success) {
                localStorage.setItem('username', username)
                localStorage.setItem('authtoken', jwtToken);
                localStorage.setItem('firstname', names);
                localStorage.setItem('emails', emails);
                localStorage.setItem('imageUrl', imageUrl);
                toast.success(message);
                setTimeout(() => {
                    navigate('/client/dashboard');
                }, 1000);
            } else {
                toast.error(message || "Login failed");
            }
        } catch (error) {
            toast.error("Internal Server Error, Please try again later");
        }
    };

    return (




        <div className={classes.bgimage}>
            <ToastContainer />


            <div className={`${classes.outercontainer}`}>




                <div className={`${classes.logincontainer}`}>


                    <div className={classes.intobxs} >


                        <div className={classes.otrcrd} >


                            <div className={`${classes.logincard}`}>

                                <h2 className={`${classes.loginheader}`}>Client Login</h2>

                                <form onSubmit={handleLogin}>
                                    <label htmlFor="email" className={`${classes.labels}`}>
                                        Email or Username<span className={`${classes.star}`}></span>
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        value={loginInfo.email}
                                        onChange={handleChange}
                                        // placeholder="Enter Username or Email"
                                        className={`${classes.inputs}`}
                                    // required
                                    />

                                    <div className={classes.lablepassfor}>
                                        <label htmlFor="password" className={`${classes.labels}`}>
                                            Password <span className={`${classes.star}`}></span>
                                        </label>

                                        <p>
                                            <Link to="/client/forgot" className={`${classes.forgotpasswordlink}`}>
                                                Lost your password?
                                            </Link>
                                        </p>
                                    </div>



                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        value={loginInfo.password}
                                        onChange={handleChange}
                                        // placeholder="Enter Password"
                                        className={`${classes.inputs}`}
                                    // required
                                    />
                                    <div className={`${classes.signupboxme}`}>
                                        <p><input type="checkbox" ></input>&nbsp;Stay signed in for a week</p>

                                    </div>
                                    <div className={`${classes.loginbtnbox}`}>
                                        <button type="submit" className={`${classes.loginbutton}`}>
                                            Login
                                        </button>
                                    </div>
                                </form>

                            </div>


                            <div className={classes.lowersgnup} >
                                <div className={`${classes.signupbox} ${classes.lstps}`}>
                                    <p>Don't have an account?</p>
                                    &nbsp;
                                    <p>
                                        <Link to="/client/signup" className={`${classes.signuplinklink}`}>
                                            Sign Up
                                        </Link>


                                    </p>
                                </div>
                            </div>
                        </div>









                        <div className={classes.btmtxts} >
                            <p>
                                <Link to="https://omegaconsulting.online/contact-us/" className={`${classes.btmlnk1}`}>
                                    Contact
                                </Link>


                            </p>

                            &middot;

                            <p>
                                <Link to="https://omegaconsulting.online/terms-of-use/" className={`${classes.btmlnk1}`}>
                                    Privacy & terms
                                </Link>


                            </p>

                        </div>




                    </div>





                </div>












            </div>

        </div>
    );
}

export default Login;
