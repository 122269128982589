import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from '../../styles/faq.module.css';

const S1 = () => {



   // data/objectivesFAQ.js
const FAQ = [
    {
      question: "How do I set and track objectives?",
      answer: "To set and track objectives, go to the 'Objectives' section. Here, you can define new objectives, set deadlines, and monitor your progress towards achieving them."
    },
    {
      question: "Can I edit existing objectives?",
      answer: "Yes, you can edit existing objectives by selecting them in the 'Objectives' section and updating the details."
    },
    {
      question: "How do I mark an objective as complete?",
      answer: "Mark objectives as complete by checking the completion box next to the objective in the 'Objectives' section."
    },
    {
      question: "How can I set deadlines for objectives?",
      answer: "Deadlines can be set when creating or editing an objective in the 'Objectives' section."
    }
  ];



    return (
        <div className={classes.priv}>
            <h1>Objectives FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
