import React from 'react';
import { Route, Routes, NavLink, Navigate } from 'react-router-dom';
import Profile from '../pages/AccountSetting/Profile';






import classes from '../styles/accountSetting/accountSetting.module.css';

const AccountSetting = () => {
  return (
    <div className={classes['account-setting']}>
      <nav className={classes.tabs}>
        <NavLink
          to="profile"
          className={({ isActive }) => (isActive ? `${classes.active} ${classes.tab}` : classes.tab)}
        >
          Profile
        </NavLink>
        
      </nav>
      <div className={classes.content}>
        <Routes>
          <Route path="profile" element={<Profile />} />
          <Route path="/" element={<Navigate to="profile" />} /> {/* Default route */}
        </Routes>
      </div>
    </div>
  );
};

export default AccountSetting;
