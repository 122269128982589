import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from '../../styles/faq.module.css';

const S1 = () => {



    // data/projectScopeFAQ.js
const FAQ = [
    {
      question: "What is included in the Project Scope section?",
      answer: "The Project Scope section includes detailed information about the project’s objectives, timelines, and deliverables. It helps you understand the project’s boundaries and expectations."
    },
    {
      question: "How can I view the objectives of the project?",
      answer: "Objectives are listed in the Project Scope section, where you can view and track each objective’s progress."
    },
    {
      question: "Can I update the project scope?",
      answer: "Updates to the project scope can be made by selecting 'Edit Scope' in the Project Scope section."
    },
    {
      question: "How do I view the timeline of the project?",
      answer: "The project timeline is displayed in the Project Scope section, showing key milestones and deadlines."
    }
  ];



    return (
        <div className={classes.priv}>
            <h1>Project Scope FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
