// import React from 'react';
// import { Route, Routes, Navigate } from 'react-router-dom';
// import Header from './Header';
// import Sidebar from './Sidebar';
// import Home from '../pages/Home';
// import Status from '../pages/Status';
// import Contracts from '../pages/Contracts';
// import BillsInvoices from '../pages/BillsInvoices';
// import Proactive from '../pages/Proactive';
// import Objectives from '../pages/Objectives';
// import PrivacyTerms from '../pages/PrivacyTerms';
// import Settings from '../pages/Settings';

// import '../styles/index.css';
// import '../styles/dashboard.css';

// const Dashboard = () => {
//   return (
//     <div className="layout">
//       <div className="sidebar-container">
//         <Sidebar />
//       </div>
//       <div className="main-content">
//         <Header />
//         <div className="content">
//           <Routes>
//             <Route path="/" element={<Navigate to="/home" />} /> {/* Redirect to Home */}
//             <Route path="home" element={<Home />} /> {/* Route for Home */}
//             <Route path="status" element={<Status />} />
//             <Route path="contracts" element={<Contracts />} />
//             <Route path="billsInvoices" element={<BillsInvoices />} />
//             <Route path="proactive" element={<Proactive />} />
//             <Route path="objectives" element={<Objectives />} />
//             <Route path="privacyTerms" element={<PrivacyTerms />} />
//             <Route path="settings/*" element={<Settings />} /> {/* Include Settings with nested routes */}
//           </Routes>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

// src/components/Dashboard.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Home from '../pages/Home';
import Status from '../pages/Status';
import Contracts from '../components/Contracts';
import BillsInvoices from '../pages/Bills';
import ProjectScope from '../pages/ProjectScope';
import Objectives from '../pages/Objectives';
import Proactive from '../pages/Proactive';
import PrivacyTerms from '../pages/PrivacyTerms';
import Settings from '../pages/Settings';
import Notifications from '../pages/Notifications';


import S1 from '../pages/Faq/S1';
import S2 from '../pages/Faq/S2';
import S3 from '../pages/Faq/S3';
import S4 from '../pages/Faq/S4';
import S5 from '../pages/Faq/S5';
import S6 from '../pages/Faq/S6';
import S7 from '../pages/Faq/S7';
import S8 from '../pages/Faq/S8';
import S9 from '../pages/Faq/S9';
import S10 from '../pages/Faq/S10';



import '../styles/index.css';
import classes from '../styles/dashboard.module.css';

const Dashboard = () => {
  return (
    <div className={classes.layout}>
      <div className={classes.sidebarcontainer}>
        <Sidebar />
      </div>
      <div className={classes.maincontent}>
        <Header />
        <div className={classes.content}>
          <Routes>
            <Route path="/" element={<Navigate to="home" />} /> {/* Redirect to Home */}
            <Route path="home" element={<Home />} /> {/* Route for Home */}
            <Route path="status" element={<Status />} />
            <Route path="contracts/*" element={<Contracts />} />
            <Route path="billsInvoices/*" element={<BillsInvoices />} />
            <Route path="projectScope" element={<ProjectScope />} />
            <Route path="objectives" element={<Objectives />} />
            <Route path="Proactive" element={<Proactive />} />
            <Route path="privacyTerms" element={<PrivacyTerms />} />
            <Route path="settings/*" element={<Settings />} /> {/* Include Settings with nested routes */}
            <Route path="notifications" element={<Notifications />} />
            
            


             {/* faq's  */}
             <Route path="profilefaq" element={<S1 />} />
            <Route path="paymentfaq" element={<S2 />} />
            <Route path="deliverablesfaq" element={<S3 />} />
            <Route path="contractsfaq" element={<S4 />} />
            <Route path="bills&invoicesfaq" element={<S5 />} />
            <Route path="projectscopefaq" element={<S6 />} />
            <Route path="objectivesfaq" element={<S7 />} />
            <Route path="proactivefaq" element={<S8 />} />
            <Route path="privacyfaq" element={<S9 />} />
            <Route path="logoutfaq" element={<S10 />} />
            

            
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
