import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from '../../styles/faq.module.css';
import axios from 'axios';
import configs from '../AccountSetting/url'



const S1 = () => {
    const navigate = useNavigate();
    const localhost = configs.apiUrl


    useEffect(() => {
        // setLoggedName(localStorage.getItem("firstname"));
        const fetchProtectedData = async () => {
          const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage
    
          try {
            const response = await axios.get(`${localhost}/protected`, {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the header
              },
            });
    
            if (response.data === true) {
              console.log("Access granted:", response.data); // The token was verified successfully
              // Proceed with your page logic here
            }
    
          } catch (error) {
            navigate('/client/login');
          }
        };
        fetchProtectedData();
      });

    const FAQ = [
        {
          question: "How do I log out of the dashboard?",
          answer: "To log out of the dashboard, click on the 'Logout' button usually found in the top-right corner of the interface. This will securely log you out of your account."
        }
      ];



    return (
        <div className={classes.priv}>
            <h1>Logout FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
