import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from '../../styles/faq.module.css';
import axios from 'axios';
import configs from '../AccountSetting/url'


const S1 = () => {
  const navigate = useNavigate();
  const localhost = configs.apiUrl


    useEffect(() => {
        // setLoggedName(localStorage.getItem("firstname"));
        const fetchProtectedData = async () => {
          const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage
    
          try {
            const response = await axios.get(`${localhost}/protected`, {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the header
              },
            });
    
            if (response.data === true) {
              console.log("Access granted:", response.data); // The token was verified successfully
              // Proceed with your page logic here
            }
    
          } catch (error) {
            navigate('/client/login');
          }
        };
        fetchProtectedData();
      });



    const FAQ = [
        {
          question: "How do I view my contracts?",
          answer: "View your contracts by going to the 'Contracts' section. Here, you can see all your active and past contracts, including details and documents associated with each."
        },
        {
          question: "Can I download contract documents?",
          answer: "Yes, you can download contract documents from the 'Contracts' section by clicking on the download icon next to the contract."
        },
        {
          question: "How do I search for a specific contract?",
          answer: "Use the search bar in the 'Contracts' section to find specific contracts by entering keywords or contract numbers."
        },
        {
          question: "How do I request changes to a contract?",
          answer: "To request changes, open the contract in the 'Contracts' section and use the 'Request Changes' button to submit your modification requests."
        }
      ];



    return (
        <div className={classes.priv}>
            <h1>Contracts FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
