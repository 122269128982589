import React from 'react';
import classes from '../styles/notifications.module.css';

function Notifications() {


    const notifications = [
        { id: 1, heading: 'Notification 1', description: 'This is the description for the first notification.' },
        { id: 2, heading: 'Notification 2', description: 'Here is a short description for the second notification.' },
        { id: 3, heading: 'Notification 3', description: 'Details about the third notification go here.' },
        { id: 4, heading: 'Notification 4', description: 'Description for the fourth notification.' },
        { id: 5, heading: 'Notification 5', description: 'A brief description of the fifth notification.' },
        { id: 6, heading: 'Notification 6', description: 'Details for the sixth notification.' },
        { id: 7, heading: 'Notification 7', description: 'Short description of the seventh notification.' },
        { id: 8, heading: 'Notification 8', description: 'Eighth notification details.' },
        { id: 9, heading: 'Notification 9', description: 'A short description for notification nine.' },
        { id: 10, heading: 'Notification 10', description: 'Description of the tenth notification.' },
        { id: 11, heading: 'Notification 11', description: 'Description for the eleventh notification.' },
        { id: 12, heading: 'Notification 12', description: 'Brief description for the twelfth notification.' },
        { id: 13, heading: 'Notification 13', description: 'Details for notification thirteen.' },
        { id: 14, heading: 'Notification 14', description: 'Fourteenth notification description.' },
        { id: 15, heading: 'Notification 15', description: 'Short description of the fifteenth notification.' },
        { id: 16, heading: 'Notification 16', description: 'Description for the sixteenth notification.' },
        { id: 17, heading: 'Notification 17', description: 'Details for the seventeenth notification.' },
        { id: 18, heading: 'Notification 18', description: 'Description of the eighteenth notification.' },
        { id: 19, heading: 'Notification 19', description: 'Short description for the nineteenth notification.' },
        { id: 20, heading: 'Notification 20', description: 'Final notification description.' }
      ];









    return (
        <div>
            <h1>Notifications</h1>
            <br></br>

            <div className={classes.notibox} >

                <div className={classes.notiin} >



                {notifications.map(notification => (
        <div key={notification.id} className={classes.notifs}>
          <h3 className={classes.heading}>{notification.heading}:&nbsp;</h3>
          <p className={classes.description}>{notification.description}</p>
        </div>
      ))}

                </div>

            </div>
        </div>
    );
}

export default Notifications;