import React, { useState, useEffect } from 'react';
import axios from 'axios';
import classes from '../../styles/accountSetting/profile.module.css';
import profileImage from '../../assets/profileimg.png';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import config from '../../aUrl';

const Profile = () => {

    const [users, setUsers] = useState({});
    const params = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [CPassword, setCPassword] = useState('');
    const localhost = config.apiUrl;

    const getUserData = async () => {
        try {
            const response = await axios.get(`${localhost}/getDataForHeader/${params.id}`);
            const result = response.data.header;

            if (response.status === 200) {
                setUsers(result);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        getUserData();
    }, [params.id]); // Added params.id as a dependency

    // Password validation function
    const validatePassword = () => {
        if (newPassword.length < 8) {
            toast.error("Password must contain at least 8 characters");
            return false;
        } else if (newPassword !== CPassword) {
            toast.error("Passwords do not match!");
            return false;
        } else if (!/[a-z]/.test(newPassword)) {
            toast.error("Your password must contain a lowercase letter");
            return false;
        } else if (!/[A-Z]/.test(newPassword)) {
            toast.error("Your password must contain an uppercase letter");
            return false;
        } else if (!/[0-9]/.test(newPassword)) {
            toast.error("Your password must contain a digit");
            return false;
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
            toast.error("Your password must contain a special character");
            return false;
        }
        return true;
    };

    const handlePassword = async (e) => {
        e.preventDefault();

        if (!validatePassword()) return;

        try {
            const response = await axios.put(`${localhost}/updatePasswordByAdmin/${params.id}`, {
                CPassword,
                newPassword,
            });

            if (response.status === 200) {
                toast.success("Password updated successfully");
            }
        } catch (err) {
            const errorMessage = err.response?.data?.message || "Failed to update password";
            toast.error(errorMessage);
        }
    };

    return (
        <div className={classes.profilesettingscontainer}>
            <ToastContainer />

            <div className={classes.personalsection}>
                <div className={classes.otrhead}>
                    <div className={classes.inotrhead}>
                        <h2>Client Profile</h2>
                    </div>
                </div>

                <div className={classes.profilepicture}>
                    <img alt="Profile" src={users.imageUrl || profileImage} />
                </div>

                {/* USER DETAILS */}
                <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                        {/* NAME */}
                        <label htmlFor="name" className={classes.labels}>Name</label>
                        <div className={classes.inputwrapper}>
                            <input type="text" id="name" value={users.name || ''} disabled className={classes.inputs} />
                        </div>
                    </div>

                    <div className={classes.formitem}>
                        {/* USERNAME */}
                        <label htmlFor="user-name" className={classes.labels}>Username</label>
                        <div className={classes.inputwrapper}>
                            <input type="text" id="user-name" value={users.Username || ''} disabled className={classes.inputs} />
                        </div>
                    </div>
                </div>

                <div className={classes.formgroup}>
                    <div className={classes.formitem}>
                        {/* CLIENT ID */}
                        <label htmlFor="client-id" className={classes.labels}>Client Id</label>
                        <div className={classes.inputwrapper}>
                            <input type="text" id="client-id" value={users._id || ''} disabled className={classes.inputs} />
                        </div>
                    </div>

                    {/* EMAIL */}
                    <div className={classes.formitem}>
                        <label htmlFor="email" className={classes.labels}>Email</label>
                        <div className={classes.inputwrapper}>
                            <input type="email" id="email" value={users.email || ''} disabled className={classes.inputs} />
                        </div>
                    </div>
                </div>
            </div>
            <hr className={classes.line} />

            {/* PASSWORD UPDATE FORM */}
            <form onSubmit={handlePassword}>
                <div className={classes.privacysection}>
                    <h2>Privacy and Security</h2>
                    <br />

                    <div className={classes.formgroup2}>
                        <div className={classes.formitem}>
                            <label htmlFor="new-password" className={classes.labels}>New Password</label>
                            <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className={classes.inputpassword} id="new-password" />
                        </div>
                        <div className={classes.formitem}>
                            <label htmlFor="confirm-password" className={classes.labels}>Confirm password</label>
                            <input type="password" value={CPassword} onChange={(e) => setCPassword(e.target.value)} className={classes.inputpassword} id="confirm-password" />
                        </div>
                    </div>

                    <button type="submit" className={classes.savepassbutton}>
                        Save Password
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Profile;