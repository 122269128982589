import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/index.css';
 // Import the CSS file for styling
import classes from '../styles/sidebar.module.css'; // Import the CSS file for styling
import logo from '../assets/omegalogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faGear, faFileContract, faFileInvoiceDollar, faLightbulb, faBullseye, faShieldAlt, faCog, faSignOutAlt, faMessage } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
const Sidebar = () => {
  const navItems = [
    { to: "/client/dashboard/home", label: "Home", icon: faHouse },
    { to: "/client/dashboard/status", label: "Deliverables Status", icon: faGear },
    { to: "/client/dashboard/contracts", label: "Contracts", icon: faFileContract },
    { to: "/client/dashboard/billsInvoices", label: "Bills & Invoices", icon: faFileInvoiceDollar },
    { to: "/client/dashboard/projectScope", label: "Project Scope", icon: faLightbulb },
    { to: "/client/dashboard/objectives", label: "Objectives", icon: faBullseye },
    { to: "/client/dashboard/proactive", label: "Proactive", icon: faMessage },
    { to: "/client/dashboard/privacyTerms", label: "Privacy & Terms", icon: faShieldAlt },
    { to: "/client/dashboard/settings", label: "Settings", icon: faCog },
  ];

  const navigate = useNavigate()
  const handleLogout = (e) => {
    localStorage.removeItem('authtoken')
    localStorage.removeItem('username')
    localStorage.removeItem('imageUrl')
    localStorage.removeItem('emails')
    localStorage.removeItem('firstname')
    setTimeout(()=>{
      navigate('/client/login')
    },1000)
  }

  return (
    <aside className={classes.sidebar}>
      <div className={classes.logo}>
        <img src={logo} alt="Company Logo" />
      </div>
      <ul>
        {navItems.map(item => (
          <NavLink
          key={item.to}
          to={item.to}
          className={({ isActive }) => isActive ? classes.active : ''}
        >
          {({ isActive }) => (
            <li className={isActive ? classes['active-link'] : ''}>
              <div className={`${classes.bluemark} ${isActive ? classes.active : ''}`}></div>
              <div className={`${classes['link-content']} ${isActive ? classes.active : ''}`}>
                <span><FontAwesomeIcon icon={item.icon} />&nbsp;</span>{item.label}
              </div>
            </li>
          )}
        </NavLink>
        ))}
      </ul>
      <div className={classes.logbox}>
        <button onClick={ handleLogout }><FontAwesomeIcon icon={faSignOutAlt} /> Logout</button>
      </div>
    </aside>
  );
};

export default Sidebar;
