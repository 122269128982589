import React, { useState } from 'react';
import classes from '../../styles/projectScope/projectTimeline.module.css';
import EastIcon from '@mui/icons-material/East';
import DoneIcon from '@mui/icons-material/Done';

const ProjectTimeline = () => {




// for initial data just show nothing in the sprint head and description and the dates as well
// There will be only fixed 5 sprints no more than that



// example data

  // Initial data for sprints
  const initialSprints = [
    { head: 'Sprint 1', description: 'Moonlight filters through the ancient forest' },
    { head: 'Sprint 2', description: 'Gentle waves kiss the shore under twilight' },
    { head: 'Sprint 3', description: 'Autumn leaves drift on a crisp, cool breeze' },
    { head: 'Sprint 4', description: 'The city lights shimmer in the evening haze' },
    { head: 'Sprint 5', description: 'A soft lullaby whispers through the dark night' },
  ];

  // Project timeline dates
  const projectTimelineDates = {
    startDate: '01/22/2023',
    endDate: '10/18/2023',
  };


 






  // Don't touch the code below this line ------------------------------------
  // State to manage sprints, release dates, and edit mode
  const [sprints, setSprints] = useState(initialSprints);
  const [dates, setDates] = useState(projectTimelineDates);
  const [isEditMode, setIsEditMode] = useState(false);

  // Handle Edit button
  const handleEditClick = () => {
    setIsEditMode(true); // Enter edit mode
  };

  // Handle input changes
  const handleInputChange = (index, field, value) => {
    const newSprints = [...sprints];
    newSprints[index][field] = value;
    setSprints(newSprints);
  };

  // Convert 'mm/dd/yyyy' to 'yyyy-mm-dd'
  const formatToISODate = (date) => {
    const [month, day, year] = date.split('/');
    return `${year}-${month}-${day}`;
  };

  // Convert 'yyyy-mm-dd' to 'mm/dd/yyyy'
  const formatToUSDate = (date) => {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  };

  // Handle input change for dates
  const handleDateChange = (field, value) => {
    setDates(prevDates => ({
      ...prevDates,
      [field]: value
    }));
  };
// don't touch the code above this line ------------------------------------






  





  // Handle Cancel button
  const handleCancelClick = () => {
    // Restore the original data
    // call the database api to restore the original data and then exit the edit mode
    setIsEditMode(false); // Exit edit mode
  };




  // Handle Save button
  const handleSaveClick = () => {
        // Check the conditions for only 10 characters in the sprint head and 40 characters in the sprint description at most!
        // also no empty fields are allowed in the sprint head, description, dates
        // if these conditions are met, then save the data to the database and exit the edit mode
    setIsEditMode(false); // Exit edit mode
  };










  return (
    <div className={classes.timelne}>
      <div className={classes.headbtns}>
        <h2>Project Timeline</h2>
        <div className={classes.inotrhead}>
          {!isEditMode ? (
            <button type="button" className={classes.editbtn} onClick={handleEditClick}>
              Edit
            </button>
          ) : (
            <>
              <button type="button" className={classes.cancelbtn} onClick={handleCancelClick}>
                Cancel
              </button>
              <button className={classes.savebtn} onClick={handleSaveClick}>
                Save
              </button>
            </>
          )}
        </div>
      </div>

      {!isEditMode ? (
        <div className={classes.timelneinner}>
          {/* Non-edit mode: Show timeline and graphs */}
          <div className={classes.timelineContainer}>
            <div className={classes.timelineItem}>
              <div className={classes.lineinmid}></div>

              <div className={classes.strt}>
                <p>Release&nbsp;Start<br /> <span className={classes.sprntdts} >{dates.startDate}</span></p>

                <div className={classes.start}> <EastIcon /> </div>
              </div>

              {sprints.map((sprint, index) => (
                <div key={index} className={`${index % 2 === 0 ? classes.odd : classes.even}`}>
                  {index % 2 !== 0 && (
                    <div className={classes.descriptionodd}>
                      <p2>{sprint.head}</p2>
                      <p>{sprint.description}</p>
                    </div>
                  )}
                  <div className={classes.circle}>
                    <p>0{index + 1}</p>
                  </div>
                  {index % 2 === 0 && (
                    <div className={classes.descriptioneven}>
                      <p2>{sprint.head}</p2>
                      <p>{sprint.description}</p>
                    </div>
                  )}
                </div>
              ))}

              <div className={classes.strtop}>
                <p>Release&nbsp;End<br /> <span className={classes.sprntdts} >{dates.endDate}</span></p>
                <div className={classes.stop}><DoneIcon /></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.editModeContainer}>
          {/* Edit mode: Show inputs for sprints in a table */}
          <div className={classes.editTable}>

            <div className={classes.heads} >
              <div className={classes.head1} >Sprint Number</div>
              <div className={classes.head2} >Sprint Name</div>
              <div className={classes.head3} >Description</div>
            </div>

            <div className={classes.sprintslsts} >
              {sprints.map((sprint, index) => (
                <div className={classes.sprintsingle} key={index}>
                  <div className={classes.sprntno} >0{index + 1}</div>
                  <div className={classes.sprnthead} >
                    <input
                      type="text"
                      value={sprint.head}
                      onChange={(e) => handleInputChange(index, 'head', e.target.value)}
                    />
                  </div>
                  <div className={classes.sprntdesc} >
                    <input
                      type="text"
                      value={sprint.description}
                      onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Inputs for release start and end dates */}
          <div className={classes.releaseDates}>
            <div>
              <label>Release Start: </label>
              <input
                type="date"
                value={formatToISODate(dates.startDate)}
                onChange={(e) => handleDateChange('startDate', formatToUSDate(e.target.value))}
              />
            </div>
            <div>
              <label>Release End: </label>
              <input
                type="date"
                value={formatToISODate(dates.endDate)}
                onChange={(e) => handleDateChange('endDate', formatToUSDate(e.target.value))}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectTimeline;
