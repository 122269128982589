import React, { useState, useEffect } from "react";
import classes from "../styles/status.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from "../aUrl";

const Status = () => {
  const params = useParams();
  const localhost = config.apiUrl;
  const [status, setStatus] = useState([]);

  const FetchData = async () => {
    try {
      const response = await axios.get(
        `${localhost}/admin/Deliverable/Status/${params.id}`
      );
      const result = response.data;
      if (response.status === 200 && result) {
        setProjectData(result);
      }
    } catch (error) {}
  };

  const FetchList = async () => {
    try {
      const response = await axios.get(
        `${localhost}/admin/Deliverable/list/${params.id}`
      );
      const result = response.data;
      if (response.status === 200 && result) {
        setData(result);
      }
    } catch (error) {}
  };

  useEffect(() => {
    FetchData();
    FetchList();
  }, []);

  // Initial project details state
  const projectDetails = {
    projectName: "ABC Project",
    projectId: "124545",
    projectManager: "Add name here",
    status: "Complete",
  };

  // Initial list of items (team members or tasks)
  const listsData = [
    {
      id: 1,
      name: "Abhishek Bharti",
      description: "Full Stack Development Intern",
      owner: "Add name here",
      status: "Complete",
    },
    {
      id: 2,
      name: "Sudhir",
      description: "Virtual Associate",
      owner: "Add name here",
      status: "In Progress",
    },
    {
      id: 3,
      name: "Sahil",
      description: "Backend Development Intern",
      owner: "Add name here",
      status: "Not Started",
    },
    {
      id: 4,
      name: "Hulk",
      description: "Marvels",
      owner: "Add name here",
      status: "Not Started",
    },
    {
      id: 5,
      name: "Vikrant",
      description: "Ut leo nibh sed feugiat. Sit tristique netus id.",
      owner: "Add name here",
      status: "Not Started",
    },
    {
      id: 6,
      name: "Joseph",
      description: "Ut leo nibh sed feugiat. Sit tristique netus id.",
      owner: "Add name here",
      status: "In Progress",
    },
    {
      id: 7,
      name: "Justin",
      description: "Ut leo nibh sed feugiat. Sit tristique netus id.",
      owner: "Add name here",
      status: "Not Started",
    },
    {
      id: 8,
      name: "Charlie Putn",
      description: "Ut leo nibh sed feugiat. Sit tristique netus id.",
      owner: "Add name here",
      status: "Not Started",
    },
  ];

  // States for managing data and editing modes
  const [projectData, setProjectData] = useState({
    name: '',
    projectId: '',
    projectManager: '',
    status: '',
  })

  // const [data, setData] = useState({
  //   name : '',
  //   description : '',
  //   owner : '',
  //   status : ''
  // })
  const [data, setData] = useState([
    {
      name : '',
      description : '',
      owner : '',
      status : ''
    }
  ])

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingList, setIsEditingList] = useState(false);

  // Function to toggle edit mode for project details
  const toggleEditMode = () => {
    if (isEditing) {
      // Cancel edit, fetch the latest project data from the database
      // add a function that fetches the latest project data from the database
    }
    // Toggle between edit and view modes
    setIsEditing(!isEditing);
  };

  // Function to toggle edit mode for list items
  const toggleListEditMode = () => {
    if (isEditingList) {
      // Cancel edit, fetch list data from the database
      // add a function that fetches the latest list data from the database
    }
    // Toggle between edit and view modes
    setIsEditingList(!isEditingList);
  };

  // Function to save project details (edit mode)
  const handleSaveClick = async () => {
    try {
      const response = await axios.post(
        `${localhost}/admin/Deliverable/Status/save/${params.id}`, projectData,
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      )

      if (response.status === 200) {
        toast.success("Information Stored Successfully");
        setIsEditing(false);
      }
    } catch (error) {}

    // Save project details (you can add your save logic here)
    // SAVE LOGIC GOES HERE
  };

  // Function to save list data (edit mode)
  const handleSaveClickList = async () => {
    // before saving the data, validate no field should be empty
    try {
      const response = await axios.post(`${localhost}/admin/Deliverable/list/save/${params.id}`, sortedData , 
        {
          headers : {
            'Content-Type' : 'application/json'
          }
        }
      )

      if(response.status === 200)
      {
        toast.success("Information Stored Successfully");
        setIsEditingList(false);
      }

    } catch (error) {

    }
    // Save list data (you can add your save logic here)
    // SAVE LOGIC GOES HERE
  };

  // don't change the code below this line ___________________________________________________________________
  // Function to handle input changes in project details
  const handleProjectChange = (e, field) => {
    setProjectData({
      ...projectData,
      [field]: e.target.value,
    });
  };

  // Function to handle changes in list items
  const handleListChange = (id, field, value) => {
    const updatedData = data.map((item) =>
      item.id === id ? { ...item, [field]: value } : item
    );
    setData(updatedData);
  };

  // Function to add a new item to the list
  const handleAddClick = () => {
    const newItem = {
      id: data.length + 1,
      name: "",
      description: "",
      owner: "",
      status: "Not Started",
    };
    setData([...data, newItem]);
  };

  // Function to delete a list item by id
  const handleDeleteClick = (id) => {
    const updatedData = data.filter((item) => item.id !== id);

    // Adjust the ids dynamically after deleting an item
    const adjustedData = updatedData.map((item, index) => ({
      ...item,
      id: index + 1,
    }));

    setData(adjustedData);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Complete":
        return "#C0FFCA"; // Light green
      case "In Progress":
        return "#FFF6A8"; // Light yellow
      case "Not Started":
        return "#FFC7A8"; // Light red
      default:
        return "#FFFFFF"; // Default color if none of the cases match
    }
  };

  // Sort data in descending order by id
  const sortedData = [...data].sort((a, b) => b.id - a.id);
  // don't change the code above this line ___________________________________________________________________

  return (
    <div>
      {/* ToastContainer for notifications */}
      <ToastContainer />

      <div className={classes.btnandhed}>
        <h1>Deliverables Status</h1>

        <div className={classes.sveedtbtn}>
          {/* Edit button toggles to cancel when in edit mode */}
          <button
            type="button"
            className={`${classes.editbtnhed}`}
            onClick={toggleEditMode}
          >
            {isEditing ? "Cancel" : "Edit"}
          </button>

          {/* Save BUTTON */}
          {isEditing && (
            <button
              type="button"
              className={classes.saveimgbutton}
              onClick={handleSaveClick}
              disabled={!isEditing}
            >
              Save
            </button>
          )}
        </div>
      </div>
      <br />

      <div className={classes.outerbox}>
        <div className={classes.box1}>
          <div className={classes.inbox}>
            <div className={classes.txt}>
              <p2>Project Name</p2>
            </div>
            <div className={classes.txt2}>
              {isEditing ? (
                <input
                  type="text"
                  value={projectData.name}
                  onChange={(e) => handleProjectChange(e, "name")}
                  className={classes.p}
                />
              ) : (
                <p className={classes.p}>{projectData.name}</p>
              )}
            </div>
          </div>

          <div className={classes.inbox}>
            <div className={classes.txt}>
              <p2>Project Id</p2>
            </div>
            <div className={classes.txt2}>
              {isEditing ? (
                <input
                  type="text"
                  value={projectData.projectId}
                  onChange={(e) => handleProjectChange(e, "projectId")}
                  className={classes.p}
                />
              ) : (
                <p className={classes.p}>#{projectData.projectId}</p>
              )}
            </div>
          </div>

          <div className={classes.inbox}>
            <div className={classes.txt}>
              <p2>Project Manager</p2>
            </div>
            <div className={classes.txt2}>
              {isEditing ? (
                <input
                  type="text"
                  value={projectData.projectManager}
                  onChange={(e) => handleProjectChange(e, "projectManager")}
                  className={classes.p}
                />
              ) : (
                <p className={classes.p}>{projectData.projectManager}</p>
              )}
            </div>
          </div>

          <div className={classes.inbox}>
            <div className={classes.txt}>
              <p2>Status</p2>
            </div>
            <div className={classes.txt2}>
              <div
                className={classes.clrbox}
                style={{ backgroundColor: getStatusColor(projectData.status) }}
              >
                {isEditing ? (
                  <select
                    value={projectData.status}
                    onChange={(e) => handleProjectChange(e, "status")}
                    className={classes.p}
                  >
                    <option value="Complete">Complete</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Not Started">Not Started</option>
                  </select>
                ) : (
                  <p className={classes.p}>{projectData.status}</p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* status lists */}

        <div className={classes.box2}>
          <div className={classes.addbtnlst}>
            <div className={classes.sveedtbtn2}>
              {/* Edit button toggles to cancel when in edit mode */}
              <button
                type="button"
                className={`${classes.editbtnhed}`}
                onClick={toggleListEditMode}
              >
                {isEditingList ? "Cancel" : "Edit"}
              </button>

              {/* Save BUTTON */}
              {isEditingList && (
                <button
                  type="button"
                  className={classes.saveimgbutton}
                  onClick={handleSaveClickList}
                  disabled={!isEditingList}
                >
                  Save
                </button>
              )}
            </div>

            {isEditingList && (
              <button
                type="button"
                className={classes.saveimgbutton}
                onClick={handleAddClick}
                disabled={!isEditingList}
              >
                Add
              </button>
            )}
          </div>

          <div className={classes.table}>
            <div className={classes.labeloflist}>
              <div className={classes.lsth1}>Sr. No.</div>
              <div className={classes.lsth2}>Deliverable Name</div>
              <div className={classes.lsth3}>Description</div>
              <div className={classes.lsth4}>Owner</div>
              <div className={classes.lsth5}>Status</div>
            </div>

            <div className={classes.listsbox}>
              {sortedData.map((item, index) => (
                <div
                  key={index}
                  className={classes.singlelist}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#ffffff" : "#f5f5f5",
                  }}
                >
                  <div className={classes.itmh1}>{index + 1}</div>
                  <div className={classes.itmh2}>
                    {isEditingList ? (
                      <input
                        type="text"
                        value={item.name}
                        min={1}
                        max={15}
                        onChange={(e) =>
                          handleListChange(item.id, "name", e.target.value)
                        }
                      />
                    ) : (
                      item.name
                    )}
                  </div>
                  <div className={classes.itmh3}>
                    {isEditingList ? (
                      <input
                        type="text"
                        value={item.description}
                        onChange={(e) =>
                          handleListChange(
                            item.id,
                            "description",
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      item.description
                    )}
                  </div>
                  <div className={classes.itmh4}>
                    {isEditingList ? (
                      <input
                        type="text"
                        value={item.owner}
                        onChange={(e) =>
                          handleListChange(item.id, "owner", e.target.value)
                        }
                      />
                    ) : (
                      item.owner
                    )}
                  </div>
                  <div className={classes.itmh51}>
                    <div
                      className={classes.itmh5}
                      style={{ backgroundColor: getStatusColor(item.status) }}
                    >
                      {isEditingList ? (
                        <select
                          value={item.status}
                          onChange={(e) =>
                            handleListChange(item.id, "status", e.target.value)
                          }
                        >
                          <option value="Complete">Complete</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Not Started">Not Started</option>
                        </select>
                      ) : (
                        item.status
                      )}
                    </div>
                    {isEditingList && (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        className={classes.deleteButtonlst}
                        onClick={() => handleDeleteClick(item.id)}
                      >
                        <DeleteIcon />
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={classes.legndoutr}>
            <div>
              <h2>LEGENDS</h2>
              <br />
              <div className={classes.lgndin}>
                <div className={classes.clrleg1}></div>
                &nbsp;<p>Complete</p>
              </div>
              <div className={classes.lgndin}>
                <div className={classes.clrleg2}></div>
                &nbsp;<p>In Progress</p>
              </div>
              <div className={classes.lgndin}>
                <div className={classes.clrleg3}></div>
                &nbsp;<p>Not Started</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Status;
