import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./client/pages/Login";
import Signup from "./client/pages/Register";
import Authentication from "./client/pages/OtpPage";
import Forgot from "./client/pages/Forgot";
import Reset from "./client/pages/Reset";
import Dashboard from "./client/components/Dashboard";
import Authenticatio from "./client/pages/verifyotp";
import Setpassword from "./client/pages/setpassword";
// import EmployeeDashboard from './employee/components/Dashboard';
import AdminDashboard from './admin/components/Dashboard';
import EditClient from './admin/EditClient/components/Dashboard';
// import EditEmployee from './admin/components/EditEmployee';


const App = () => {
  return (

      <Router>

<div className="App">
          <Routes>
            <Route path="/client/login" element={<Login />} />
            <Route path="/client/signup" element={<Signup />} />
            <Route path="/client/authentication" element={<Authentication />} />
            <Route path="/client/forgot" element={<Forgot />} />
            <Route path="/client/reset" element={<Reset />} />
            <Route path="/client/setpassword" element={<Setpassword />} />
            <Route path="/client/verifyotp" element={<Authenticatio />} />
            <Route path="/client/dashboard/*" element= { <Dashboard/> } />
          


          



          
          {/* employee  */}

          {/* <Route path="/employee/dashboard/*" element={<EmployeeDashboard />} /> */}

          {/* admin  */}
          <Route path="/admin/dashboard/*" element={<AdminDashboard />} />


          
          {/* adminClient  */}

          <Route path="/admin/dashboard/clientlist/:id/edit/*" element={<EditClient />} />

          
          {/* adminEmployee  */}

          {/* <Route path="/admin/dashboard/employeelist/edit/*" element={<EditEmployee />} /> */}

          </Routes>
        </div>
      </Router>

  )
};

export default App;
