import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from '../../styles/faq.module.css';

const S1 = () => {



    const FAQ = [
        {
          question: "How do I check the status of my deliverables?",
          answer: "To check the status of your deliverables, navigate to the 'Deliverables' section. This area shows the current status, deadlines, and progress of all your deliverables."
        },
        {
          question: "Can I view detailed reports for my deliverables?",
          answer: "Yes, detailed reports are available in the 'Deliverables' section. Click on any deliverable to view its detailed report."
        },
        {
          question: "How do I update the status of a deliverable?",
          answer: "To update the status, go to the 'Deliverables' section, select the deliverable you want to update, and choose the new status from the dropdown menu."
        },
        {
          question: "How can I set deadlines for new deliverables?",
          answer: "Deadlines for new deliverables can be set when you create or edit a deliverable in the 'Deliverables' section."
        }
      ];



    return (
        <div className={classes.priv}>
            <h1>Deliverables FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
