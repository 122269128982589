import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from '../../styles/faq.module.css';

const S1 = () => {



    const FAQ = [
        {
          question: "How do I view my contracts?",
          answer: "View your contracts by going to the 'Contracts' section. Here, you can see all your active and past contracts, including details and documents associated with each."
        },
        {
          question: "Can I download contract documents?",
          answer: "Yes, you can download contract documents from the 'Contracts' section by clicking on the download icon next to the contract."
        },
        {
          question: "How do I search for a specific contract?",
          answer: "Use the search bar in the 'Contracts' section to find specific contracts by entering keywords or contract numbers."
        },
        {
          question: "How do I request changes to a contract?",
          answer: "To request changes, open the contract in the 'Contracts' section and use the 'Request Changes' button to submit your modification requests."
        }
      ];



    return (
        <div className={classes.priv}>
            <h1>Contracts FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
