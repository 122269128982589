import React from 'react';
import classes from '../../styles/contracts.module.css';
import { useDropzone } from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import config from '../../aUrl';

const FileUploadBusiness = () => {
    const localhost = config.apiUrl;
    const maxSize = 2 * 1024 * 1024; // 1MB limit

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: async (acceptedFiles) => {
            let validFiles = true;

            acceptedFiles.forEach(file => {
                if (file.size > maxSize) {
                    toast.error(`${file.name} exceeds the 2MB limit.`);
                    validFiles = false;
                }
            });

            if (!validFiles) return; // Stop the upload process if any file is invalid

            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('pdfs', file);
            });

            // Assuming you need to send userId as well
            const userId = localStorage.getItem("emails"); // replace with actual userId
            formData.append('userId', userId);

            try {
                toast("Uploading...");
                const response = await axios.post(`${localhost}/uploadBusinesspdf`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                toast.success("PDF uploaded successfully");
                console.log('Upload successful:', response.data);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } catch (error) {
                console.error('Error uploading files:', error);
                toast.error("Error uploading PDF");
            }
        },
        accept: 'application/pdf', // Accept PDF files
    });

    return (
        <div
            {...getRootProps()}
            style={{
                border: '2px dashed #cccccc',
                borderRadius: '4px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                height: '100%',
                backgroundColor: isDragActive ? '#e0f7fa' : '#ffffff', // Change background on drag
            }}
        >
            <ToastContainer />
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop the files here ...</p>
            ) : (
                <div className={classes.photoupload}>
                    <div className={classes.uploadthefile}>
                        <div className={classes.iconupload}>
                            <UploadFileIcon sx={{ fontSize: 45 }} />
                        </div>
                        <div className={classes.txtout2}>
                            <p className={classes.pdftxt2}>Select your PDF to upload</p>
                            <br />
                            <p className={classes.pdftxt3}>or drag and drop it here</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileUploadBusiness;
