import React from 'react';
import { Route, Routes, NavLink, Navigate } from 'react-router-dom';
import Profile from '../pages/AccountSetting/Profile';
import Business from '../pages/AccountSetting/Business';

// PAYMENT HERE UPDATE  
import Payment from '../pages/AccountSetting/Payment';




import classes from '../styles/accountSetting/accountSetting.module.css';

const AccountSetting = () => {
  return (
    <div className={classes['account-setting']}>
      <nav className={classes.tabs}>
        <NavLink
          to="profile"
          className={({ isActive }) => (isActive ? `${classes.active} ${classes.tab}` : classes.tab)}
        >
          Profile
        </NavLink>
        <NavLink
          to="business"
          className={({ isActive }) => (isActive ? `${classes.active} ${classes.tab}` : classes.tab)}
        >
          Business
        </NavLink>

        {/* UPDATED THE PAYMENT HERE */}
        <NavLink
          to="payment"
          className={({ isActive }) => (isActive ? `${classes.active} ${classes.tab}` : classes.tab)}
        >
          Payment
        </NavLink>
      </nav>
      <div className={classes.content}>
        <Routes>
          <Route path="profile" element={<Profile />} />
          <Route path="business" element={<Business />} />

          {/* UPDATED THE PAYMENT HERE */}
          <Route path="payment" element={<Payment />} />

          <Route path="/" element={<Navigate to="profile" />} /> {/* Default route */}
        </Routes>
      </div>
    </div>
  );
};

export default AccountSetting;
