import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/index.css';
 // Import the CSS file for styling
import classes from '../styles/sidebar.module.css'; // Import the CSS file for styling
import logo from '../assets/omegalogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faCog, faSignOutAlt, faChartLine, faUserShield, faMessage, faUser, faUserAltSlash, faIdBadge, faPeopleGroup, faUserGroup, faIdCard } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
  const navItems = [
    { to: "/admin/dashboard/home", label: "Home", icon: faHouse },
    { to: "/admin/dashboard/analytics", label: "Analytics", icon: faChartLine },
    { to: "/admin/dashboard/messages", label: "Messages", icon: faMessage },
    { to: "/admin/dashboard/employeelist", label: "Employee", icon: faIdCard },
    { to: "/admin/dashboard/clientlist", label: "Client", icon: faUserGroup },
    { to: "/admin/dashboard/permissions", label: "Permissions", icon: faUserShield },
    { to: "/admin/dashboard/settings", label: "Settings", icon: faCog },
  ];

  return (
    <aside className={classes.sidebar}>
      <div className={classes.logo}>
        <img src={logo} alt="Company Logo" />
      </div>
      <ul>
        {navItems.map(item => (
          <NavLink
          key={item.to}
          to={item.to}
          className={({ isActive }) => isActive ? classes.active : ''}
        >
          {({ isActive }) => (
            <li className={isActive ? classes['active-link'] : ''}>
              <div className={`${classes.bluemark} ${isActive ? classes.active : ''}`}></div>
              <div className={`${classes['link-content']} ${isActive ? classes.active : ''}`}>
                <span><FontAwesomeIcon icon={item.icon} />&nbsp;</span>{item.label}
              </div>
            </li>
          )}
        </NavLink>
        ))}
      </ul>
      <div className={classes.logbox}>
        <button><FontAwesomeIcon icon={faSignOutAlt} /> Logout</button>
      </div>
    </aside>
  );
};

export default Sidebar;
