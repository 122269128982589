import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../styles/OtpPage.css';
import { useLocation, useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import config from '../aUrl'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const Otp = () => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [timer, setTimer] = useState(10);
    const timerRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const localhost = config.apiUrl
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (isResendDisabled && timer > 0) {
            timerRef.current = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            clearInterval(timerRef.current);
            setIsResendDisabled(false);
        }

        return () => clearInterval(timerRef.current);
    }, [isResendDisabled, timer]);

    const handleOtpChange = (e, index) => {
        const value = e.target.value;

        // Allow only numeric values
        if (!/^\d*$/.test(value)) return;

        const newOtp = [...otp];
        newOtp[index] = value.slice(0, 1);
        setOtp(newOtp);

        // Move focus to next input
        if (value && index < 5) {
            document.getElementById(`otp-${index + 1}`).focus();
        }

        // Handle backspace
        if (e.nativeEvent.inputType === 'deleteContentBackward' && index > 0 && value === '') {
            document.getElementById(`otp-${index - 1}`).focus();
        }
    };

    const handleResendOtp = async () => {
        setIsResendDisabled(true);
        setTimer(10);
        setIsLoading(true);

        try {
            const res = await axios.post(`${localhost}/resend-otp`, {
                email: location.state.email,
            });
            if (res.status === 200) {
                toast.success("OTP Resend Successfully");
            }
        } catch (error) {
            toast.error("Email not found, Signup again");
            setTimeout(() => {
                navigate('/client/signup')
            }, 2000);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('OTP Submitted:', otp.join(''));
        setIsLoading(true);
        try {
            const res = await axios.post(`${localhost}/verify-otp`, {
                email: location.state.email,
                otp: otp.join(''), // Convert array to string
            });
            if (res.status === 200) {
                toast.success("OTP Verify Successfully");
                toast.success("Reset Your Password Now")
                setTimeout(() => {
                    navigate("/client/setpassword",{state :{email : location.state.email}});
                }, 3000);
            } else {
                toast.error("OTP is Invalid");
            }
        } catch (error) {
            toast.error("OTP is Invalid");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="otp-container">
            <ToastContainer />
            <div className="card">
                <h1 className="heading">Enter OTP</h1>
                <p className="description">Please enter the 6-digit OTP sent to your email</p>
                <form onSubmit={handleSubmit} className="otp-form">
                    <div className="otp-inputs">
                        {otp.map((value, index) => (
                            <input
                                key={index}
                                id={`otp-${index}`}
                                type="text"
                                maxLength="1"
                                value={value}
                                onChange={(e) => handleOtpChange(e, index)}
                                className="otp-input"
                            />
                        ))}
                    </div>
                    <button type="submit" className="submit-button">Submit</button>
                    <div className="resend-container">
                        <span className="resend-text">Didn't receive OTP? </span>
                        <button
                            type="button"
                            onClick={handleResendOtp}
                            className="resend-button"
                            disabled={isResendDisabled}
                        >Resend OTP</button>
                    </div>
                    {isResendDisabled && timer > 0 && (
                        <p className="timer">Resend available in {timer}s</p>
                    )}
                </form>
            </div>
            <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        </div>

        
    );
};
export default Otp;