import React, { useEffect, useState } from 'react';
import axios from 'axios';
import classes from '../../styles/contracts.module.css';
import FileUpload from '../../components/fileUpload/FileUploadBusiness'; // File upload component
import { useNavigate } from 'react-router-dom';
import config from '../../aUrl'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const BusinessContract = () => {
  const [pdfData, setPdfData] = useState([]);
  const navigate = useNavigate();
  const localhost = config.apiUrl
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');



  useEffect(() => {
    const fetchPdfData = async () => {
      try {
        const userId = localStorage.getItem('emails'); // Assuming user ID is stored in localStorage
        const response = await axios.get(`${localhost}/getpdfs/${userId}`);
        setPdfData(response.data.pdfs); // Adjust based on the actual response structure
      } catch (error) {
        console.error('Error fetching PDF data:', error);
      }
    };

    const fetchProtectedData = async () => {
      const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage

      try {
        const response = await axios.get(`${localhost}/protected`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the header
          },
        });

        if (response.data === true) {
          console.log("Access granted:", response.data); // The token was verified successfully
          // Proceed with your page logic here
        }

      } catch (error) {
        navigate('/client/login');
      }
    };
    fetchProtectedData();
    fetchPdfData();
  }, []);

  const handleDownload = async (url, filename) => {
      const link = document.createElement('a');
      link.href = url;
      link.download = url;
      link.click();
    // const response = await fetch(pdfUrl);
    // const blob = await response.blob();
    // const url = window.URL.createObjectURL(blob);
    // const link = document.createElement('a');
    // link.href = url;
    // link.download = filename;
    // document.body.appendChild(link);
    // link.click();
    // link.remove();
  };
  





  // Filter PDFs based on search query
  const filteredPdfData = pdfData.filter(pdf =>
    pdf.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);
  const handleFocus = () => {
    setIsSearchBarFocused(true);
  };




  return (
    <div>
      <h2>Business Contracts</h2>


      {/* Search Input */}
      <div className={classes.searchContainer}>
        <input
          type="text"
          placeholder="Search Business Contracts..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={handleFocus}
          onBlur={() => setIsSearchBarFocused(false)}
          className={`${classes.searchBar} ${isSearchBarFocused ? classes.searchBarExpanded : ''}`}
        />

      </div>



      <div className={classes.pdfboxes}>
        {filteredPdfData.map((pdfs, index) => (
          <div
            className={classes.pdfs}
            key={index}
            onClick={() => handleDownload(pdfs.url, pdfs.text)}
            style={{ cursor: 'pointer' }} // Optional: shows a pointer cursor on hover
          >
            <div className={classes.photopdf}>
              <img src={pdfs.imageUrl} alt={`Contract ${pdfs.text}`} /> {/* Use default image if not provided */}
            </div>
            <div className={classes.txtout}>
              <p className={classes.pdftxt}>{pdfs.text}</p>
            </div>
          </div>
        ))}

        <div className={classes.pdfsup}>
          <FileUpload />
        </div>
      </div>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default BusinessContract;
