import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import classes from "../styles/projectscope.module.css";
import Sliderbudget from "../components/projectScope/objective_sliders/SliderBudget";
import Slidercomplete from "../components/projectScope/objective_sliders/SliderComplete";
import OverAllocated from "../components/projectScope/objective_sliders/OverAllocated";
import Normally from "../components/projectScope/objective_sliders/Normally";
import UnderAllocated from "../components/projectScope/objective_sliders/UnderAllocated";
import OverallProgress from "../components/projectScope/OverallProgress";
import ProjectTimeline from "../components/projectScope/ProjectTimeline";
import BurnGraph from "../components/projectScope/BurnGraph";
import config from "../aUrl";

const ProjectScope = () => {
  const navigate = useNavigate();
  const localhost = config.apiUrl;
  const [scope, setScope] = useState([]);
  const [activity , setActivities] = useState([]);
  const [issue, setIssue] = useState([]);
  const [risk, setRisk] = useState([]);
  const [audit, setAudit] = useState([]);

  useEffect(() => {
    const fetchProtectedData = async () => {
      const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage

      try {
        const response = await axios.get(`${localhost}/protected`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the header
          },
        });

        if (response.data === true) {
          console.log("Access granted:", response.data); // The token was verified successfully
          // Proceed with your page logic here
        }
      } catch (error) {
        navigate("/client/login");
      }
    };
    fetchProtectedData();
    FetchActivities();
    FetchTopIssues();
    FetchRisks();
    FetchAudit();
    FetchScope();
  },[]);

  const FetchActivities = async () => {
    const userId = localStorage.getItem("username");
    try{
    const response = await axios.get(`${localhost}/project/activities/${userId}`);
    const result = response.data;
    if (response.status === 200 && result) {
      setActivities(result);
    }
    } catch (error) {
      // alert("error")
    }
  }

  const FetchTopIssues = async () => {
    const userId = localStorage.getItem("username");
    try {
      const response = await axios.get(`${localhost}/project/TopIssues/${userId}`);
      const result = response.data;
      if (response.status === 200 && result) 
      {
        setIssue(result);
      }
    } catch (error) {
      // alert("error")
    }
  }

  const FetchRisks = async () =>{
    const userId = localStorage.getItem("username");
    try {
      const response =  await axios.get(`${localhost}/project/Risks/${userId}`);
      const result = response.data;
      if (response.status === 200 && result) 
      {
        setRisk(result);
      }
    } catch (error) {

    }
  }

  const FetchAudit = async () => {
    const userId = localStorage.getItem("username");
    try {
      const response = await axios.get(`${localhost}/project/Audit/${userId}`);
      const result = response.data;
      if(response.status === 200 && result) 
      {
        setAudit(result);
      }
    } catch (error) {

    }
  }

  const FetchScope = async () => {
    const userId = localStorage.getItem("username");
    try {
      const response = await axios.get(`${localhost}/project/Overall/${userId}`);
      const result = response.data;
      if(response.status === 200 && result) 
      {
        setScope(result);
      }
    } catch (error) {

    }
    
  } 

  const statusColor = scope.status; // Change this to 'Green', 'yellow' or 'red' to change the color of the Overall Status
  const progressValue = scope.progress; // For example, 75% progress


  // Function to get the color based on impact and probability (Don't change this)
  const getImpactColor = (impact) => {
    switch (impact) {
      case "Low":
        return "#6391F3"; // light blue
      case "Medium":
        return "#525CDD"; // level 2 blue
      case "High":
        return "#202AAB"; // level 3 blue
      default:
        return "#f5f5f5"; // Default grey background
    }
  };

  const getProbabilityColor = (probability) => {
    switch (probability) {
      case "Low":
        return "#6391F3"; // light blue
      case "Medium":
        return "#525CDD"; // level 2 blue
      case "High":
        return "#202AAB"; // level 3 blue
      default:
        return "#f5f5f5"; // Default grey background
    }
  };

  return (
    <div>
      <div className={classes.headerbx}>
        <div className={classes.scopehead}>
          <h1>Project Scope</h1>
        </div>

        <div className={classes.headerbx2}>
          {/* Overall Status */}
          <div className={classes.overstat}>
            <p className={classes.headptxt}>Overall Status</p>{" "}
            <div
              className={classes.statclr}
              style={{ backgroundColor: statusColor }}
            ></div>
          </div>

          {/* Overall Progress */}
          <div className={classes.overstat}>
            <p className={classes.headptxt}>Overall Progress</p>{" "}
            <div className={classes.ovrprg}>
              {" "}
              <OverallProgress progress={progressValue} />{" "}
            </div>
          </div>
        </div>
      </div>

      <br></br>

      {/* activity and timeline */}

      <div className={classes.actiline}>
        {/* ongoing activity  */}
        <div className={classes.actvty}>
          <div className={classes.onghed}>
            <h2>Ongoing Activities</h2>
          </div>

          <div className={classes.nmedsc}>
            <div className={classes.nme}>
              <p2>Name</p2>
            </div>
            <div className={classes.dsc}>
              <p2>Description</p2>
            </div>
          </div>

          {/* Activities Here */}
          <div className={classes.actimsgouterbox}>
            {activity.map((activity, index) => (
              <div
                className={classes.actimessbox}
                key={index}
                style={{
                  backgroundColor:
                    activity.id % 2 === 0 ? "#f5f5f5" : "#ffffff",
                }}
              >
                <div className={classes.activnme}>
                  <p2>{activity.name}</p2>
                </div>
                <div className={classes.activdesc}>
                  <p>{activity.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* ongoing Timeline  */}
        <div className={classes.timelne}>
          <h2>Project Timeline</h2>
          <div className={classes.timelneinner}>
            <ProjectTimeline />
          </div>
        </div>
      </div>

      {/* Issue Burndown risks */}

      <div className={classes.isuebrnrsk}>
        {/* Top 5 issues  */}
        <div className={classes.isue}>
          <div className={classes.onghed}>
            <h2>Top 5 Issues</h2>
          </div>

          <div className={classes.nmedsc}>
            <div className={classes.nme}>
              <p2>Rank</p2>
            </div>
            <div className={classes.dsc}>
              <p2>Issue</p2>
            </div>
          </div>

          {/* Issues Here */}
          <div className={classes.issuemsgouterbox}>
            {issue.map((issue, index) => (
              <div
                className={classes.issuemessbox}
                key={index}
                style={{
                  backgroundColor: issue.id % 2 === 0 ? "#f5f5f5" : "#ffffff",
                }}
              >
                <div className={classes.issuenme}>
                  <p2>#{issue.rank}</p2>
                </div>
                <div className={classes.issuedesc}>
                  <p>{issue.issue}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Burndown Graph  */}

        <div className={classes.brngraph}>
          <div className={classes.onghed}>
            <h2>Burndown Graph</h2>
          </div>
          <div className={classes.brngraphheight}>
            <BurnGraph />
          </div>
        </div>

        {/* Risks  */}

        <div className={classes.rsks}>
          <div className={classes.onghed}>
            <h2>Risks</h2>
          </div>

          <div className={classes.rnmerimptrprob}>
            <div className={classes.rnme}>
              <p2>Risk name</p2>
            </div>
            <div className={classes.rimpt}>
              <p2>Impact</p2>
            </div>
            <div className={classes.rprob}>
              <p2>Probability</p2>
            </div>
          </div>

          {/* Risks Here */}
          <div className={classes.risksmsgouterbox}>
            {risk.map((risk, index) => {
              // Determine the background color for risksnme
              const risksnmeBgColor = risk.id % 2 == 0 ? "#f5f5f5" : "#ffffff"; // Grey if id is odd

              // Get colors based on impact and probability
              const risksimpctBgColor = getImpactColor(risk.impact);
              const risksprobBgColor = getProbabilityColor(risk.probability);

              return (
                <div
                  className={classes.risksmessbox}
                  key={index}
                  style={{ backgroundColor: risksnmeBgColor }}
                >
                  <div className={classes.risksnme}>
                    <p2>{risk.name}</p2>
                  </div>
                  <div
                    className={classes.risksimpct}
                    style={{ backgroundColor: risksimpctBgColor }}
                  >
                    <p>{risk.impact}</p>
                  </div>
                  <div
                    className={classes.risksprob}
                    style={{ backgroundColor: risksprobBgColor }}
                  >
                    <p>{risk.probability}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={classes.box1}>
        <h2>Audit</h2>

        <div class={classes.parent}>
          <div class={classes.first}>
            <div className={classes.inbox}>
              <div className={classes.txt}>
                <label>Project Manager</label>
              </div>
              <div className={classes.txt2}>
                <p className={classes.p}>{audit.projectManager}</p>
              </div>
            </div>

            <div className={classes.inbox2}>
              <div className={classes.txtgoal}>
                <label>Goal</label>
              </div>
              <div className={classes.txt2goal}>
                <p className={classes.p}>{audit.goal}</p>
              </div>
            </div>
          </div>

          <div class={classes.second}>
            <div class={classes.s1}>
              <div className={classes.inbox}>
                <div className={classes.txt}>
                  <label>Start</label>
                </div>
                <div className={classes.txt2}>
                  <p className={classes.p}>{audit.start}</p>
                </div>
              </div>

              <div className={classes.inbox2}>
                <div className={classes.txtgoal}>
                  <label>Finish</label>
                </div>
                <div className={classes.txt2goal}>
                  <p className={classes.p}>{audit.finish}</p>
                </div>
              </div>
            </div>
            <div class={classes.s2}>
              <div className={classes.inbox4}>
                <div className={classes.txt}>
                  <label>Duration</label>
                </div>
                <div className={classes.txt2}>
                  <p className={classes.p}>{audit.duration}(s)</p>
                </div>
              </div>

              <div className={classes.inbox5}>
                <div className={classes.txtcomp}>
                  <label>Complete</label>
                </div>
                <div className={classes.txt2goal}>
                  <p className={classes.p}>{audit.complete}%</p>
                </div>
              </div>

              <div className={classes.inbox6}>
                <div className={classes.txtgoal}>
                  <label>Budget</label>
                </div>
                <div className={classes.txt2goal}>
                  <p className={classes.p}>${audit.budget}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Second Box  */}

      <div className={classes.box2}>
        <div className={classes.box2in1}>
          <h2>Cost and Budget</h2>

          {/* Slider for Cost and Budget  */}

          <div className={classes.slidercost}>
            <Sliderbudget />
            <div className={classes.sliderlblbotm} ><p>$0</p><p>${}</p></div>

          </div>
        </div>

        <div className={classes.box2in2}>
          <h2>Project Complete</h2>
          {/* Slider for Cost and Budget  */}

          <div className={classes.slidercost}>
            <Slidercomplete />
            <div className={classes.sliderlblbotm} ><p>0%</p><p>100%</p></div>

          </div>
        </div>
      </div>

      <div className={classes.box3}>
        <div className={classes.resourse}>
          <h2>Resource Allocation</h2>

          <div className={classes.allocators}>
            <div className={classes.aloboxs}>
              <div className={classes.alotxt}>
                <p>Overallocated</p>
              </div>{" "}
              <OverAllocated /> <div></div>
            </div>

            <div className={classes.aloboxs}>
              <div className={classes.alotxt}>
                <p>Normally</p>
              </div>
              <Normally /> <div></div>
            </div>

            <div className={classes.aloboxs}>
              <div className={classes.alotxt}>
                <p>Underallocated</p>
              </div>
              <UnderAllocated /> <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectScope;
