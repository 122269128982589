import * as React from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../../aUrl';

// Custom tooltip to format the value with 'hello'
function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={`${value}`}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

// Define the shadow style
const iOSBoxShadow = 'none';

// Styled Slider component with customized styles
const IOSSlider = styled(Slider)(({ theme }) => ({
  color: '#F1D575', // Slider color
  height: '100%', // Height of the slider
  padding: '0', // Padding around the slider
  
  '& .MuiSlider-thumb': {
    height: '100%', // Thumb height
    width: '10px', // Thumb width
    backgroundColor: '#836707', // Thumb background color
    border: 'none', // Thumb border
    boxShadow: iOSBoxShadow, // Thumb shadow
    borderRadius: '5px', // Thumb border radius
    cursor: 'default', // Default cursor to indicate non-interactivity
    '&.Mui-disabled': {
      backgroundColor: '#836707', // Keep thumb color when disabled
    },
  },

  '& .MuiSlider-valueLabel': {
    fontSize: 16, // Font size for the value label
    fontWeight: 'bold', // Font weight for the value label
    top: 0, // Position of the value label
    backgroundColor: 'transparent', // Background color of the value label
    color: theme.palette.text.primary, // Color of the value label
    '&::before': {
      display: 'none', // Hide before element
    },
    '& *': {
      background: 'transparent', // Background of value label content
      color: theme.palette.mode === 'dark' ? '#fff' : '#000', // Color based on theme
    },
  },
  
  '& .MuiSlider-track': {
    border: 'none', // Track border
    height: '90%',
    borderRadius: '5px 0px 0px 5px', // Track height
    '&.Mui-disabled': {
      backgroundColor: '#F1D575', // Keep track color when disabled
    },
  },
  
  '& .MuiSlider-rail': {
    opacity: 0.7, // Rail opacity
    backgroundColor: '#F5F5F5', // Rail background color
    height: '90%', // Rail height
    borderRadius: '5px 5px 5px 5px', // Rail border radius
    '&.Mui-disabled': {
      backgroundColor: '#F5F5F5', // Keep rail color when disabled
    },
  },

  // Apply styles for the entire slider in the disabled state
  '&.Mui-disabled': {
    color: '#F1D575', // Keep the slider color when disabled
    cursor: 'default', // Default cursor when disabled
  },
}));

export default function App() {
  const localhost = config.apiUrl;
  const [value, setValue] = useState([]);

  useEffect(()=>{
    FetchProjectcost();
  },[])

  const FetchProjectcost = async () => {
    const userId = localStorage.getItem("userId");
    try{
      const response = await axios.get(`${localhost}/project/ProjectComplete/${userId}`);
      const result = response.data;
      if(response.status === 200 && result) 
      {
        setValue(result)
      }
    } catch(error) {

    }
  }

  return (
    <IOSSlider
      aria-label="ios slider"
      min={0} // Minimum value
      max={100000} // Maximum value
      value={value.value} // Default value
      step={100} // Step size
      valueLabelDisplay="on" // Ensure value label is shown
      ValueLabelComponent={ValueLabelComponent} // Custom value label component
      disabled // Make the slider non-interactive
    />
  );
}

