import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import classes from '../styles/objectives.module.css';
import BudgetSlider from '../components/objective/BudgetSlider';
import config from '../aUrl'



  const Objectives = () => {
  
  
    const navigate = useNavigate();
    const localhost = config.apiUrl;
    const [objective, setObjective] = useState([])
    const [breakdown, setBreakdown] = useState([]);
    const [mtask, setMtask] = useState([])
    const [ttask, setTtask] = useState([])
    const [wtask, setWtask] = useState([])
    const [thtask, setThtask] = useState([])
    const [ftask, setFtask] = useState([])

    

    useEffect(() => {
      const fetchProtectedData = async () => {
        const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage
  
        try {
          const response = await axios.get(`${localhost}/protected`, {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the header
            },
          });
  
          if (response.data === true) {
            console.log("Access granted:", response.data); // The token was verified successfully
            // Proceed with your page logic here
          }
  
        } catch (error) {
          navigate('/client/login');
        }
      };
      fetchProtectedData();
      FetchObjectives();
      FetchBreakdown();
      FetchMTask();
      FetchTTask();
      FetchWTask();
      FetchThTask();
      FetchFTask();
    },[]);

    const FetchObjectives = async () => {
      const userId = localStorage.getItem("username");
      try {
        const response = await axios.get(`${localhost}/objective/objectives/${userId}`);
        const result = response.data;
        if(response.status === 200 && result) 
        {
          setObjective(result);
        }
      } catch (err) {
        
      }
    }

    const FetchBreakdown = async () => {
      const userId = localStorage.getItem("username");
      try {
        const response = await axios.get(`${localhost}/objective/financialBreakdown/${userId}`);
        const result = response.data;
        if(response.status === 200 && result)
        {
          setBreakdown(result);
        }
      } catch (err) {

      }
    }

    const FetchMTask = async () => {
      const userId = localStorage.getItem("username")
      try {
       const response = await axios.get(`${localhost}/objective/MondayTask/${userId}`);
       const result = response.data;
       if(response.status === 200 && result)
       {
         setMtask(result)
       }
      } catch (err) {

      }
    }

    const FetchTTask = async () => {
      const userId = localStorage.getItem("username")
      try {
       const response = await axios.get(`${localhost}/objective/TuesdayTask/${userId}`);
       const result = response.data;
       if(response.status === 200 && result)
       {
         setTtask(result)
       }
      } catch (err) {

      }
    }

    const FetchWTask = async () => {
      const userId = localStorage.getItem("username")
      try {
       const response = await axios.get(`${localhost}/objective/WednesdayTask/${userId}`);
       const result = response.data;
       if(response.status === 200 && result)
       {
         setWtask(result)
       }
      } catch (err) {

      }
    }

    const FetchThTask = async () => {
      const userId = localStorage.getItem("username")
      try {
       const response = await axios.get(`${localhost}/objective/ThursdayTask/${userId}`);
       const result = response.data;
       if(response.status === 200 && result)
       {
         setThtask(result)
       }
      } catch (err) {

      }
    }

    const FetchFTask = async () => {
      const userId = localStorage.getItem("username")
      try {
       const response = await axios.get(`${localhost}/objective/FridayTask/${userId}`);
       const result = response.data;
       if(response.status === 200 && result)
       {
         setFtask(result)
       }
      } catch (err) {

      }
    }




  
    // temp value for objectives
    const objectives = [
      { id: 1, title: 'Objective 1' },
      { id: 2, title: 'Objective 2' },
      { id: 3, title: 'Objective 3' },
      { id: 4, title: 'Objective 4' },
      { id: 5, title: 'Objective 5' },
      { id: 6, title: 'Objective 6' },
      { id: 7, title: 'Objective 7' },
      { id: 8, title: 'Objective 8' },
      { id: 9, title: 'Objective 9' },
      { id: 10, title: 'Objective 10' },
      { id: 11, title: 'Objective 11' },
      { id: 12, title: 'Objective 12' },
      { id: 13, title: 'Objective 13' },
    ];

  // tasks values temp 
  
  const taskSchedule = {
    Monday: [
      { id: 1, task: "Task 1" },
      { id: 2, task: "Task 2" },
      { id: 3, task: "Task 3" },
      { id: 4, task: "Task 4" },
      { id: 5, task: "Task 5" },

    ],
    Tuesday: [
      { id: 1, task: "Task 1" },
      { id: 2, task: "Task 2" },
      { id: 3, task: "Task 3" },
    ],
    Wednesday: [
      { id: 1, task: "Task 1" },
    ],
    Thursday: [
      { id: 1, task: "Task 1" },
      { id: 2, task: "Task 2" },
    ],
    Friday: [
      { id: 1, task: "Task 1" },
    ],
  };
  
  
  const colors = ["#DBE7FE", "#ECDDFF", "#FCEBE4"]; //tasks color
    const activities = [
      { id: 1, cost: "$200", description: "Hello there, iuewfhkjwe eofhj oeihf oiohe" },
      { id: 2, cost: "$200", description: "Urna donec cras molestie sit justo etiam." },
      { id: 3, cost: "$300", description: "Urna donec cras molestie sit justo etiam." },
      { id: 4, cost: "$500", description: "Urna donec cras molestie sit justo etiam." },
      { id: 5, cost: "$700", description: "Urna donec cras molestie sit justo etiam." },
      { id: 6, cost: "$200", description: "Urna donec cras molestie sit justo etiam." },
      { id: 7, cost: "$100", description: "Urna donec cras molestie sit justo etiam." },
      { id: 8, cost: "$200", description: "Urna donec cras molestie sit justo etiam." },
      { id: 9, cost: "$300", description: "Urna donec cras molestie sit justo etiam." },
      { id: 10, cost: "$500", description: "Urna donec cras molestie sit justo etiam." },
      { id: 11, cost: "$700", description: "Urna donec cras molestie sit justo etiam." },
      { id: 12, cost: "$200", description: "Urna donec cras molestie sit justo etiam." },
      { id: 13, cost: "$100", description: "Urna donec cras molestie sit justo etiam." },
      { id: 14, cost: "$200", description: "Urna donec cras molestie sit justo etiam." },
    ];
  
  
  
  
  
  
  
  
  
    return (
      <div>
        <h1>Objectives</h1>
        <br></br>
  
        <div className={classes.objout}>
          <div className={classes.obj1}>
  
            <div className={classes.obj11}>
  
              {objective.map((objective, index) => (
                <div key={index} className={classes.objs}>
  
                  <div className={classes.bluedt}></div><p>&nbsp;{objective.objective}</p>
  
                </div>
              ))}
  
            </div>
  
          </div>
  
  
  
  
  
  
  
  
          <div className={classes.obj2}>
  
            <div className={classes.csb}>
              <h2>Costs and Budget</h2>
              <br></br>
              <div className={classes.csbgrph}>
                <BudgetSlider />
                <div className={classes.bdjslidelabek}><p>$0</p> <p>$100000</p></div>
              </div>
  
  
  
              {/* BREAKDOWN   */}
              <h2>Financial Breakdown</h2>
              <br></br>
              <div className={classes.actvty}>
  
  
                <div className={classes.nmedsc}>
                  <div className={classes.nme}><p2>Cost</p2></div>
                  <div className={classes.dsc}><p2>Description</p2></div>
                </div>
  
  
  
                {/* BREAKDOWNS Here */}
                <div className={classes.actimsgouterbox}>
  
  
                  {breakdown.map((activity, index) => (
                    <div
                      className={classes.actimessbox}
                      key={index}
                      style={{ backgroundColor: activity.id % 2 === 0 ? "#f5f5f5" : "#ffffff" }}
                    >
                      <div className={classes.activnme}><p>{activity.cost}</p></div>
                      <div className={classes.activdesc}><p>{activity.description}</p></div>
                    </div>
                  ))}
  
                </div>
  
              </div>
  
  
            </div>
          </div>
        </div>

        <div className={classes.objweek}>
          <h2>This Week At A Glance</h2>
          <br></br>
  
          <div className={classes.weekglance}>
  
  
           <div className={classes.mon}>
            <div className={classes.weeklabel}><p>Monday</p></div>
  
            {mtask.map((task, index) => (
              <div key={index} className={classes.tasks} style={{
                backgroundColor: colors[index % colors.length],
              }} >
                <p>{task.description}</p>
              </div>
            ))}
            </div> 
  
  
           <div className={classes.tue}>
           <div className={classes.weeklabel}><p>Tuesday</p></div>
           {ttask.map((task, index) => (
              <div key={index} className={classes.tasks} style={{
                backgroundColor: colors[index % colors.length],
              }} >
                <p>{task.description}</p>
              </div>
            ))}
             </div> 
  
  
           <div className={classes.wed}> 
           <div className={classes.weeklabel}><p>Wednesday</p></div>
           {wtask.map((task, index) => (
              <div key={index} className={classes.tasks} style={{
                backgroundColor: colors[index % colors.length],
              }} >
                <p>{task.description}</p>
              </div>
            ))}
            </div> 
  
  
           <div className={classes.thu}>
           <div className={classes.weeklabel}><p>Thursday</p></div>
           {thtask.map((task, index) => (
              <div key={index} className={classes.tasks} style={{
                backgroundColor: colors[index % colors.length],
              }} >
                <p>{task.description}</p>
              </div>
            ))}
           
            </div> 
  
  
  
  
  
  
           <div className={classes.fri}>
           <div className={classes.weeklabel}><p>Friday</p></div>
           {ftask.map((task, index) => (
              <div key={index} className={classes.tasks} style={{
                backgroundColor: colors[index % colors.length],
              }} >
                <p>{task.description}</p>
              </div>
            ))}
            </div> 
  
  
  
            </div>
        </div>
  
      </div>
    );
  };
  
  export default Objectives;
  
