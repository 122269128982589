import React from 'react';
import { Route, Routes, NavLink, Navigate } from 'react-router-dom';
import classes from '../styles/accountSetting/accountSetting.module.css';
import ActiveClient from '../pages/clientLists/ActiveClient';
import DeactiveClient from '../pages/clientLists/DeactiveClient';

const clientList = () => {
  return (
    <div className={classes['account-setting']}>
      <nav className={classes.tabs}>
        <NavLink
          to="active"
          className={({ isActive }) => (isActive ? `${classes.active} ${classes.tab}` : classes.tab)}
        >
          Active
        </NavLink>
        <NavLink
          to="deactive"
          className={({ isActive }) => (isActive ? `${classes.active} ${classes.tab}` : classes.tab)}
        >
          Deactive
        </NavLink>

      </nav>
      <div className={classes.content}>
        <Routes>
          <Route path="active" element={<ActiveClient />} />
          <Route path="deactive" element={<DeactiveClient />} />
          <Route path="/" element={<Navigate to="active" />} /> {/* Default route */}
        </Routes>
      </div>
    </div>
  );
};

export default clientList;