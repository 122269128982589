import React from 'react';

import classes from '../../styles/billsInvoices/pending.module.css';



const pending = [
    {
        id: "1234",
        amount: "$500 USD",
        dueDate: "12/12/2021",
        invoiceDoc: "https://www.freshbooks.com/wp-content/uploads/2023/08/PDF-Invoice-Templates-US-Template-01.pdf",
        link: "https://stripe.com/in"
    },
    {
        id: "1234",
        amount: "$500 USD",
        dueDate: "12/12/2021",
        invoiceDoc: "https://www.freshbooks.com/wp-content/uploads/2023/08/PDF-Invoice-Templates-US-Template-01.pdf",
        link: "https://stripe.com/in"
    },

    {
        id: "1234",
        amount: "$500 USD",
        dueDate: "12/12/2021",
        invoiceDoc: "https://www.freshbooks.com/wp-content/uploads/2023/08/PDF-Invoice-Templates-US-Template-01.pdf",
        link: "https://stripe.com/in"
    },

    {
        id: "1234",
        amount: "$500 USD",
        dueDate: "12/12/2021",
        invoiceDoc: "https://www.freshbooks.com/wp-content/uploads/2023/08/PDF-Invoice-Templates-US-Template-01.pdf",
        link: "https://stripe.com/in"
    },

    {
        id: "1234",
        amount: "$500 USD",
        dueDate: "12/12/2021",
        invoiceDoc: "https://www.freshbooks.com/wp-content/uploads/2023/08/PDF-Invoice-Templates-US-Template-01.pdf",
        link: "https://stripe.com/in"
    },

    {
        id: "1234",
        amount: "$500 USD",
        dueDate: "12/12/2021",
        invoiceDoc: "https://www.freshbooks.com/wp-content/uploads/2023/08/PDF-Invoice-Templates-US-Template-01.pdf",
        link: "https://stripe.com/in"
    },

];






const Pending = () => {
    return (
        <div>
            <h2>Pending Bills</h2>
            <br></br>


            <div className={classes.pendingbox}>

                <div className={classes.table}>
                    <div className={classes.labeloflist}>

                        <div className={classes.lsth1}>#Id</div>
                        <div className={classes.lsth2}>Amount</div>
                        <div className={classes.lsth3}>Due date</div>
                        <div className={classes.lsth4}>Invoice</div>
                        <div className={classes.lsth5}>Payment Link</div>

                    </div>



                    <div className={classes.listsbox}>
                        {pending.map((item) => (
                            <div
                                key={item.id}
                                className={classes.singlelist}
                            >
                                <div className={classes.itmh1}>{item.id}</div>
                                <div className={classes.itmh2}>{item.amount}</div>
                                <div className={classes.itmh3}>{item.dueDate}</div>

                                <div className={classes.itmh4}>
                                    <a href={item.invoiceDoc} download target="_blank" rel="noopener noreferrer" className={classes.downloadButton1} >
                                        <p>View/Download</p>

                                    </a>
                                </div>

                                <div className={classes.itmh51}>
                                <a href={item.link} download target="_blank" rel="noopener noreferrer" className={classes.downloadButton} >
                                        <p>Pay Now</p>

                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Pending;


