import React from 'react';
import classes from '../styles/contracts.module.css';
import { useDropzone } from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const FileUpload = () => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            // Handle file upload logic here
            console.log(acceptedFiles);
        },
        accept: 'image/*', // Example: accept only image files
    });

    return (
        <div
            {...getRootProps()}
            style={{
                border: '2px dashed #cccccc',
                borderRadius: '4px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                height: '100%',
            }}
        >



            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop the files here ...</p>
            ) : (
                <div className={classes.photoupload}>

            <div className={classes.uploadthefile}>

            <div className={classes.iconupload}>
              <UploadFileIcon  sx={{ fontSize: 45 }} />
              
            </div>


            <div className={classes.txtout2}>

              <p className={classes.pdftxt2}>Select your document to upload</p>
              <br></br>

              <p className={classes.pdftxt3}>or drag and drop a here</p>
            </div>
            </div>


          </div>
            )}
        </div>
    );
};

export default FileUpload;
