import * as React from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

// Custom tooltip to format the value with 'hello'
function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={`${value}`}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

// Define the shadow style
const iOSBoxShadow = 'none';
// Styled Slider component with customized styles
const IOSSlider = styled(Slider)(({ theme }) => ({
  color: '#6391F3', // Slider color
  height: '100%', // Height of the slider
  padding: '0', // Padding around the slider
  
  '& .MuiSlider-thumb': {
    height: '100%', // Thumb height
    width: '10px', // Thumb width
    backgroundColor: '#836707', // Thumb background color
    border: 'none', // Thumb border
    boxShadow: iOSBoxShadow, // Thumb shadow
    borderRadius: '5px', // Thumb border radius
    cursor: 'default', // Default cursor to indicate non-interactivity
    '&.Mui-disabled': {
      backgroundColor: '#184199', // Keep thumb color when disabled
    },
  },

  '& .MuiSlider-valueLabel': {
    fontSize: 16, // Font size for the value label
    fontWeight: 'bold', // Font weight for the value label
    top: 0, // Position of the value label
    backgroundColor: 'transparent', // Background color of the value label
    color: theme.palette.text.primary, // Color of the value label
    '&::before': {
      display: 'none', // Hide before element
    },
    '& *': {
      background: 'transparent', // Background of value label content
      color: theme.palette.mode === 'dark' ? '#fff' : '#000', // Color based on theme
    },
  },
  
  '& .MuiSlider-track': {
    border: 'none', // Track border
    height: '90%',
    borderRadius: '5px 0px 0px 5px', // Track height
    '&.Mui-disabled': {
      backgroundColor: '#6391F3', // Keep track color when disabled
    },
  },
  
  '& .MuiSlider-rail': {
    opacity: 0.7, // Rail opacity
    backgroundColor: '#F5F5F5', // Rail background color
    height: '90%', // Rail height
    borderRadius: '5px 5px 5px 5px', // Rail border radius
    '&.Mui-disabled': {
      backgroundColor: '#F5F5F5', // Keep rail color when disabled
    },
  },

  // Apply styles for the entire slider in the disabled state
  '&.Mui-disabled': {
    color: '#6391F3', // Keep the slider color when disabled
    cursor: 'default', // Default cursor when disabled
  },


}));

const SliderBudget = ({ value, onChange }) => {
  return (
    <IOSSlider
      aria-label="ios slider"
      min={0}
      max={100000}
      value={value} // Use passed value
      step={1}
      valueLabelDisplay="on"
      ValueLabelComponent={ValueLabelComponent}
      onChange={onChange} // Handle value changes
      disabled
    />
  );
};

SliderBudget.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SliderBudget;

