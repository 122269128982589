import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { colors } from '@mui/material';

export default function EnhancedLineChart() {
  return (
    <LineChart
      xAxis={[{ data: [1, 2, 3, 4, 5, 6]}]}
      series={[
        {
          data: [5, 15, 10, 20, 15, 10],
          smooth: true, // Smooth the line to create a curve
          area: false, // No shaded area under the line
          color: '#5CE1E6', // Set the curve (line) color to black
        }
        
      ]}
      tooltip={{
        trigger: 'axis', // Tooltip will appear when hovering over axis points
        axisPointer: {
          type: 'cross', // Add a crosshair pointer
          label: {
            backgroundColor: '#5CE1E6', // Style the tooltip label
          },
        },
      }}
    />
  );
}
