import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { colors } from '@mui/material';

export default function EnhancedLineChart() {
  return (
    <LineChart
      xAxis={[{ data: [5, 7, 8, 10, 11, 13, 16, 19, 22, 24, 27, 30, 32]}]}
      series={[
        {
          data: [1, 3, 4, 6, 5, 6, 2, 4, 3, 5, 7, 4, 3],
          smooth: true, // Smooth the line to create a curve
          area: false, // No shaded area under the line
          color: '#5CE1E6', // Set the curve (line) color to black
        }
        
      ]}
      tooltip={{
        trigger: 'axis', // Tooltip will appear when hovering over axis points
        axisPointer: {
          type: 'cross', // Add a crosshair pointer
          label: {
            backgroundColor: '#5CE1E6', // Style the tooltip label
          },
        },
      }}
    />
  );
}
