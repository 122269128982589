import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classes from '../../styles/accountSetting/payment.module.css'; // Adjust the path as per your folder structure
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../aUrl';

const Payment = () => {
    // Initial state with default values
    const [isEditMode, setIsEditMode] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(true); // New state to track submission
    const params = useParams();
    const localhost = config.apiUrl;

    const [formData, setFormData] = useState({
        eftAuthorizationDate: '',
        onlineBillPayment: '',
        routingNumber: '',
        clientSetUpDate: '',
        accountNumber: '',
        verificationDate: '',
        transferDate: '',
        firstPaymentDate: '',
        amount1: '',
        amount2: '',
        paymentFrequency1: '',
        paymentFrequency2: '',
        accountingBudgetPerMonth: '',
        estimateOfServices: ''
    });

    // Storing the initial form data
    const [initialFormData, setInitialFormData] = useState({ ...formData });

    // Toggle between edit and view modes
    const toggleEditMode = () => {
        if (!isEditMode) {
            // Remove 'N/A' from fields when entering edit mode
            const updatedFormData = Object.fromEntries(
                Object.entries(formData).map(([key, value]) => [key, value === 'N/A' ? '' : value])
            );
            setInitialFormData({ ...updatedFormData });
        }
        setIsEditMode(!isEditMode);
    };

// date format function
    const formatDate = (dateString) => {
        if (!dateString) return 'N/A'; // Handle empty or undefined dates
    
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
    
        return `${month}/${day}/${year}`;
    };



    // Handle form data changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleCancel = () => {
        setFormData({ ...initialFormData });
        setIsEditMode(false);
    };








    // Handle save action
    const handleSave = async (e) => {
        e.preventDefault();
    
        // Check if any required fields are empty
        for (const [key, value] of Object.entries(formData)) {
            // Ensure value is a string before calling trim()
            if (!String(value).trim()) {
                toast.error(`Please fill in the ${key.replace(/([A-Z])/g, ' $1').toLowerCase()}`, {
                });
                return; // Exit the function if validation fails
            }
        }
    
        const paymentData = {
            eftAuthorizationDate: formData.eftAuthorizationDate,
            onlineBillPayment: formData.onlineBillPayment,
            routingNumber: formData.routingNumber,
            clientSetUpDate: formData.clientSetUpDate,
            accountNumber: formData.accountNumber,
            verificationDate: formData.verificationDate,
            transferDate: formData.transferDate,
            firstPaymentDate: formData.firstPaymentDate,
            amount1: formData.amount1,
            amount2: formData.amount2,
            paymentFrequency1: formData.paymentFrequency1,
            paymentFrequency2: formData.paymentFrequency2,
            accountingBudgetPerMonth: formData.accountingBudgetPerMonth,
            estimateOfServices: formData.estimateOfServices,
        };
    
        const response = await fetch(`${localhost}/savePaymentByAdmin/${params.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentData),
        });
    
        if (response.status === 200) {
            setFormData(paymentData);
            setIsSubmitted(true);
            setIsEditMode(false); // Exit edit mode after saving
            toast.success("Information stored successfully");
        } else {
            toast.error("Failed to store information");
        }
    };

    












    const getPaymentInformation = async () => {
    try {
        const response = await axios.get(`${localhost}/getPaymentDataForAdmin/${params.id}`);
        
        if (response.status === 200) {
            const result = response.data.payment || {};
            setFormData({
                eftAuthorizationDate: formatDate(result.eftAuthorizationDate),
                onlineBillPayment: (result.onlineBillPayment),
                routingNumber: result.routingNumber || 'N/A',
                clientSetUpDate: formatDate(result.clientSetUpDate),
                accountNumber: result.accountNumber || 'N/A',
                verificationDate: formatDate(result.verificationDate),
                transferDate: formatDate(result.transferDate),
                firstPaymentDate: formatDate(result.firstPaymentDate),
                amount1: result.amount1 || 'N/A',
                amount2: result.amount2 || 'N/A',
                paymentFrequency1: result.paymentFrequency1 || 'N/A',
                paymentFrequency2: result.paymentFrequency2 || 'N/A',
                accountingBudgetPerMonth: result.accountingBudgetPerMonth || 'N/A',
                estimateOfServices: result.estimateOfServices || 'N/A'
            });
            
        } else {
            setFormData({
                eftAuthorizationDate: 'N/A',
                onlineBillPayment: 'N/A',
                routingNumber: 'N/A',
                clientSetUpDate: 'N/A',
                accountNumber: 'N/A',
                verificationDate: 'N/A',
                transferDate: 'N/A',
                firstPaymentDate: 'N/A',
                amount1: 'N/A',
                amount2: 'N/A',
                paymentFrequency1: 'N/A',
                paymentFrequency2: 'N/A',
                accountingBudgetPerMonth: 'N/A',
                estimateOfServices: 'N/A'
            });
        }
    } catch (error) {
        setFormData({
            eftAuthorizationDate: 'N/A',
            onlineBillPayment: 'N/A',
            routingNumber: 'N/A',
            clientSetUpDate: 'N/A',
            accountNumber: 'N/A',
            verificationDate: 'N/A',
            transferDate: 'N/A',
            firstPaymentDate: 'N/A',
            amount1: 'N/A',
            amount2: 'N/A',
            paymentFrequency1: 'N/A',
            paymentFrequency2: 'N/A',
            accountingBudgetPerMonth: 'N/A',
            estimateOfServices: 'N/A'
        });
    }
};




        

    useEffect(() => {
        getPaymentInformation();
    }, []);

    return (
        <div className={`${classes.profilesettingscontainer}`}>

            {/* ToastContainer for notifications */}
            <ToastContainer />
            <div className={`${classes.personalsection}`}>
                <div className={classes.otrhead}>
                    <div className={classes.inotrhead}>
                        <h2>Payment Information</h2>
                    </div>
                    <div className={classes.inotrhead}>
                        {/* Edit button toggles to cancel when in edit mode */}
                        <button type='button' className={`${classes.saveimgbutton} ${classes.inotrheadedt}`} onClick={isEditMode ? handleCancel : toggleEditMode}>
                            {isEditMode ? 'Cancel' : 'Edit'}
                        </button>

                        {/* Save button appears only in edit mode */}
                        {isEditMode && (
                            <button onClick={handleSave} className={`${classes.saveimgbutton}`}>
                                Save
                            </button>
                        )}
                    </div>
                </div>

                <form>
                    {/* PAYMENT DETAILS */}
                    <div className={`${classes.formgroup}`}>
                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>EFT Authorization Date</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.eftAuthorizationDate || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="date"
                                        name="eftAuthorizationDate"
                                        value={formData.eftAuthorizationDate}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>

                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>Online Bill Payment</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.onlineBillPayment || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="text"
                                        name="onlineBillPayment"
                                        value={formData.onlineBillPayment}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.formgroup}`}>
                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>Routing Number</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.routingNumber || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="number"
                                        name="routingNumber"
                                        value={formData.routingNumber}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>

                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>Client Set Up Date</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.clientSetUpDate || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="date"
                                        name="clientSetUpDate"
                                        value={formData.clientSetUpDate}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.formgroup}`}>
                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>Account Number</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.accountNumber || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="number"
                                        name="accountNumber"
                                        value={formData.accountNumber}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>

                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>Verification Date</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.verificationDate || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="date"
                                        name="verificationDate"
                                        value={formData.verificationDate}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.formgroup}`}>
                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>Transfer Date</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.transferDate || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="date"
                                        name="transferDate"
                                        value={formData.transferDate}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>

                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>First Payment Date</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.firstPaymentDate || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="date"
                                        name="firstPaymentDate"
                                        value={formData.firstPaymentDate}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.formgroup}`}>
                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>Amount 1</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.amount1 || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="number"
                                        name="amount1"
                                        value={formData.amount1}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>

                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>Amount 2</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.amount2 || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="number"
                                        name="amount2"
                                        value={formData.amount2}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.formgroup}`}>
                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>Payment Frequency 1</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.paymentFrequency1 || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="text"
                                        name="paymentFrequency1"
                                        value={formData.paymentFrequency1}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>

                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>Payment Frequency 2</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.paymentFrequency2 || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="text"
                                        name="paymentFrequency2"
                                        value={formData.paymentFrequency2}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.formgroup}`}>
                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>Accounting Budget Per Month</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.accountingBudgetPerMonth || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="number"
                                        name="accountingBudgetPerMonth"
                                        value={formData.accountingBudgetPerMonth}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>

                        <div className={`${classes.formitem}`}>
                            <label className={`${classes.labels}`}>Estimate Of Services</label>
                            <div className={`${classes.inputwrapper}`}>
                                {isSubmitted && !isEditMode ? (
                                    <p>{formData.estimateOfServices || 'N/A'}</p>
                                ) : (
                                    <input
                                        type="text"
                                        name="estimateOfServices"
                                        value={formData.estimateOfServices}
                                        onChange={handleInputChange}
                                        className={`${classes.inputs}`}
                                        required
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Payment;
