import React, { useEffect, useState } from "react";
import classes from "../../styles/accountSetting/profile.module.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import profileImage from "../../assets/profileimg.png";
import spinner from "../../assets/spinner.gif"
import { useNavigate } from "react-router-dom";
import configs from './url'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Profile = () => {
  const navigate = useNavigate();
  const localhost = configs.apiUrl
  const [isLoading, setIsLoading] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [emails, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [user, setUser] = useState({});
  const [newPassword, setNewPassword] = useState('');
  const [CPassword, setCPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    setFirstname(localStorage.getItem("firstname"));
    setEmail(localStorage.getItem("emails"));
    setUsername(localStorage.getItem("username"));

    const fetchUserData = async () => {
      try {
        const username = localStorage.getItem("emails");
        const response = await axios.get(`${localhost}/userdata/${username}`);
        console.log("Fetched User Data:", response.data);  // Debugging line
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    const fetchProtectedData = async () => {
      const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage

      try {
        const response = await axios.get(`${localhost}/protected`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the header
          },
        });

        if (response.data === true) {
          console.log("Access granted:", response.data); // The token was verified successfully
          // Proceed with your page logic here
        }

      } catch (error) {
        navigate('/client/login');
      }
    };
    fetchProtectedData();
    fetchUserData();
  }, []);

  const handlePassword = async (e) => {
    e.preventDefault();

    if (newPassword.length < 8) {
      toast.error("Password must contain at least 8 characters");
      return;
    } else if (newPassword !== CPassword) {
      toast.error("Passwords do not match!");
      return;
    } else if (!/[a-z]/.test(newPassword)) {
      toast.error("Your password must contain a lowercase letter");
      return;
    } else if (!/[A-Z]/.test(newPassword)) {
      toast.error("Your password must contain an uppercase letter");
      return;
    } else if (!/[0-9]/.test(newPassword)) {
      toast.error("Your password must contain a digit");
      return;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
      toast.error("Your password must contain a special character");
      return;
    }

    try {
      setIsLoading(true);
      const Username = localStorage.getItem('username');
      const response = await axios.put(`${localhost}/updatepassword`, {
        Username,
        CPassword,
        newPassword
      });
      setMessage(response.data.message);
      toast.success(response.data.message);
    } catch (err) {
      setMessage(err.response.data.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    formData.append("username", username);

    try {
      setIsLoading(true)
      toast("Please Wait...");
      const response = await axios.post(`${localhost}/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Upload Response:", response.data); // Debugging line
      setUser(response.data);
      toast.success("Image Uploaded Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error("Failed to upload image");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.profilesettingscontainer}>
      <div className={classes.personalsection}>
        <ToastContainer />
        <h2>Personal</h2>

        <div className={classes.profilepicture}>
          {/* <img src={user.imageUrl ? user.imageUrl : profileImage} alt="Profile" /> */}
          <img src={isLoading ? (user.imageUrl = "https://cdnl.iconscout.com/lottie/premium/thumb/infinity-loader-animation-download-in-lottie-json-gif-static-svg-file-formats--infinite-loaders-pack-user-interface-animations-4718941.gif"):(user.imageUrl ? user.imageUrl : profileImage)} alt="Profile" />
          <div className={classes.imageuploadsec}>
            <label className={classes.uploadimgbtn} htmlFor="file-upload" style={{ cursor: "pointer" }}>
              Upload Image
              <input
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                style={{ display: "none" }}

              />
            </label>
          </div>
        </div>

        <div className={classes.formgroup}>
          <div className={classes.formitem}>
            <label htmlFor="first-name" className={classes.labels}>Full Name</label>
            <div className={classes.inputwrapper}>
              <input type="text" id="first-name" value={firstname} className={classes.inputs} readOnly />
            </div>
          </div>


          <div className={classes.formitem}>
            <label htmlFor="last-name" className={classes.labels}>Username</label>
            <div className={classes.inputwrapper}>
              <input type="text" id="last-name" value={username} className={classes.inputs} readOnly />
            </div>
          </div>

        </div>

        <div className={classes.formgroup}>
          <div className={classes.formitem}>
            <label htmlFor="email" className={classes.labels}>Email</label>
            <div className={classes.inputwrapper}>
              <input type="email" id="email" defaultValue={emails} className={classes.inputs} readOnly />
            </div>
          </div>
        </div>
      </div>
      <hr className={classes.line} />

      <form onSubmit={handlePassword}>
        <div className={classes.privacysection}>
          <h2>Change Your Password</h2>
          <br />

          <div className={classes.formgroup2}>
            <div className={classes.formitem}>
              <label htmlFor="new-password" className={classes.labels}>New Password</label>
              <input type="password" value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)} className={classes.inputpassword} id="new-password" />
            </div>

            <div className={classes.formitem}>
              <label htmlFor="confirm-password" className={classes.labels}>Confirm password</label>
              <input type="password" value={CPassword}
                onChange={(e) => setCPassword(e.target.value)} className={classes.inputpassword} id="confirm-password" />
            </div>
          </div>

          <button type="submit" className={classes.savepassbutton}>
            Save Password
          </button>
        </div>
      </form>
    </div>
  );
};

export default Profile;
