import React from 'react';

import classes from '../../styles/accountSetting/profile.module.css';
import profileImage from '../../assets/profileimg.png';



const Profile = () => {
    return (




        <div className={`${classes.profilesettingscontainer}`}>

            <div className={`${classes.personalsection}`}>
                <h2>Personal</h2>


                <div className={classes.profilepicture}>
                    <img alt="Profile" src={profileImage}  />

                    <div className={classes.imageuploadsec}>
                        <label className={classes.uploadimgbtn} htmlFor="file-upload" style={{ cursor: "pointer" }}>
                            Upload Image
                            <input
                                id="file-upload"
                                type="file"
                                accept="image/*"
                                // onChange={handleFileUpload}
                                style={{ display: "none" }}
                            />
                        </label>
                    </div>
                </div>





                {/* USER DETAILS */}
                <div className={`${classes.formgroup}`}>
                    <div className={`${classes.formitem}`}>
                        {/* FIRST NAME  */}
                        <label htmlFor="first-name" className={`${classes.labels}`} >First Name</label>
                        <div className={`${classes.inputwrapper}`}>
                            <input type="text" id="first-name" defaultValue="First Name" disabled className={`${classes.inputs}`} />



                            {/* EDIT BUTTON FIRST NAME */}

                            {/* <button className={`${classes.editbutton}`}>
                                <i className={`${classes.editicon}`}></i>
                            </button> */}
                        </div>
                    </div>
                    <div className={`${classes.formitem}`}>
                        {/* LAST NAME */}
                        <label htmlFor="last-name" className={`${classes.labels}`} >Last Name</label>
                        <div className={`${classes.inputwrapper}`}>
                            <input type="text" id="last-name" defaultValue="Last Name" disabled className={`${classes.inputs}`} />



                            {/* EDIT BUTTON LAST NAME */}

                            {/* <button className={`${classes.editbutton}`}>
                                <i className={`${classes.editicon}`}></i>
                            </button> */}
                        </div>
                    </div>
                </div>






                {/* EMAIL */}
                <div className={`${classes.formgroup}`}>

                    <div className={`${classes.formitem}`}>

                        <label htmlFor="email" className={`${classes.labels}`} >Email</label>
                        <div className={`${classes.inputwrapper}`}>
                            <input type="email" id="email" defaultValue="abc@mail.com" disabled className={`${classes.inputs}`} />

                            {/* EDIT BUTTON EMAIL  */}

                            {/* <button className={`${classes.editbutton}`}>
                                <i className={`${classes.editicon}`}></i>
                            </button> */}

                        </div>
                    </div>
                </div>









            </div>
            <hr className={`${classes.line}`} />




            {/* PASSWORD CHANGE SECTION  */}

            {/* PASSWORD UPDATE FORM */}

            <form action="" method="">

                <div className={`${classes.privacysection}`}>
                    <h2>Privacy and Security</h2>
                    <br></br>

                    <div className={`${classes.formgroup2}`}>




                        <div className={`${classes.formitem}`}>
                            <label htmlFor="new-password" className={`${classes.labels}`} >New Password</label>
                            <input type="password" className={`${classes.inputpassword}`} id="new-password" />
                        </div>
                        <div className={`${classes.formitem}`}>
                            <label htmlFor="confirm-password" className={`${classes.labels}`} >Confirm password</label>
                            <input type="password" className={`${classes.inputpassword}`} id="confirm-password" />
                        </div>
                    </div>


                    {/* SAVE PASSWORD BUTTON */}

                    <button type='submit' className={`${classes.savepassbutton}`}>
                        Save Password
                    </button>


                </div>
            </form>

        </div>


    );
};

export default Profile;