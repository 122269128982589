import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from '../../styles/faq.module.css';

const S1 = () => {



    const FAQ = [
        {
          question: "How do I manage my privacy settings and view terms?",
          answer: "Manage your privacy settings and view terms by accessing the 'Privacy' section. Here, you can update your privacy preferences and review the terms of service and privacy policy."
        },
        {
          question: "Can I change my privacy preferences?",
          answer: "Yes, you can change your privacy preferences in the 'Privacy' section by selecting the options that best suit your needs."
        },
        {
          question: "How do I review the terms of service?",
          answer: "Review the terms of service by clicking on the 'Terms of Service' link in the Privacy section."
        },
        {
          question: "How do I update my privacy policy?",
          answer: "Updates to your privacy policy can be made by selecting 'Edit Privacy Policy' in the Privacy section."
        }
      ];



    return (
        <div className={classes.priv}>
            <h1>Privacy FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
