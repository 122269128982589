import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from '../../styles/faq.module.css';

const S1 = () => {



    // data/proactiveFAQ.js
const FAQ = [
    {
      question: "What is the Proactive section used for?",
      answer: "The Proactive section is used for managing proactive tasks and strategies. It helps you plan and execute actions to address potential issues before they arise."
    },
    {
      question: "How do I add a new proactive task?",
      answer: "Add new proactive tasks by clicking on 'Add Task' in the Proactive section and filling in the required details."
    },
    {
      question: "Can I track the progress of proactive tasks?",
      answer: "Yes, progress can be tracked in the Proactive section where you can view task statuses and updates."
    },
    {
      question: "How do I prioritize proactive tasks?",
      answer: "Prioritize tasks by setting their priority level when creating or editing a task in the Proactive section."
    }
  ];



    return (
        <div className={classes.priv}>
            <h1>Proactive FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
