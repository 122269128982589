import React from 'react';

const OverallProgress = ({ progress }) => {
  // Ensure the progress is between 0 and 100
  const normalizedProgress = Math.min(Math.max(progress, 0), 100);

  // Calculate the angle for the arc (from 0 to 180 degrees)
  const angle = (normalizedProgress / 100) * 180;

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent:'center'}}>
      <svg
        width="70%"
        height="70%"
        viewBox="0 0 100 50"
        style={{ overflow: 'visible' }}
      >
        {/* Background semi-circle */}
        <path
          d="M10,50 A40,40 0 0,1 90,50"
          fill="none"
          
          stroke="#092E7D"
          strokeWidth="10"
        />
        {/* Progress arc */}
        <path
          d="M10,50 A40,40 0 0,1 90,50"
          fill="none"
          stroke="#6391F3"
          strokeWidth="10"
          strokeDasharray={`${
            (angle / 180) * 125.6
          } ${125.6 - (angle / 180) * 125.6}`}
        //   strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default OverallProgress;
