import React, { useEffect, useState } from 'react';
import classes from '../styles/header.module.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../aUrl';

const Header = () => {

  const navigate = useNavigate();
  const params = useParams();
  const [users, setUsers] = useState([]);
  const localhost = config.apiUrl;

  const getUserData = async () =>{
    try{
      const response = await axios.get(`${localhost}/getDataForHeader/${params.id}`);
      const result = response.data.header;

      if(response.status === 200)
      {  
        setUsers(result); 
      }
    } catch (error) {
      console.error("ERROR");
    }
  }

  useEffect(()=>{
    getUserData();
  },[])

  function proactive() {
    navigate("/client/dashboard/proactive")
  }

  function profile() {
    navigate("/client/dashboard/settings/profile")
  }

  return (
    <div className={classes.header}>


      <div className={classes.headerin} >

      
      <div className={classes.deta}>
        <p2>#Id:&nbsp;<span>{users._id}</span></p2>
      </div>

      <div className={classes.deta}>
      <p2>Name:&nbsp;<span>{users.name}</span></p2>
      </div>

      <div className={classes.deta}>
      <p2>Email:&nbsp;<span>{users.email}</span></p2>
      </div>
      </div>


      {/* <div className={classes.right}>
        <div className={classes.noti}>
        <FontAwesomeIcon icon={faBell} />
        </div>
        <div className={classes.noti} onClick={proactive}>
        <FontAwesomeIcon icon={faEnvelope} />
        </div>
        <div className={classes.noti} onClick={profile}>
        <FontAwesomeIcon icon={faUser} />
        </div>




      </div> */}


    </div>
  );
};

export default Header;
