import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from '../../styles/faq.module.css';

const S1 = () => {



    // data/billsInvoicesFAQ.js
const FAQ = [
    {
      question: "Where can I view and manage my bills and invoices?",
      answer: "Bills and invoices can be managed under the 'Bills & Invoices' section. This section allows you to view, download, and pay your bills and invoices."
    },
    {
      question: "How do I download a bill or invoice?",
      answer: "To download, go to the 'Bills & Invoices' section, find the bill or invoice you want, and click on the download icon."
    },
    {
      question: "Can I pay bills and invoices online?",
      answer: "Yes, you can pay bills and invoices online through the 'Bills & Invoices' section by selecting the 'Pay Now' option."
    },
    {
      question: "How do I view the details of a bill or invoice?",
      answer: "Click on any bill or invoice in the 'Bills & Invoices' section to view its details."
    }
  ];



    return (
        <div className={classes.priv}>
            <h1>Bills & Invoices FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
