import React, { useState } from 'react';
import classes from '../../styles/projectScope/audit.module.css';
import Sliderbudget from './objective_sliders/SliderBudget';
import Slidercomplete from './objective_sliders/SliderComplete';
import OverAllocated from './objective_sliders/OverAllocated';
import Normally from './objective_sliders/Normally';
import UnderAllocated from './objective_sliders/UnderAllocated';




const Audit = () => {

    const initialData = {
        projectManager: 'Abhishek Bharti',
        goal: 'Goal of the project is to create a new website for the company.',
        start: '2028-01-22',
        finish: '2028-11-29',
        duration: '21',
        complete: '72',
        budget: '700'
    };

    const [isEditMode, setIsEditMode] = useState(false);
    const [data, setData] = useState(initialData);
    const [initialDataState, setInitialDataState] = useState(initialData);

    const toggleEditMode = () => {
        setIsEditMode(true);
    };

    const handleCancel = () => {
        setData(initialDataState); // Restore previous data
        setIsEditMode(false);
    };

    const handleSave = () => {
        setInitialDataState(data); // Save current data as initial
        setIsEditMode(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    

    const convertToDisplayDateFormat = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        return `${month}/${day}/${year}`;
    };





    // Function to handle slider value change
  const handleSliderChange = (newValue) => {
    setData((prevData) => ({
      ...prevData,
      complete: newValue,
    }));
  };



    return (



        <div  >
            <div className={classes.box1}>
                <div className={classes.otrhead}>
                    <div className={classes.inotrhead}>
                        <h2>Audit</h2>
                    </div>
                    <div className={classes.inotrhead}>
                        <button
                            type="button"
                            className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
                            onClick={isEditMode ? handleCancel : toggleEditMode}
                        >
                            {isEditMode ? 'Cancel' : 'Edit'}
                        </button>

                        {isEditMode && (
                            <button
                                onClick={handleSave}
                                className={`${classes.saveimgbutton} ${classes.inotrheadedt}`}
                            >
                                Save
                            </button>
                        )}
                    </div>
                </div>

                <div className={classes.parent}>
                    <div className={classes.first}>
                        <div className={classes.inbox}>
                            <div className={classes.txt}>
                                <label>Project Manager</label>
                            </div>
                            <div className={classes.txt2}>
                                {isEditMode ? (
                                    <input
                                        type="text"
                                        name="projectManager"
                                        value={data.projectManager}
                                        onChange={handleChange}
                                        className={classes.p}
                                    />
                                ) : (
                                    <p className={classes.p}>{data.projectManager}</p>
                                )}
                            </div>
                        </div>

                        <div className={classes.inbox2}>
                            <div className={classes.txtgoal}>
                                <label>Goal</label>
                            </div>
                            <div className={classes.txt2goal}>
                                {isEditMode ? (
                                    <input
                                        type="text"
                                        name="goal"
                                        value={data.goal}
                                        onChange={handleChange}
                                        className={classes.p}
                                    />
                                ) : (
                                    <p className={classes.p}>{data.goal}</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={classes.second}>
                        <div className={classes.s1}>
                            <div className={classes.inbox}>
                                <div className={classes.txt}>
                                    <label>Start</label>
                                </div>
                                <div className={classes.txt2}>
                                    {isEditMode ? (
                                        <input
                                            type="date"
                                            name="start"
                                            value={(data.start)}
                                            onChange={handleChange}
                                            className={classes.p}
                                        />
                                    ) : (
                                        <p className={classes.p}>{convertToDisplayDateFormat(data.start)}</p>
                                    )}
                                </div>
                            </div>

                            <div className={classes.inbox2}>
                                <div className={classes.txtgoal}>
                                    <label>Finish</label>
                                </div>
                                <div className={classes.txt2goal}>
                                    {isEditMode ? (
                                        <input
                                            type="date"
                                            name="finish"
                                            value={(data.finish)}
                                            onChange={handleChange}
                                            className={classes.p}
                                        />
                                    ) : (
                                        <p className={classes.p}>{convertToDisplayDateFormat(data.finish)}</p>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={classes.s2}>
                            <div className={classes.inbox4}>
                                <div className={classes.txt}>
                                    <label>Duration</label>
                                </div>
                                <div className={classes.txt2}>
                                    {isEditMode ? (
                                        <input
                                            type="text"
                                            name="duration"
                                            value={data.duration}
                                            onChange={handleChange}
                                            className={classes.p}
                                        />
                                    ) : (
                                        <p className={classes.p}>{data.duration}&nbsp;Day(s)</p>
                                    )}
                                </div>
                            </div>

                            <div className={classes.inbox5}>
                                <div className={classes.txtcomp}>
                                    <label>Complete</label>
                                </div>
                                <div className={classes.txt2goal}>
                                    {isEditMode ? (
                                        <input
                                            type="number"
                                            name="complete"
                                            min="0" 
                                            max="100"
                                            value={data.complete}
                                            onChange={handleChange}
                                            className={classes.p}
                                        />
                                    ) : (
                                        <p className={classes.p}>{data.complete}&nbsp;%</p>
                                    )}
                                </div>
                            </div>

                            <div className={classes.inbox6}>
                                <div className={classes.txtgoal}>
                                    <label>Budget</label>
                                </div>
                                <div className={classes.txt2goal}>
                                    {isEditMode ? (
                                        <input
                                            type="text"
                                            name="budget"
                                            value={data.budget}
                                            onChange={handleChange}
                                            className={classes.p}
                                        />
                                    ) : (
                                        <p className={classes.p}>$&nbsp;{data.budget}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




















































            {/* Second Box  */}


            <div className={classes.box2}>


                <div className={classes.box2in1}>
                    <h2>Cost and Budget</h2>

                    {/* Slider for Cost and Budget  */}

                    <div className={classes.slidercost}>
                        
                        <Sliderbudget 
                        max={data.budget} // Maximum value for the slider
                        />


                        <div className={classes.sliderlblbotm} ><p>$0</p><p>${data.budget}</p></div>

                    </div>

                </div>





                <div className={classes.box2in2}>
                    <h2>Project Complete</h2>
                    {/* Slider for Cost and Budget  */}

                    <div className={classes.slidercost}>
                        <Slidercomplete               // Here the value is passed to the component. (Don't touch this)
                         value={data.complete} //  (Don't touch this)
                         onSliderChange={handleSliderChange}  // Pass function to update value (Don't touch this)
                         editable={isEditMode} // Pass edit mode state to Slidercomplete (Don't touch this)
                        />                            
                        <div className={classes.sliderlblbotm} ><p>0%</p><p>100%</p></div>
                    </div>

                </div>


            </div>








            <div className={classes.box3}>
                <div className={classes.resourse}>
                    <h2>Resource Allocation</h2>

                    <div className={classes.allocators}>
                        <div className={classes.aloboxs}>
                            <div className={classes.alotxt}><p>Overallocated</p></div> <OverAllocated />  <div></div>
                        </div>

                        <div className={classes.aloboxs}>
                            <div className={classes.alotxt} ><p>Normally</p></div><Normally /> <div></div>
                        </div>

                        <div className={classes.aloboxs}>
                            <div className={classes.alotxt} ><p>Underallocated</p></div><UnderAllocated /> <div></div>
                        </div>

                    </div>


                </div>
            </div>




        </div>


    );
};

export default Audit;