import * as React from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../../aUrl';

// Custom tooltip to format the value
function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={`${value}`}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

// Define the shadow style
const iOSBoxShadow = 'none';

// Styled Slider component with customized styles
const IOSSlider = styled(Slider)(({ theme }) => ({
  color: '#6391F3',
  height: '100%',
  padding: '0',
  
  '& .MuiSlider-thumb': {
    height: '100%',
    width: '0',
    backgroundColor: '#836707',
    border: 'none',
    boxShadow: iOSBoxShadow,
    borderRadius: '5px',
    cursor: 'default',
    '&.Mui-disabled': {
      backgroundColor: '#836707',
    },
  },

  '& .MuiSlider-valueLabel': {
    fontSize: 16,
    fontWeight: 'bold',
    top: '78%',
    right: '50%',
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    '&::before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  
  '& .MuiSlider-track': {
    border: 'none',
    height: '90%',
    borderRadius: '5px',
    '&.Mui-disabled': {
      backgroundColor: '#F1D575',
    },
  },
  
  '& .MuiSlider-rail': {
    opacity: 0.7,
    backgroundColor: 'transparent',
    height: '90%',
    borderRadius: '5px 5px 5px 5px',
    '&.Mui-disabled': {
      backgroundColor: '#F5F5F5',
    },
  },

  '&.Mui-disabled': {
    color: '#6391F3',
    cursor: 'default',
  },
}));

export default function App() {
  const localhost = config.apiUrl;
  const [values, setValues] = useState({ Normal: 0 }); // Initial state

  const Fetchnormal = async () => {
    const userId = localStorage.getItem("username");
    try {
      const response = await axios.get(`${localhost}/project/ResourceAllocation/${userId}`);
      const result = response.data;
      if (response.status === 200 && result) {
        console.log("Fetched data:", result.Normal); // Debugging output
        setValues(result); // Set the fetched data
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    Fetchnormal(); // Fetch data when component mounts
  }, []);

  return (
    <>
      {/* Conditionally render the slider if the value exists */}
      {typeof values.Normal === 'number' && (
        <IOSSlider
          aria-label="ios slider"
          min={0} // Minimum value
          max={100} // Maximum value
          value={values.Normal} // Value from the fetched data
          valueLabelDisplay="on" // Ensure value label is shown
          ValueLabelComponent={ValueLabelComponent} // Custom value label component
          disabled // Make the slider non-interactive
        />
      )}
    </>
  );
}
