import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; 
import classes from '../styles/home.module.css'
import axios from 'axios';
// import { toast, ToastContainer } from "react-toastify";
import config from '../aUrl'

const Home = () => {
  const [firstname, setLoggedName] = useState();
  const navigate = useNavigate();
  const localhost = config.apiUrl;

  useEffect(() => {
    setLoggedName(localStorage.getItem("firstname"));
    const fetchProtectedData = async () => {
      const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage

      try {
        const response = await axios.get(`${localhost}/protected`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the header
          },
        });

        if (response.data === true) {
          console.log("Access granted:", response.data); // The token was verified successfully
        }

      } catch (error) {
        navigate('/client/login');
      }
    };
    fetchProtectedData();
  });

// FUNCTIONS TO NAVIGATE TO DIFFERENT PAGES BY BUTTON 
  function profile(){
    navigate("/client/dashboard/settings/profile")
}

function payment(){
 navigate("/client/dashboard/settings/payment")
}

function status(){
navigate("/client/dashboard/status")
}


function profilelrn(){
  navigate("/client/dashboard/profilefaq")
}

function paymentlrn(){
  navigate("/client/dashboard/paymentfaq")
}

function deliverablelrn(){
  navigate("/client/dashboard/deliverablesfaq")
}






  return (
    <div className={classes.outer}>     

      <div className={classes.inner1}>
        <h1 className={classes.welcome}>Welcome<span>&nbsp;{firstname}</span></h1>
        <br></br>
        <h3>Organize your work and improve your performance here with us!</h3>
      </div>
      <br></br>
      <h1>Try Things Out</h1>
      <br></br>





      <div className={classes.inner2}>

        <div className={classes.box}>
          <h2>Set up your profile</h2>
          <br></br>
          <br></br>
          <div className={classes.btn}>
            <button className={classes.setting} onClick = {profile} >Profile</button>
            <button className={classes.learnm} onClick={profilelrn} >Learn More!</button>
          </div>
        </div>



        <div className={classes.box}>
          <h2>Payment Setup</h2>
          <br></br>
          <br></br>
          <div className={classes.btn}>
          <button className={classes.setting} onClick={payment}>Payment</button>
            <button className={classes.learnm} onClick={paymentlrn} >Learn More!</button>
          </div>
        </div>



        <div className={classes.box}>
          <h2>Deliverable Status</h2>
          <br></br>
          <br></br>
          <div className={classes.btn}>
          <button className={classes.setting} onClick={status}>Status</button>
            <button className={classes.learnm} onClick={deliverablelrn} >Learn More!</button>
          </div>
        </div>



      </div>
    </div>

  );
};

export default Home;
