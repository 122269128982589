import React, { useState, useEffect } from 'react';
import classes from '../../styles/accountSetting/payment.module.css';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import configs from './url';

const Payment = () => {
    const navigate = useNavigate();
    const localhost = configs.apiUrl;

    const [formData, setFormData] = useState({
        eftAuthorizationDate: '',
        onlineBillPayment: '',
        routingNumber: '',
        clientSetUpDate: '',
        accountNumber: '',
        verificationDate: '',
        transferDate: '',
        firstPaymentDate: '',
        amount1: '',
        amount2: '',
        paymentFrequency1: '',
        paymentFrequency2: '',
        accountingBudgetPerMonth: '',
        estimateOfServices: '',
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    // Date format function
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };






    

     // Fetch payment information on component mount
     const fetchPaymentData = async () => {
        const userId = localStorage.getItem("emails");
        const response = await fetch(`${localhost}/getPayment/${userId}`);
        const result = await response.json();

        if (response.ok && result.payment) {
            // Format dates before setting them in state
            const payment = {
                ...result.payment,
                eftAuthorizationDate: formatDate(result.payment.eftAuthorizationDate),
                clientSetUpDate: formatDate(result.payment.clientSetUpDate),
                verificationDate: formatDate(result.payment.verificationDate),
                transferDate: formatDate(result.payment.transferDate),
                firstPaymentDate: formatDate(result.payment.firstPaymentDate),
            };
            setFormData(payment);
            setIsSubmitted(true);
        }
    };

    const fetchProtectedData = async () => {
        const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage
        try {
            const response = await axios.get(`${localhost}/protected`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Include the token in the header
                },
            });

            if (response.data === true) {
                console.log("Access granted:", response.data); // The token was verified successfully
                // Proceed with your page logic here
            }
        } catch (error) {
            navigate('/client/login');
        }
    };

    useEffect(() => {
        fetchProtectedData();
        fetchPaymentData();
    }, [localhost, navigate]);






    
    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);

        const paymentData = {
            userId: localStorage.getItem("emails"),
            eftAuthorizationDate: formData.get('eftAuthorizationDate'),
            onlineBillPayment: formData.get('onlineBillPayment'),
            routingNumber: formData.get('routingNumber'),
            clientSetUpDate: formData.get('clientSetUpDate'),
            accountNumber: formData.get('accountNumber'),
            verificationDate: formData.get('verificationDate'),
            transferDate: formData.get('transferDate'),
            firstPaymentDate: formData.get('firstPaymentDate'),
            amount1: formData.get('amount1'),
            amount2: formData.get('amount2'),
            paymentFrequency1: formData.get('paymentFrequency1'),
            paymentFrequency2: formData.get('paymentFrequency2'),
            accountingBudgetPerMonth: formData.get('accountingBudgetPerMonth'),
            estimateOfServices: formData.get('estimateOfServices'),
        };

        const response = await fetch(`${localhost}/savePayment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentData),
        });
        if (response.ok) {
           
    
            setFormData(paymentData);
            setIsSubmitted(true);
            toast.success("Information stored Successfully");
            // Fetch the updated data after saving
            fetchPaymentData();
        }
    };










    return (
        <div className={classes.profilesettingscontainer}>
            <ToastContainer />
            <div className={classes.personalsection}>
                <h2>Payment Information</h2>
                <form onSubmit={handleSubmit}>
                    <div className={classes.formgroup}>
                        <div className={classes.formitem}>
                            <label className={classes.labels}>EFT Authorization Date</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.eftAuthorizationDate}</p>
                                ) : (
                                    <input
                                        type="date"
                                        name="eftAuthorizationDate"
                                        className={classes.inputs}
                                        required
                                        value={formData.eftAuthorizationDate.split('/').reverse().join('-')}
                                        onChange={(e) => setFormData({ ...formData, eftAuthorizationDate: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>

                        <div className={classes.formitem}>
                            <label className={classes.labels}>Online Bill Payment</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.onlineBillPayment}</p>
                                ) : (
                                    <input
                                        type="text"
                                        name="onlineBillPayment"
                                        className={classes.inputs}
                                        required
                                        value={formData.onlineBillPayment}
                                        onChange={(e) => setFormData({ ...formData, onlineBillPayment: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>
                    </div>






                    <div className={classes.formgroup}>
                        <div className={classes.formitem}>
                            <label className={classes.labels}>Routing Number</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.routingNumber}</p>
                                ) : (
                                    <input
                                        type="number"
                                        name="routingNumber"
                                        className={classes.inputs}
                                        required
                                        value={formData.routingNumber}
                                        onChange={(e) => setFormData({ ...formData, routingNumber: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classes.formitem}>
                            <label className={classes.labels}>Client Set Up Date</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.clientSetUpDate}</p>
                                ) : (
                                    <input
                                        type="date"
                                        name="clientSetUpDate"
                                        className={classes.inputs}
                                        required
                                        value={formData.clientSetUpDate.split('/').reverse().join('-')}
                                        onChange={(e) => setFormData({ ...formData, clientSetUpDate: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={classes.formgroup}>
                        <div className={classes.formitem}>
                            <label className={classes.labels}>Account Number</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.accountNumber}</p>
                                ) : (
                                    <input
                                        type="number"
                                        name="accountNumber"
                                        className={classes.inputs}
                                        required
                                        value={formData.accountNumber}
                                        onChange={(e) => setFormData({ ...formData, accountNumber: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classes.formitem}>
                            <label className={classes.labels}>Verification Date</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.verificationDate}</p>
                                ) : (
                                    <input
                                        type="date"
                                        name="verificationDate"
                                        className={classes.inputs}
                                        required
                                        value={formData.verificationDate.split('/').reverse().join('-')}
                                        onChange={(e) => setFormData({ ...formData, verificationDate: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={classes.formgroup}>
                        <div className={classes.formitem}>
                            <label className={classes.labels}>Transfer Date</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.transferDate}</p>
                                ) : (
                                    <input
                                        type="date"
                                        name="transferDate"
                                        className={classes.inputs}
                                        required
                                        value={formData.transferDate.split('/').reverse().join('-')}
                                        onChange={(e) => setFormData({ ...formData, transferDate: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classes.formitem}>
                            <label className={classes.labels}>First Payment Date</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.firstPaymentDate}</p>
                                ) : (
                                    <input
                                        type="date"
                                        name="firstPaymentDate"
                                        className={classes.inputs}
                                        required
                                        value={formData.firstPaymentDate.split('/').reverse().join('-')}
                                        onChange={(e) => setFormData({ ...formData, firstPaymentDate: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={classes.formgroup}>
                        <div className={classes.formitem}>
                            <label className={classes.labels}>Amount 1</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.amount1}</p>
                                ) : (
                                    <input
                                        type="number"
                                        name="amount1"
                                        className={classes.inputs}
                                        required
                                        value={formData.amount1}
                                        onChange={(e) => setFormData({ ...formData, amount1: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classes.formitem}>
                            <label className={classes.labels}>Amount 2</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.amount2}</p>
                                ) : (
                                    <input
                                        type="number"
                                        name="amount2"
                                        className={classes.inputs}
                                        required
                                        value={formData.amount2}
                                        onChange={(e) => setFormData({ ...formData, amount2: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={classes.formgroup}>
                        <div className={classes.formitem}>
                            <label className={classes.labels}>Payment Frequency 1</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.paymentFrequency1}</p>
                                ) : (
                                    <input
                                        type="text"
                                        name="paymentFrequency1"
                                        className={classes.inputs}
                                        required
                                        value={formData.paymentFrequency1}
                                        onChange={(e) => setFormData({ ...formData, paymentFrequency1: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classes.formitem}>
                            <label className={classes.labels}>Payment Frequency 2</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.paymentFrequency2}</p>
                                ) : (
                                    <input
                                        type="text"
                                        name="paymentFrequency2"
                                        className={classes.inputs}
                                        required
                                        value={formData.paymentFrequency2}
                                        onChange={(e) => setFormData({ ...formData, paymentFrequency2: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={classes.formgroup}>
                        <div className={classes.formitem}>
                            <label className={classes.labels}>Accounting Budget Per Month</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.accountingBudgetPerMonth}</p>
                                ) : (
                                    <input
                                        type="number"
                                        name="accountingBudgetPerMonth"
                                        className={classes.inputs}
                                        required
                                        value={formData.accountingBudgetPerMonth}
                                        onChange={(e) => setFormData({ ...formData, accountingBudgetPerMonth: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classes.formitem}>
                            <label className={classes.labels}>Estimate of Services</label>
                            <div className={classes.inputwrapper}>
                                {isSubmitted ? (
                                    <p>{formData.estimateOfServices}</p>
                                ) : (
                                    <input
                                        type="text"
                                        name="estimateOfServices"
                                        className={classes.inputs}
                                        required
                                        value={formData.estimateOfServices}
                                        onChange={(e) => setFormData({ ...formData, estimateOfServices: e.target.value })}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {!isSubmitted && (
                        <button type='submit' className={classes.savepassbutton}>
                            Save
                        </button>
                    )}
                </form>
            </div>
        </div>
    );
};

export default Payment;
