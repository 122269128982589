import React, { useState, useRef, useEffect } from 'react';
import classes from '../styles/proactive.module.css';
import chatImage from '../assets/logochat.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SendIcon from '@mui/icons-material/Send';
import MicNoneIcon from '@mui/icons-material/MicNone';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../aUrl'



const Proactive = () => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const localhost = config.apiUrl


  // files handel
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');



  const [chat, setChat] = useState([
    { user: 'You', text: 'Hello, Admin!', timestamp: '10:00 AM', status: 'read', day: 'Today' },
    { user: 'Omega Bot', text: 'Hi, how can I help you?', timestamp: '10:01 AM', status: null, day: 'Yesterday' },
    { user: 'You', text: 'Hello, Admin!', timestamp: '10:00 AM', status: 'read', day: 'Today' },
    { user: 'Omega Bot', text: 'Hi, how can I help you?', timestamp: '10:01 AM', status: null, day: 'Thu' },
    { user: 'You', text: 'Hello, Admin!', timestamp: '10:00 AM', status: 'read', day: 'Aug 6th,' },
    { user: 'Omega Bot', text: 'Hi, how can I help you?', timestamp: '10:01 AM', status: null, day: 'Yesterday' },
    { user: 'You', text: 'Hello, Admin!', timestamp: '10:00 AM', status: 'read', day: 'Today' },
    { user: 'Omega Bot', text: 'Hi, how can I help you?', timestamp: '10:01 AM', status: null, day: 'Today' },
  ]);


  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);

  const mediaRecorderRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    if (isRecording) {
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => {
          if (prevTime >= 60) {
            handleStopRecording();
            return 60;
          }
          return prevTime + 1;
        });
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);

    
  }, [isRecording]);

  useEffect(()=>{
    const fetchProtectedData = async () => {
      const token = localStorage.getItem("authtoken"); // Retrieve the JWT from localStorage

      try {
        const response = await axios.get(`${localhost}/protected`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the header
          },
        });

        if (response.data === true) {
          console.log("Access granted:", response.data); // The token was verified successfully
          // Proceed with your page logic here
        }

      } catch (error) {
        navigate('/client/login');
      }
    };
    fetchProtectedData();
  })

  const handleToggleRecording = () => {
    if (isRecording) {
      handleStopRecording();
    } else {
      handleStartRecording();
    }
  };

  const handleStartRecording = () => {
    setIsRecording(true);
    setRecordingTime(0);
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.start();

      const audioChunks = [];
      mediaRecorderRef.current.ondataavailable = (e) => {
        audioChunks.push(e.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
        const reader = new FileReader();
        reader.onload = () => {
          setRecordedAudio(reader.result);
        };
        reader.readAsDataURL(audioBlob);
      };
    });
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    mediaRecorderRef.current.stop();
  };



  const handleSendAudio = () => {
    if (recordedAudio) {
      const audioMessage = {
        user: 'You',
        audio: recordedAudio,
        timestamp: new Date().toLocaleTimeString(),
        status: 'sent',
      };
      setChat((prevChat) => [...prevChat, audioMessage]);
      setRecordedAudio(null);
      setIsRecording(false);
      setRecordingTime(0);
    }

  };




  const handleDeleteAudio = () => {

    setIsRecording(false);
    setRecordedAudio(null);
    setRecordingTime(0);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    const newMessage = {
      user: 'You',
      text: message,
      timestamp: new Date().toLocaleTimeString(),
      status: 'sent',
    };








    // file changes handel 




    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        newMessage.file = reader.result;
        setChat((prevChat) => [...prevChat, newMessage]);
        setFile(null);
        scrollToBottom(); // Ensure scrolling happens after sending the file
      };
      reader.readAsDataURL(file);
    } else {
      setChat((prevChat) => [...prevChat, newMessage]);
      scrollToBottom(); // Ensure scrolling happens after sending
    }

    setMessage('');
    setFile(null);
    setFileName(null);
  };




  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Define allowed file types
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf']; // Example types

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        alert('Unsupported file type. Please upload a JPEG, PNG, or PDF file.');
        return;
      }

      // Check file size (5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        alert('File size exceeds 5MB. Please upload a smaller file.');
        return;
      }

      // If file is valid, set it in state
      setFile(file);
      setFileName(file.name);
    }
  };









  // full screen function
  const [isFullscreen, setIsFullscreen] = useState(false);



  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement !== null);
    };

    // Add event listeners
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    // Cleanup listeners on component unmount
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);




  // autofocus of the message input 

  const inputRef = useRef(null); // Step 1: Create a ref for the input element

  useEffect(() => {
    // Step 2: Focus the input when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);




  const handleFullscreen = () => {
    const element = document.querySelector(`.${classes.chatoutr}`);
    if (!isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(); // Safari
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen(); // IE/Edge
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Safari
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE/Edge
      }
    }
  };




  // Scrool to the bottom of the chat 
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chat]);



  // Scroll to the bottom of the chat
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };


  useEffect(() => {
    scrollToBottom();
  }, [chat, file]); // Add `file` to dependencies












  return (

    <div>
      {/* <h1>Proactive</h1>
      <br></br> */}





      <div className={classes.chatoutr}>

        <div className={classes.chatHeader}>

          <div className={classes.chtlabl}>

            <div className={classes.avatar}>
              <img src={chatImage} alt="logo" />
            </div>

            <h2>Omega&nbsp;Bot</h2>
          </div>





          {/* Full screen btn  */}

          <div>
            <button onClick={handleFullscreen} className={classes.fullscreenButton}>
              {isFullscreen ? <CloseFullscreenOutlinedIcon /> : <OpenInFullOutlinedIcon />}
            </button>

          </div>
        </div>

        <div className={classes.chatContainer}    >
          <div className={classes.messages} ref={chatContainerRef} >
            {chat.map((msg, index) => (
              <div
                key={index}
                className={`${classes.message} ${msg.user === 'You' ? classes.clientMessage : classes.adminMessage}`}
              >

                <p className={classes.msgusrlabel} >{msg.user}&nbsp;&nbsp;  <span> {msg.day}&nbsp;{msg.timestamp && msg.timestamp}</span> </p>

                <p className={classes.msggstatus}>{msg.status && msg.status}</p>





                {msg.text && <p>{msg.text}</p>}
                {msg.file && msg.file.startsWith('data:image') && <img src={msg.file} alt="attachment" className={classes.attachment} />}
                {msg.file && msg.file.startsWith('data:video') && <video controls src={msg.file} className={classes.attachment} />}
                {msg.audio && <audio controls src={msg.audio} className={classes.attachment} />}

              </div>
            ))}
          </div>
        </div>







        {/* form here  */}
        <form onSubmit={sendMessage} className={classes.form}>



          {!recordedAudio && !file && (
            <input
              type="text"
              value={message}
              ref={inputRef}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !message.trim()) {
                  e.preventDefault(); // Prevent form submission if the message is empty
                }
              }}
              placeholder="Type your message..."
              className={classes.msggginput}
            />

          )}


          {/* file name preview  */}
          {fileName && (
            <div className={classes.filePreview}>
              <span>{fileName}</span>
            </div>
          )}






          {/* Show file input only if no file selected ,not recording, no audio preview, no file preview, and message input is empty */}
          {!file && !isRecording && !recordedAudio && !message.trim() && (

            <div className={classes.fileInputWrapper}>
              <button className={classes.fileInputButton}>
                <AttachFileRoundedIcon className={classes.icon} />
                <input
                  type="file"
                  onChange={handleFileChange}
                  className={classes.fileInput}
                />
              </button>
            </div>
          )}





          {/* Show mic button only if not recording and no recorded audio and no file */}
          {!recordedAudio && !message.trim() && !file && (

            <button
              type="button"
              onClick={handleToggleRecording}
              className={`${classes.micButton} ${isRecording ? classes.recording : ''}`}
              disabled={recordingTime >= 60}
            >
              <MicNoneIcon />
            </button>
          )}





          {isRecording && <div className={classes.timer}>{recordingTime}s</div>}
          {recordedAudio && (
            <div className={classes.audioPreview}>
              <audio controls src={recordedAudio}></audio>
              <button type="button" onClick={handleSendAudio} className={classes.sendButton}>Send</button>
              <button type="button" onClick={handleDeleteAudio} className={classes.deleteButton}>Delete</button>
            </div>
          )}



          {!recordedAudio && (
            <button
              type="submit"
              className={classes.button}
              disabled={!message.trim() && !file} // Disable if both input and file are empty
            >
              <SendIcon />
            </button>

          )}






        </form>
      </div>

    </div>
  );
};

export default Proactive;
