import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from '../styles/home.module.css'

const Home = () => {

   const navigate = useNavigate();

   function employee(){
       navigate("/admin/dashboard/employeelist")
   }

   function client(){
    navigate("/admin/dashboard/clientlist")
}

function analytics(){
  navigate("/admin/dashboard/analytics")
}



  
  return (
    <div className={classes.outer}>     

      <div className={classes.inner1}>
        <h1><span className={classes.welcome} >Welcome</span> to Admin Panel!</h1>
        <br></br>
        <h3>Digitized for Excellence.</h3>
      </div>
      <br></br>
      <h1>Dashboard Control Center</h1>
      <br></br>





      <div className={classes.inner2}>

        <div className={classes.box}>
          <h2>Employee Dashboard</h2>
          <br></br>
          <br></br>
          <div className={classes.btn}>
            <button className={classes.setting} onClick = {employee} >Manage Employee</button>
          </div>
        </div>



        <div className={classes.box}>
          <h2>Client Dashboard</h2>
          <br></br>
          <br></br>
          <div className={classes.btn}>
          <button className={classes.setting} onClick={client}>Manage Client</button>
          </div>
        </div>


        <div className={classes.box}>
          <h2>Overall Analytics</h2>
          <br></br>
          <br></br>
          <div className={classes.btn}>
          <button className={classes.setting} onClick={analytics}>Go to Analytics</button>
          </div>
        </div>





        



      </div>
    </div>
  );
};

export default Home;
