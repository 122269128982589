import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from '../../styles/faq.module.css';

const S1 = () => {



    // data/profileFAQ.js
    const FAQ = [
        {
            question: "How can I set up my profile?",
            answer: "To set up your profile, go to the 'Profile' section in the sidebar. Here, you can update your personal information, add a profile picture, and adjust your preferences."
        },
        {
            question: "How do I change my profile picture?",
            answer: "In the 'Profile' section, click on the current profile picture and select 'Upload New Picture' to change it."
        },
        {
            question: "Can I update my contact information?",
            answer: "Yes, you can update your contact information under the 'Profile' section by editing the contact details fields."
        },
        {
            question: "How do I adjust my notification settings?",
            answer: "Go to the 'Profile' section and find the 'Notification Settings' option to adjust your preferences for receiving notifications."
        }
    ];



    return (
        <div className={classes.priv}>
            <h1>Profile FAQ's</h1>
            <br />
            <div className={classes.privin}>




                <div className={classes.qna}>
                    {FAQ.map((item, index) => (
                        <div key={index} className={classes.qnaItem}>
                            <h3 className={classes.quse}>{item.question}</h3>
                            <p className={classes.answer}>{item.answer}</p>
                        </div>
                    ))}
                </div>












            </div>






        </div>

    );
};

export default S1;
